import type { LayerSpecification } from "maplibre-gl";

export const CAMERA_LAYER: LayerSpecification = {
	id: "cms_device",
	source: "pgtiles_2",
	"source-layer": "public.kafkamessages_cms",
	type: "symbol",
	paint: {
		"icon-opacity": 1,
	},
	filter: ["all", ["==", ["geometry-type"], "Point"], ["==", ["get", "status"], "active"]],
	layout: {
		"icon-image": "kamera-caurspidiga",
		"icon-rotation-alignment": "viewport",
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};
