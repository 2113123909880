import type { StyleSpecification } from "maplibre-gl";

export const BASE_MAP_ALT: StyleSpecification = {
	version: 8,
	sources: {
		osm: {
			type: "raster",
			tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
			tileSize: 256,
		},
	},
	layers: [
		{
			id: "osm-layer",
			type: "raster",
			source: "osm",
		},
	],
};

export const BASE_MAP: StyleSpecification = {
	version: 8,
	name: "SIPR OSM",
	metadata: {
		"openmaptiles:version": "3.x",
	},
	sources: {
		openmaptiles: {
			type: "vector",
			url: "https://map-test.lvceli.lv/data/v3.json",
		},
	},
	sprite: "https://map.transportdata.gov.lv/gfiles/visualization/map-style/sipr/sprites/osm-liberty",
	glyphs: "https://map-test.lvceli.lv/osm/fonts/{fontstack}/{range}.pbf",
	layers: [
		{
			id: "background",
			type: "background",
			paint: {
				"background-color": "rgb(239,239,239)",
			},
		},
		{
			id: "park",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "park",
			paint: {
				"fill-color": "#d8e8c8",
				"fill-opacity": 0.7,
				"fill-outline-color": "rgba(95, 208, 100, 1)",
			},
		},
		{
			id: "park_outline",
			type: "line",
			source: "openmaptiles",
			"source-layer": "park",
			paint: {
				"line-dasharray": [1, 1.5],
				"line-color": "rgba(228, 241, 215, 1)",
			},
		},
		{
			id: "landuse_residential",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landuse",
			maxzoom: 8,
			filter: ["==", "class", "residential"],
			paint: {
				"fill-color": {
					base: 1,
					stops: [
						[9, "hsla(0, 3%, 85%, 0.84)"],
						[12, "hsla(35, 57%, 88%, 0.49)"],
					],
				},
			},
		},
		{
			id: "landcover_wood",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landcover",
			filter: ["all", ["==", "class", "wood"]],
			paint: {
				"fill-antialias": false,
				"fill-color": "hsla(98, 61%, 72%, 0.7)",
				"fill-opacity": 0.4,
			},
		},
		{
			id: "landcover_grass",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landcover",
			filter: ["all", ["==", "class", "grass"]],
			paint: {
				"fill-antialias": false,
				"fill-color": "rgba(176, 213, 154, 1)",
				"fill-opacity": 0.3,
			},
		},
		{
			id: "landcover_ice",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landcover",
			filter: ["all", ["==", "class", "ice"]],
			paint: {
				"fill-antialias": false,
				"fill-color": "rgba(224, 236, 236, 1)",
				"fill-opacity": 0.8,
			},
		},
		{
			id: "landuse_cemetery",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landuse",
			filter: ["==", "class", "cemetery"],
			paint: {
				"fill-color": "hsl(75, 37%, 81%)",
			},
		},
		{
			id: "landuse_hospital",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landuse",
			filter: ["==", "class", "hospital"],
			paint: {
				"fill-color": "#fde",
			},
		},
		{
			id: "landuse_school",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landuse",
			filter: ["==", "class", "school"],
			paint: {
				"fill-color": "rgb(236,238,204)",
			},
		},
		{
			id: "waterway_tunnel",
			type: "line",
			source: "openmaptiles",
			"source-layer": "waterway",
			filter: ["all", ["==", "brunnel", "tunnel"]],
			paint: {
				"line-color": "#a0c8f0",
				"line-dasharray": [3, 3],
				"line-gap-width": {
					stops: [
						[12, 0],
						[20, 6],
					],
				},
				"line-opacity": 1,
				"line-width": {
					base: 1.4,
					stops: [
						[8, 1],
						[20, 2],
					],
				},
			},
		},
		{
			id: "waterway_river",
			type: "line",
			source: "openmaptiles",
			"source-layer": "waterway",
			filter: ["all", ["==", "class", "river"], ["!=", "brunnel", "tunnel"]],
			layout: {
				"line-cap": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#a0c8f0",
				"line-width": ["interpolate", ["exponential", 1.2], ["zoom"], 10, 2, 20, 6],
			},
		},
		{
			id: "waterway_other",
			type: "line",
			source: "openmaptiles",
			"source-layer": "waterway",
			filter: ["all", ["!=", "class", "river"], ["!=", "brunnel", "tunnel"]],
			layout: {
				"line-cap": "round",
			},
			paint: {
				"line-color": "#a0c8f0",
				"line-width": {
					base: 1.3,
					stops: [
						[13, 2],
						[20, 6],
					],
				},
			},
		},
		{
			id: "water",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "water",
			filter: ["all", ["!=", "brunnel", "tunnel"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"fill-color": "rgb(158,189,255)",
			},
		},
		{
			id: "landcover_sand",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "landcover",
			filter: ["all", ["==", "class", "sand"]],
			paint: {
				"fill-color": "rgba(247, 239, 195, 1)",
			},
		},
		{
			id: "aeroway_fill",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "aeroway",
			minzoom: 11,
			filter: ["==", "$type", "Polygon"],
			paint: {
				"fill-color": "rgba(229, 228, 224, 1)",
				"fill-opacity": 0.7,
			},
		},
		{
			id: "aeroway_runway",
			type: "line",
			source: "openmaptiles",
			"source-layer": "aeroway",
			minzoom: 11,
			filter: ["all", ["==", "$type", "LineString"], ["==", "class", "runway"]],
			paint: {
				"line-color": "#f0ede9",
				"line-width": {
					base: 1.2,
					stops: [
						[11, 3],
						[20, 16],
					],
				},
			},
		},
		{
			id: "aeroway_taxiway",
			type: "line",
			source: "openmaptiles",
			"source-layer": "aeroway",
			minzoom: 11,
			filter: ["all", ["==", "$type", "LineString"], ["==", "class", "taxiway"]],
			paint: {
				"line-color": "#f0ede9",
				"line-width": {
					base: 1.2,
					stops: [
						[11, 0.5],
						[20, 6],
					],
				},
			},
		},
		{
			id: "tunnel_minor",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"], ["==", "class", "minor_road"]],
			layout: {
				"line-cap": "butt",
				"line-join": "miter",
				visibility: "visible",
			},
			paint: {
				"line-color": "#efefef",
				"line-dasharray": [0.36, 0.18],
				"line-width": {
					base: 1.55,
					stops: [
						[4, 0.25],
						[20, 30],
					],
				},
			},
		},
		{
			id: "tunnel_major",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"], ["in", "class", "primary", "secondary", "tertiary", "trunk"]],
			layout: {
				"line-cap": "butt",
				"line-join": "miter",
				visibility: "visible",
			},
			paint: {
				"line-color": "#fff",
				"line-dasharray": [0.28, 0.14],
				"line-width": {
					base: 1.4,
					stops: [
						[6, 0.5],
						[20, 30],
					],
				},
			},
		},
		{
			id: "road_area_pattern",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "Polygon"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"fill-pattern": "osm:pedestrian_polygon",
			},
		},
		{
			id: "road_path",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "class", "path"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-width": {
					base: 1.4,
					stops: [
						[6, 0.5],
						[20, 5],
					],
				},
				"line-color": "rgba(160, 120, 120, 0.66)",
			},
		},
		{
			id: "road_minor",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 0,
			filter: ["all", ["==", "class", "minor"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-width": {
					base: 1.4,
					stops: [
						[6, 0.5],
						[20, 20],
					],
				},
				"line-color": "#fefefe",
			},
		},
		{
			id: "road_tertiary",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 0,
			filter: ["all", ["==", "class", "tertiary"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-color": "#fefefe",
				"line-width": {
					base: 1.4,
					stops: [
						[6, 0.5],
						[20, 20],
					],
				},
			},
		},
		{
			id: "road_secondary",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 0,
			filter: ["all", ["==", "class", "secondary"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-color": "#f6fabb",
				"line-width": {
					base: 1.4,
					stops: [
						[6, 0.5],
						[20, 20],
					],
				},
			},
		},
		{
			id: "road_primary",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 0,
			maxzoom: 24,
			filter: ["all", ["==", "class", "primary"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-color": "#fdd7a1",
				"line-width": {
					base: 1.4,
					stops: [
						[6, 2],
						[20, 30],
					],
				},
			},
		},
		{
			id: "road_trunk",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "class", "trunk"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-offset": 0,
				"line-width": {
					base: 1.4,
					stops: [
						[8, 3],
						[16, 10],
					],
				},
				"line-color": "#fbc0ac",
			},
		},
		{
			id: "road_motorway",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 7,
			filter: ["all", ["==", "class", "motorway"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-offset": 0,
				"line-width": {
					base: 1.4,
					stops: [
						[8, 1],
						[16, 10],
					],
				},
				"line-color": "#e990a0",
			},
		},
		{
			id: "road_one_way_arrow",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 15,
			filter: ["==", "oneway", 1],
			layout: {
				"icon-image": "osm:arrow",
				"symbol-placement": "line",
			},
		},
		{
			id: "road_one_way_arrow_opposite",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 15,
			filter: ["==", "oneway", -1],
			layout: {
				"icon-image": "osm:arrow",
				"symbol-placement": "line",
				"icon-rotate": 180,
			},
		},
		{
			id: "bridge_minor case",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["==", "class", "minor_road"]],
			layout: {
				"line-cap": "butt",
				"line-join": "miter",
				visibility: "visible",
			},
			paint: {
				"line-color": "rgba(139, 127, 127, 1)",
				"line-gap-width": {
					base: 1.55,
					stops: [
						[4, 0.25],
						[20, 30],
					],
				},
				"line-width": {
					base: 1.6,
					stops: [
						[12, 0.5],
						[20, 10],
					],
				},
			},
		},
		{
			id: "bridge_major case",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["in", "class", "primary", "secondary", "tertiary", "trunk"]],
			layout: {
				"line-cap": "butt",
				"line-join": "miter",
				visibility: "visible",
			},
			paint: {
				"line-color": "rgba(139, 127, 127, 1)",
				"line-gap-width": {
					base: 1.55,
					stops: [
						[4, 0.25],
						[20, 30],
					],
				},
				"line-width": {
					base: 1.6,
					stops: [
						[12, 0.5],
						[20, 10],
					],
				},
			},
		},
		{
			id: "bridge_minor",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["==", "class", "minor_road"]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "#efefef",
				"line-width": {
					base: 1.55,
					stops: [
						[4, 0.25],
						[20, 30],
					],
				},
			},
		},
		{
			id: "bridge_major",
			type: "line",
			source: "openmaptiles",
			"source-layer": "transportation",
			filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"], ["in", "class", "primary", "secondary", "tertiary", "trunk"]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "rgba(48, 43, 43, 1)",
				"line-width": 0,
			},
		},
		{
			id: "power_line",
			type: "line",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "line"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-color": "rgba(100, 95, 95, 1)",
			},
		},
		{
			id: "power_cable",
			type: "line",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "cable"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-color": "rgba(100, 95, 95, 1)",
			},
		},
		{
			id: "power_minor_line",
			type: "line",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "minor_line"]],
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-color": "rgba(100, 95, 95, 1)",
			},
		},
		{
			id: "power_generator",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "generator"]],
			paint: {
				"fill-color": "rgba(255, 206, 228, 1)",
			},
		},
		{
			id: "power_plant",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "plant"]],
			paint: {
				"fill-color": "rgba(255, 206, 228, 1)",
			},
		},
		{
			id: "power_transformer",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "transformer"]],
			paint: {
				"fill-color": "rgba(255, 206, 228, 1)",
			},
		},
		{
			id: "power_substation",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "class", "substation"]],
			paint: {
				"fill-color": "rgba(255, 206, 228, 1)",
			},
		},
		{
			id: "power_substtaion",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "$type", "Polygon"]],
			layout: {
				"text-field": "{name}",
				"text-font": ["Roboto Regular"],
				"text-max-width": 5,
				"text-size": 12,
			},
			paint: {
				"text-color": "rgba(65, 21, 6, 1)",
				"text-halo-color": "rgba(255,255,255,0.7)",
				"text-halo-width": 1,
			},
		},
		{
			id: "power_icon",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "power",
			minzoom: 14,
			filter: ["all", ["==", "$type", "Point"]],
			layout: {
				"icon-image": "osm:{class}_11",
				"text-anchor": "left",
				"text-field": "{name_en}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 9,
				"text-offset": [0.9, 0],
				"text-size": 12,
				visibility: "visible",
				"icon-size": 1.3,
			},
			paint: {
				"text-color": "#4898ff",
				"text-halo-blur": 0.5,
				"text-halo-color": "#ffffff",
				"text-halo-width": 1,
				"icon-halo-color": "#4898ff",
				"icon-halo-blur": 0,
				"icon-halo-width": 0,
			},
		},
		{
			id: "railway_transit",
			type: "line",
			metadata: {
				"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5",
			},
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 16,
			filter: ["all", ["==", "$type", "LineString"], ["all", ["==", "class", "transit"], ["!in", "brunnel", "tunnel"]]],
			layout: {
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#dddddd",
				"line-width": 3,
			},
		},
		{
			id: "railway_transit_dashline",
			type: "line",
			metadata: {
				"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5",
			},
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 16,
			filter: ["all", ["==", "$type", "LineString"], ["all", ["==", "class", "transit"], ["!in", "brunnel", "tunnel"]]],
			layout: {
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#fafafa",
				"line-dasharray": [3, 3],
				"line-width": 2,
			},
		},
		{
			id: "railway_service",
			type: "line",
			metadata: {
				"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5",
			},
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 16,
			filter: ["all", ["==", "$type", "LineString"], ["all", ["==", "class", "rail"], ["has", "service"]]],
			layout: {
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#dddddd",
				"line-width": 3,
			},
		},
		{
			id: "railway_service_dashline",
			type: "line",
			metadata: {
				"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5",
			},
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 16,
			filter: ["all", ["==", "$type", "LineString"], ["==", "class", "rail"], ["has", "service"]],
			layout: {
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#fafafa",
				"line-dasharray": [3, 3],
				"line-width": 2,
			},
		},
		{
			id: "railway",
			type: "line",
			metadata: {
				"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5",
			},
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 13,
			filter: ["all", ["==", "$type", "LineString"], ["all", ["!has", "service"], ["==", "class", "rail"]]],
			layout: {
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#dddddd",
				"line-width": {
					base: 1.3,
					stops: [
						[16, 3],
						[20, 7],
					],
				},
			},
		},
		{
			id: "railway_dashline",
			type: "line",
			metadata: {
				"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5",
			},
			source: "openmaptiles",
			"source-layer": "transportation",
			minzoom: 13,
			filter: ["all", ["==", "$type", "LineString"], ["all", ["!has", "service"], ["==", "class", "rail"]]],
			layout: {
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "#fafafa",
				"line-dasharray": [3, 3],
				"line-width": {
					base: 1.3,
					stops: [
						[16, 2],
						[20, 6],
					],
				},
			},
		},
		{
			id: "building",
			type: "fill",
			source: "openmaptiles",
			"source-layer": "building",
			minzoom: 13,
			maxzoom: 24,
			paint: {
				"fill-color": "hsl(35, 8%, 85%)",
				"fill-outline-color": {
					base: 1,
					stops: [
						[13, "hsla(35, 6%, 79%, 0.32)"],
						[14, "hsl(35, 6%, 79%)"],
					],
				},
			},
		},
		{
			id: "building-3d",
			type: "fill-extrusion",
			source: "openmaptiles",
			"source-layer": "building",
			minzoom: 14,
			layout: {
				visibility: "none",
			},
			paint: {
				"fill-extrusion-color": "hsl(35, 8%, 85%)",
				"fill-extrusion-height": {
					property: "render_height",
					type: "identity",
				},
				"fill-extrusion-base": {
					property: "render_min_height",
					type: "identity",
				},
				"fill-extrusion-opacity": 0.8,
			},
		},
		{
			id: "house_number",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "housenumber",
			layout: {
				"text-field": "{housenumber}",
				visibility: "visible",
				"text-size": 11,
				"text-font": ["Roboto Regular"],
			},
			paint: {
				"text-opacity": 1,
				"text-color": "rgba(100, 95, 95, 1)",
			},
		},
		{
			id: "house_name",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "housenumber",
			layout: {
				"text-field": "{housename}",
				"text-font": ["Roboto Condensed Italic"],
				"text-size": 11,
				visibility: "visible",
			},
			paint: {
				"text-color": "rgba(100, 95, 95, 1)",
			},
		},
		{
			id: "boundary_3",
			type: "line",
			source: "openmaptiles",
			"source-layer": "boundary",
			minzoom: 8,
			filter: ["all", ["in", "admin_level", 3, 4], ["==", "maritime", 0]],
			layout: {
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "#9e9cab",
				"line-dasharray": [5, 1],
				"line-width": {
					base: 1,
					stops: [
						[4, 0.4],
						[5, 1],
						[12, 1.8],
					],
				},
			},
		},
		{
			id: "boundary_3-maritime",
			type: "line",
			source: "openmaptiles",
			"source-layer": "boundary",
			minzoom: 8,
			filter: ["all", ["in", "admin_level", 3, 4], ["==", "maritime", 1]],
			layout: {
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "#9e9cab",
				"line-dasharray": [5, 1],
				"line-width": {
					base: 1,
					stops: [
						[4, 0.4],
						[5, 1],
						[12, 1.8],
					],
				},
				"line-opacity": 0.2,
			},
		},
		{
			id: "boundary_2_z0-4",
			type: "line",
			source: "openmaptiles",
			"source-layer": "boundary",
			maxzoom: 5,
			filter: ["all", ["==", "admin_level", 2], ["!has", "claimed_by"], ["==", "maritime", 0]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[6, 0],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 1],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
		{
			id: "boundary_2_z0-4-maritime",
			type: "line",
			source: "openmaptiles",
			"source-layer": "boundary",
			maxzoom: 5,
			filter: ["all", ["==", "admin_level", 2], ["!has", "claimed_by"], ["==", "maritime", 1]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[4, 0.2],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 1],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
		{
			id: "boundary_2_z5-",
			type: "line",
			source: "openmaptiles",
			"source-layer": "boundary",
			minzoom: 5,
			filter: ["all", ["==", "admin_level", 2], ["==", "maritime", 0]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[4, 1],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 1],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
		{
			id: "boundary_2_z5-maritime",
			type: "line",
			source: "openmaptiles",
			"source-layer": "boundary",
			minzoom: 5,
			filter: ["all", ["==", "admin_level", 2], ["==", "maritime", 1]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "none",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[4, 0.2],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 1],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
		{
			id: "park_name",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "park",
			minzoom: 10,
			layout: {
				"text-field": "{name}",
				"text-size": 12,
				"text-font": ["Roboto Condensed Italic"],
			},
			paint: {
				"text-color": "#0b7d17",
			},
		},
		{
			id: "water_name_line",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "waterway",
			filter: ["all", ["==", "$type", "LineString"]],
			layout: {
				"text-field": "{name}",
				"text-font": ["Roboto Regular"],
				"text-max-width": 5,
				"text-size": 12,
				"symbol-placement": "line",
			},
			paint: {
				"text-color": "#5d60be",
				"text-halo-color": "rgba(255,255,255,0.7)",
				"text-halo-width": 1,
			},
		},
		{
			id: "water_name_point",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "water_name",
			filter: ["==", "$type", "Point"],
			layout: {
				"text-field": "{name}",
				"text-font": ["Roboto Regular"],
				"text-max-width": 5,
				"text-size": 12,
			},
			paint: {
				"text-color": "#5d60be",
				"text-halo-color": "rgba(255,255,255,0.7)",
				"text-halo-width": 1,
			},
		},
		{
			id: "poi_z16",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "poi",
			minzoom: 16,
			filter: ["all", ["==", "$type", "Point"], [">=", "rank", 20]],
			layout: {
				"icon-image": "osm:{class}_11",
				"text-anchor": "top",
				"text-field": "{name}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 9,
				"text-offset": [0, 0.6],
				"text-size": 12,
				visibility: "none",
			},
			paint: {
				"text-color": "#666",
				"text-halo-blur": 0.5,
				"text-halo-color": "#ffffff",
				"text-halo-width": 1,
			},
		},
		{
			id: "poi_z15",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "poi",
			minzoom: 15,
			filter: ["all", ["==", "$type", "Point"], [">=", "rank", 7], ["<", "rank", 20]],
			layout: {
				"icon-image": "osm:{class}_11",
				"text-anchor": "top",
				"text-field": "{name}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 9,
				"text-offset": [0, 0.6],
				"text-size": 12,
				visibility: "none",
			},
			paint: {
				"text-color": "#666",
				"text-halo-blur": 0.5,
				"text-halo-color": "#ffffff",
				"text-halo-width": 1,
			},
		},
		{
			id: "poi_z14",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "poi",
			minzoom: 14,
			filter: ["all", ["==", "$type", "Point"], [">=", "rank", 1], ["<", "rank", 7]],
			layout: {
				"icon-image": "osm:{class}_11",
				"text-anchor": "top",
				"text-field": "{name}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 9,
				"text-offset": [0, 0.6],
				"text-size": 12,
				visibility: "visible",
			},
			paint: {
				"text-color": "#666",
				"text-halo-blur": 0.5,
				"text-halo-color": "#ffffff",
				"text-halo-width": 1,
				"icon-opacity": 1,
			},
		},
		{
			id: "poi_transit",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "poi",
			filter: ["all", ["in", "class", "bus"]],
			layout: {
				"icon-image": "osm:{class}_11",
				"text-anchor": "left",
				"text-field": "{name_en}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 9,
				"text-offset": [0.9, 0],
				"text-size": 12,
				visibility: "visible",
				"icon-size": 1.3,
			},
			paint: {
				"text-color": "#4898ff",
				"text-halo-blur": 0.5,
				"text-halo-color": "#ffffff",
				"text-halo-width": 1,
				"icon-halo-color": "#4898ff",
				"icon-halo-blur": 0,
				"icon-halo-width": 0,
			},
		},
		/* {
			id: "road_label",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "transportation_name",
			filter: ["all"],
			layout: {
				"symbol-placement": "line",
				"text-anchor": "center",
				"text-field": "{name}",
				"text-font": ["Roboto Regular"],
				"text-offset": [0, 0.15],
				"text-size": {
					base: 1,
					stops: [
						[13, 12],
						[14, 13],
					],
				},
				visibility: "visible",
			},
			paint: {
				"text-color": "#765",
				"text-halo-blur": 0.5,
				"text-halo-width": 1,
			},
		}, */
		/* {
			id: "road_shield",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "transportation_name",
			minzoom: 7,
			filter: ["all", ["<=", "ref_length", 10]],
			layout: {
				"icon-image": "osm:default_{ref_length}",
				"icon-rotation-alignment": "viewport",
				"symbol-placement": {
					base: 1,
					stops: [
						[10, "point"],
						[11, "line"],
					],
				},
				"symbol-spacing": 500,
				"text-field": "{ref}",
				"text-font": ["Roboto Regular"],
				"text-offset": [0, 0.1],
				"text-rotation-alignment": "viewport",
				"text-size": 10,
				"icon-size": 0.8,
				visibility: "visible",
			},
		}, */
		{
			id: "place",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			minzoom: 14,
			layout: {
				"text-field": "{name_en}",
				visibility: "visible",
				"text-font": ["Roboto Condensed Italic"],
				"text-size": 12,
			},
			paint: {
				"text-color": "rgba(100, 95, 95, 1)",
			},
		},
		{
			id: "place_other",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			filter: ["all", ["in", "class", "hamlet", "island", "islet", "neighbourhood", "suburb", "quarter"]],
			layout: {
				"text-field": "{name_en}",
				"text-font": ["Roboto Condensed Italic"],
				"text-letter-spacing": 0.1,
				"text-max-width": 9,
				"text-size": {
					base: 1.2,
					stops: [
						[12, 10],
						[15, 14],
					],
				},
				"text-transform": "uppercase",
			},
			paint: {
				"text-color": "#633",
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1.2,
			},
		},
		{
			id: "place_village",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			filter: ["all", ["==", "class", "village"]],
			layout: {
				"text-field": "{name:latin}",
				"text-font": ["Roboto Regular"],
				"text-max-width": 8,
				"text-size": {
					base: 1.2,
					stops: [
						[10, 12],
						[15, 22],
					],
				},
				visibility: "visible",
			},
			paint: {
				"text-color": "#333",
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1.2,
			},
		},
		{
			id: "place_town",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			filter: ["all", ["==", "class", "town"], ["has", "rank"]],
			layout: {
				"icon-image": {
					base: 1,
					stops: [
						[0, "osm:dot_9"],
						[8, ""],
					],
				},
				"text-anchor": "bottom",
				"text-field": "{name:latin}",
				"text-font": ["Roboto Regular"],
				"text-max-width": 8,
				"text-offset": [0, 0],
				"text-size": {
					base: 1.2,
					stops: [
						[7, 12],
						[11, 16],
					],
				},
				visibility: "visible",
			},
			paint: {
				"text-color": "#333",
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1.2,
			},
		},
		{
			id: "place_city",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			minzoom: 5,
			filter: ["all", ["==", "class", "city"]],
			layout: {
				"icon-image": {
					base: 1,
					stops: [
						[0, "osm:dot_9"],
						[8, ""],
					],
				},
				"text-anchor": "bottom",
				"text-field": "{name:latin}",
				"text-font": ["Roboto Medium"],
				"text-max-width": 8,
				"text-offset": [0, 0],
				"text-size": {
					base: 1.2,
					stops: [
						[7, 14],
						[11, 24],
					],
				},
				"icon-allow-overlap": false,
				"icon-optional": false,
				visibility: "visible",
			},
			paint: {
				"text-color": "#333",
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1.2,
			},
		},
		{
			id: "state",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			maxzoom: 6,
			filter: ["all", ["==", "class", "state"]],
			layout: {
				"text-field": "{name_en}",
				"text-font": ["Roboto Condensed Italic"],
				"text-size": {
					stops: [
						[4, 11],
						[6, 15],
					],
				},
				"text-transform": "uppercase",
				visibility: "visible",
			},
			paint: {
				"text-color": "#633",
				"text-halo-color": "rgba(255,255,255,0.7)",
				"text-halo-width": 1,
			},
		},
		{
			id: "country_1",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			maxzoom: 7,
			filter: ["all", ["==", "rank", 1], ["==", "class", "country"]],
			layout: {
				"text-field": "{name:lv}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 6.25,
				"text-size": {
					stops: [
						[1, 11],
						[4, 17],
					],
				},
				"text-transform": "none",
			},
			paint: {
				"text-color": "#334",
				"text-halo-blur": 1,
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1,
			},
		},
		{
			id: "country_2",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			maxzoom: 7,
			filter: ["all", ["==", "rank", 2], ["==", "class", "country"]],
			layout: {
				"text-field": "{name:lv}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 6.25,
				"text-size": {
					stops: [
						[2, 11],
						[5, 17],
					],
				},
				"text-transform": "none",
			},
			paint: {
				"text-color": "#334",
				"text-halo-blur": 1,
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1,
			},
		},
		{
			id: "country_3",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "place",
			maxzoom: 7,
			filter: ["all", [">=", "rank", 3], ["==", "class", "country"]],
			layout: {
				"text-field": "{name:lv}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 6.25,
				"text-size": {
					stops: [
						[3, 11],
						[7, 17],
					],
				},
				"text-transform": "none",
			},
			paint: {
				"text-color": "#334",
				"text-halo-blur": 1,
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1,
			},
		},
		{
			id: "tnt_network",
			type: "line",
			source: "openmaptiles",
			"source-layer": "tnt_network",
			maxzoom: 7,
			layout: {
				visibility: "visible",
			},
			paint: {
				"line-offset": 0,
				"line-width": {
					base: 1.4,
					stops: [
						[3, 1.5],
						[8, 3],
					],
				},
				"line-color": "#fbc0ac",
			},
		},
		{
			id: "europe_country",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "europe_points",
			maxzoom: 7,
			filter: ["all", ["==", "$type", "Point"], ["any", ["has", "ISO3166-1"], ["==", "place", "country"]]],
			layout: {
				"text-field": "{name:en}",
				"text-font": ["Roboto Condensed Italic"],
				"text-max-width": 6.25,
				"text-size": {
					stops: [
						[3, 11],
						[7, 17],
					],
				},
				"text-transform": "none",
				"text-allow-overlap": false,
			},
			paint: {
				"text-color": "#334",
				"text-halo-blur": 1,
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1,
			},
		},
		{
			id: "europe_capital_lv",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "europe_points",
			minzoom: 5,
			filter: ["all", ["==", "$type", "Point"], ["==", "capital", "yes"], ["==", "int_name", "Rīga"]],
			layout: {
				"icon-image": {
					base: 1,
					stops: [
						[0, "osm:dot_9"],
						[8, ""],
					],
				},
				"text-anchor": "bottom",
				"text-field": "{int_name}",
				"text-font": ["Roboto Medium"],
				"text-max-width": 8,
				"text-offset": [0, 0],
				"text-size": {
					base: 1.2,
					stops: [
						[7, 14],
						[11, 24],
					],
				},
				"icon-allow-overlap": false,
				"icon-optional": false,
				visibility: "visible",
			},
			paint: {
				"text-color": "#333",
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1.2,
			},
		},
		{
			id: "europe_capital",
			type: "symbol",
			source: "openmaptiles",
			"source-layer": "europe_points",
			minzoom: 5,
			filter: ["all", ["==", "$type", "Point"], ["==", "capital", "yes"], ["!=", "int_name", "Rīga"]],
			layout: {
				"icon-image": {
					base: 1,
					stops: [
						[0, "osm:dot_9"],
						[8, ""],
					],
				},
				"text-anchor": "bottom",
				"text-field": "{name:en}",
				"text-font": ["Roboto Medium"],
				"text-max-width": 8,
				"text-offset": [0, 0],
				"text-size": {
					base: 1.2,
					stops: [
						[7, 14],
						[11, 24],
					],
				},
				"icon-allow-overlap": false,
				"icon-optional": false,
				visibility: "visible",
			},
			paint: {
				"text-color": "#333",
				"text-halo-color": "rgba(255,255,255,0.8)",
				"text-halo-width": 1.2,
			},
		},
		{
			id: "border_latvia",
			type: "line",
			source: "openmaptiles",
			"source-layer": "latvia_border",
			maxzoom: 7,
			filter: [
				"all",
				["!=", "source_ref", "https://www.marineregions.org/documents/bulletin76e.pdf"],
				["!=", "source_ref", "http://www.likumi.lv/doc.php?id=63730"],
				["!=", "source_ref", "http://www.lrvk.lt/bylos/Teises_aktai/2004/12/3954.doc"],
			],
			layout: {
				"line-cap": "round",
				"line-join": "round",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[4, 1],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 2],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
		{
			id: "europe_borders",
			type: "line",
			source: "openmaptiles",
			"source-layer": "europe_borders",
			filter: ["all", ["==", "$type", "LineString"], ["!=", "maritime", "yes"]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
				visibility: "visible",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[4, 1],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 0.5],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
		{
			id: "europe_borders_maritime",
			type: "line",
			source: "openmaptiles",
			"source-layer": "europe_borders",
			filter: ["all", ["==", "maritime", "yes"], ["==", "$type", "LineString"]],
			layout: {
				"line-cap": "round",
				"line-join": "round",
			},
			paint: {
				"line-color": "hsl(248, 1%, 41%)",
				"line-opacity": {
					base: 1,
					stops: [
						[0, 0.4],
						[4, 0.2],
					],
				},
				"line-width": {
					base: 1,
					stops: [
						[3, 1],
						[5, 1.2],
						[12, 3],
					],
				},
			},
		},
	] as any,
};
