import { useObservable } from "@ngneat/use-observable";
import React, { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { getMapStore } from "../Map.repository";
import { VectorTile } from "ol/source";
import { createSource } from "../../data-layers/Data-layers-new-layer-manager";
import VectorTileLayer from "ol/layer/VectorTile";
import { ENTRYPOINT } from "../Endpoints";

export const Websocket = () => {
	const { lastJsonMessage } = useWebSocket<{ message: string }>(`wss://${ENTRYPOINT.replaceAll("https://", "").replaceAll("/gis", "")}/ws/ws/lau/transportunit`, {
		// Will attempt to reconnect on all close events, such as server shutting down
		shouldReconnect: () => !window?.location?.origin?.includes("localhost"),
	});
	const [mapStore] = useObservable(getMapStore());

	useEffect(() => {
		const map = mapStore.map;

		if (lastJsonMessage && map) {
			map.getAllLayers().forEach((l) => {
				if (l.get("url")?.includes(lastJsonMessage.message.replace("UPDATED:", ""))) {
					console.log(l);
					let refreshCount = 0;
					const source = l.getSource() as VectorTile;
					const params = source.get("params");
					params.url = params.url + "?cacheBuster=" + Date.now();
					const newSource = createSource(params);
					const layer = new VectorTileLayer({
						zIndex: -10000,
						source: newSource,
					});
					let count = 0;
					const l1 = newSource.on("tileloadstart", () => {
						count = count + 1;
					});
					const l2 = newSource.on("tileloadend", () => {
						count = count - 1;
						if (count === 0) {
							l.setSource(newSource);
							map.removeLayer(layer);
							newSource.un(l1.type as any, l1.listener);
							newSource.un(l2.type as any, l2.listener);
							refreshCount = refreshCount - 1;
						}
					});
					map.addLayer(layer);
				}
			});
		}
	}, [lastJsonMessage, mapStore]);

	return <></>;
};
