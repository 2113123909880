export const ATTRIBUTE_LIST = {
	"public.kafkamessages_uvismessage": [
		"road_name",
		"road_conditions_extra_status",
		"zd_operation",
		"rainfall",
		"meteo",
		"air_temperature_from",
		"air_temperature_to",
		"notes",
	],
	"public.kafkamessages_cms": ["file_field", "t1", "ts1dst111", "vi", "pr"],
	"public.lau_transportunit": ["vehicle_type"],
	"public.kafkamessages_plannedevent_public": ["notes", "date_from", "restrictions", "detour"],
	"public.kafkamessages_plannedevent_detour": ["detour"],
	"public.kafkamessages_notplannedevent_public": ["notes", "date_from", "restrictions", "detour"],
	"public.kafkamessages_notplannedevent_detour": ["detour"],
};

export const LABELS = {
	detour: "Apbraukšanas maršruts",
	restrictions: "Ierobežojumi",
	id: "ID",
	kafka_id: "Identifikācijas kods",
	date_from: "Notikuma sākums",
	is_sync_ok: "Veiksmīga datu apstrāde",
	animal_alive_status: "Dzīvnieka dzīvibas veids",
	sync_error: "Kļūda apstrādājot datus no KAFKAS",
	message: "message",
	is_geom: "Ir atrašanās vieta",
	the_geom: "Atrašanās vieta",
	line_centers: "Līniju centri",
	linear_reference: "Lineārā reference",
	sipr_id: "Identifikācijas kods SIPR sistēmā",
	road_name: "Posms",
	area_name: "Apkārtne",
	srti: "Augsta bīstamība satiksmei",
	rtti: "RTTI",
	meteo: "Meteoroloģiskā parādība",
	rainfall: "Nokrišņu veids",
	radius: "Radius",
	zd_operation: "Ceļu uzturētāja darbība",
	road_conditions: "Ceļa stāvoklis",
	road_conditions_extra_status: "Ceļa stāvoklis",
	air_temperature_from: "Gaisa temperatūra no, °C",
	air_temperature_to: "Gaisa temperatūra līdz, °C",
	parent_id: "Virsobjekta ID",
	correlation_id: "Korelācijas ID",
	datex_enum: "Ziņojuma veids",
	datex_class: "Datex klase",
	datex_subclass: "Datex apakšklase",
	notes: "Piezīmes",
	created: "Izveidots",
	updated: "Atjaunošanas laiks",
	action: "Darbība",
	lr_road: "Ceļa posms",
	lr_km_from: "Kilometrs no",
	lr_km_to: "Kilometrs līdz",
	device_type: "Tips",
	device_sub_type: "Apakštips",
	device_name: "Iekārtas nosaukums",
	status: "Status",
	road_lane: "Braukšanas josla",
	period_seconds: "Nolasīšanas intervāls(s)",
	host: "IP adrese",
	port: "Ports",
	sensors: "Sensori",
	is_test: "Testa iekārta",
	error: "Kļūda",
	last_called: "Pēdējo konekcija",
	file_field: "Attēls",
	placement_date: "Uzstādīts no",
	removal_date: "Uzstādīts līdz",
	is_imported: "Importēts",
	t1: "Gaisa temperatūra, °C",
	t_max: "Gaisa temperatūra (max), °C",
	t_min: "Gaisa temperatūra (min), °C",
	rh1: "Mitrums",
	td1: "Kušanas temperatūra",
	rd1: "Lietus ir/nav",
	ws1: "Vēja ātrums (10 min.vidēji)",
	wd1: "Vēja virziens (10 min.vidēji)",
	pr: "Nokrišņi (summāri mm/h)",
	ri: "Lietus intensitāte",
	sh: "Sniega daudzums",
	vi: "Redzamība (m)",
	ch2: "Konfigurējams mērījums",
	mst: "Kopējais statuss",
	rs: "Lietus klase",
	ts1: "Virsmas temperatūra, °C",
	ts1drs511: "Virsmas temperatūra, °C",
	ts1dst111: "Virsmas temperatūra, °C",
	tg1: "Zemes temperatūra",
	cs1: "Vadītspēja",
	ss1: "Virsmas signāls",
	bi1: "Melnā ledus frekvence",
	tr1: "Sasalšanas punkts, Tr",
	st1: "Virsmas status",
	sst_s1: "Virsmas stāvoklis",
	sst_r1: "Virsmas lietus stāvoklis",
	sst_w1: "Virsmas brīdinājums",
	cn1: "Koncentrācija",
	ge1: "Ķimikāliju daudzums",
	tf1: "Sasalšanas punkts (šķidruma temperatūra), Tf",
	wt1: "Ūdens biezums vai pārklājuma biezums",
	ts2: "Virsmas temperatūra",
	tg2: "Zemes temperatūra",
	cs2: "Vadītspēja",
	ss2: "Virsmas signāls",
	bi2: "Melnā ledus frekvence",
	tr2: "Sasalšanas punkts, Tr",
	st2: "Virsmas status",
	sst_s2: "Virsmas stāvoklis",
	sst_r2: "Virsmas lietus stāvoklis",
	sst_w2: "Virsmas brīdinājums",
	cn2: "Koncentrācija",
	ge2: "Ķimikāliju daudzums",
	tf2: "Sasalšanas punkts (šķidruma temperatūra), Tf",
	wt2: "Ūdens biezums vai pārklājuma biezums",
	nws: "NWS kodi",
	ensst: "Saķeres līmenis",
	ensst_s: "Saķeres stāvoklis",
	ensst_w: "Saķeres brīdinājums",
	fds: "Kopējais statuss",
	fds_s: "Redzamības status",
	fds_w: "Aparatūras status",
	errors: "Kļūdas",
	warnings: "Brīdinājumi",
	has_warnings: "Ir brīdinājumi",
	rd1_raw: "Lietus raw",
	mst_raw: "Status raw",
	rs_raw: "Lietus klase raw",
	fds_s_raw: "Redzamības status raw",
	fds_w_raw: "Aparatūras status raw",
	minutemark: "Aktuālākā mērījuma nolasīšanas datums, laiks",
	interpretation: "Interpretācija",
	vehicle_type: "Transportlīdzekļa tips",
};
