import { MapGeoJSONFeature } from "maplibre-gl";
import { ATTRIBUTE_LIST, LABELS } from "./embed-popup-attributes";
import { CLASSIFIERS } from "./classifiers";
import ImageDisplay from "../../map/hover/Image-preview";
import moment from "moment";
import { t } from "i18next";

export const getValue = (value: string, attribute: string, feature: MapGeoJSONFeature) => {
	if (attribute === "date_from") {
		return moment(value).format("YYYY-MM-DD HH:mm");
	}

	if (attribute === "restrictions") {
		value = ["[", "]", "'", "'"].reduce((string, valueToRemove) => string.replaceAll(valueToRemove, ""), value);
	}

	const classifierValue = (CLASSIFIERS as any)[feature.sourceLayer!.replace("public.", "")]?.[attribute]?.[value];
	return classifierValue ?? value;
};

const getMiniTitle = (feature: MapGeoJSONFeature) => {
	if (feature.sourceLayer === "public.lau_transportunit") {
		return <p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("lauWarning")}!</p>;
	}
	return <></>;
};

const getMiniDesc = (feature: MapGeoJSONFeature) => {
	if (feature.sourceLayer === "public.lau_transportunit") {
		switch (feature.properties["vehicle_type"]) {
			case "Lorry":
				return <p>{t("lauWarningLorry")}.</p>;
			case "Grader":
			case "Tractor":
				return <p>{t("lauWarningGrader")}.</p>;
			case "Car":
				return <p>{t("lauWarningCar")}.</p>;
		}
	}

	return <></>;
};

export default function EmbedPopupDetails({ feature }: { feature: MapGeoJSONFeature }) {
	const filterAttribute = (value: string | undefined) => {
		return value !== undefined && value !== "" && !value.toLowerCase().includes("none");
	};

	return (
		<ul className="text-base flex flex-col gap-1">
			{getMiniTitle(feature)}
			{ATTRIBUTE_LIST[feature.sourceLayer as keyof typeof ATTRIBUTE_LIST]
				.filter((a) => filterAttribute(feature.properties[a]?.toString()))
				.map((attribute: string) => {
					const props = feature.properties;

					if (attribute === "file_field") {
						return <ImageDisplay key={attribute} hexData={props[attribute]}></ImageDisplay>;
					}

					return (
						<li key={attribute} className="flex items-center gap-2 break-keep">
							<p>
								<span className="font-semibold mr-2">{LABELS[attribute as keyof typeof LABELS] ?? attribute}:</span>
								{getValue(props[attribute], attribute, feature)}
							</p>
						</li>
					);
				})}
			{getMiniDesc(feature)}
		</ul>
	);
}
