import { BehaviorSubject, take } from "rxjs";
import { LayerSetResponse, PointStyle } from "../../store/Layers.repository";
import { useObservable } from "@ngneat/use-observable";
import LayersetList from "./Layerset-list";
import { getStyleItems, StyleLayerProp, updateStyleItems } from "../../data-layers/Style-layer.repository";
import { getMapStore } from "../Map.repository";
import LayerGroup from "ol/layer/Group";
import { stylefunction } from "ol-mapbox-style";
import { createMapboxStyle } from "../../data-layers/Mapbox-style-generator";
import VectorTileLayer from "ol/layer/VectorTile";
import spriteJson from "../../shared/sprites/icons.json";
import iconspng from "../../shared/sprites/icons.png";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { OVERLAY_MANAGER } from "../overlay/Select";

export const LAYER_SET$ = new BehaviorSubject<LayerSetResponse[]>([]);
export const LAYER_SET_SELECTED$ = new BehaviorSubject<LayerSetResponse | undefined>(undefined);
export const DEFAULT_LAYERSET: LayerSetResponse = {
	data_layers: [],
	name: "",
	id: 0,
	published: false,
	enabled: false,
	svg_symbol: "",
};

export default function LayersetWrapper() {
	const [layerset] = useObservable(LAYER_SET$);
	const [mapStore] = useObservable(getMapStore());
	const [searchParams] = useSearchParams();

	const checkPoint = (point: PointStyle, ls: LayerSetResponse, enabled: boolean) => {
		switch (ls.name) {
			case "Ziemas apstākļi":
			case "Winter conditions":
			case "Ziemas braukšanas apstākļi":
			case "Winter driving conditions":
				point.piktogram = enabled
					? point.piktogram?.replace("c_cctv_icon_02.svg", "c_cctv_icon_01.svg")
					: point.piktogram?.replace("c_cctv_icon_01.svg", "c_cctv_icon_02.svg");
				return point;
		}
		point.piktogram = point.piktogram?.replace("c_cctv_icon_01.svg", "c_cctv_icon_02.svg");
		return point;
	};

	const setMap = (ls: LayerSetResponse, enabled: boolean) => {
		OVERLAY_MANAGER.setPosition(undefined);

		const dataLayer = mapStore.map
			?.getLayerGroup()
			.getLayers()
			.getArray()
			.find((l) => l.get("type") === "data")! as LayerGroup;

		let styleItems: StyleLayerProp[] = [];
		getStyleItems()
			.pipe(take(1))
			.subscribe((sl) => (styleItems = sl));

		dataLayer?.getLayersArray().forEach((l) => {
			const styleLayers = styleItems
				.flatMap((sl) => sl.layers)
				.filter((sl) => ls.data_layers.includes(Number(sl.id.replace(sl.name + "-", ""))))
				.filter((styleLayer) => styleLayer.table === l.get("id"));

			if (styleLayers.length > 0) {
				const names = styleItems.map((sl) => sl.name);
				styleLayers.sort((a, b) => {
					const g1 = styleItems.find((sl) => !!sl.layers.find((layer) => layer.name === a.name))!.name;
					const g2 = styleItems.find((sl) => !!sl.layers.find((layer) => layer.name === b.name))!.name;
					return names.indexOf(g2) === names.indexOf(g1) ? (a.index > b.index ? -1 : 1) : names.indexOf(g2) - names.indexOf(g1);
				});
				const lines = styleLayers.map((i) => i.lines).flat();
				const points = styleLayers.map((i) => i.points).flat();

				points.map((p) => checkPoint(p, ls, enabled));

				const mapboxStyle = createMapboxStyle(lines, points, l.get("url"), l.get("use_uvis_popups"));
				stylefunction(l as VectorTileLayer, mapboxStyle, "features", undefined, spriteJson, iconspng);
				l.setVisible(true);
			} else {
				l.setVisible(false);
			}
		});

		const updatedItems = styleItems.map((sl) => {
			const layers = sl.layers.map((l) => {
				const visible = !!ls.data_layers.includes(Number(l.id.replace(l.name + "-", "")));
				return { ...l, visible, points: l.points.map((p) => checkPoint(p, ls, enabled)) };
			});

			return { ...sl, visible: layers.filter((l) => l.visible).length > 0, layers };
		});

		updateStyleItems(updatedItems as any);
	};

	const enable = (ls: LayerSetResponse) => {
		LAYER_SET_SELECTED$.next(ls);
		setMap(ls, true);
	};

	const disable = () => {
		LAYER_SET_SELECTED$.next(DEFAULT_LAYERSET);
		setMap({ ...DEFAULT_LAYERSET, name: LAYER_SET_SELECTED$.value?.name ?? "" }, false);
	};

	useEffect(() => {
		const enabledLS = LAYER_SET$.value.find((ls) => ls.enabled);
		if (enabledLS && searchParams.size === 0) {
			LAYER_SET_SELECTED$.next(enabledLS);
			setMap(enabledLS, true);
		}
	}, []);

	return (
		<div className="flex flex-wrap absolute top-[6.5rem] left-4 max-w-[600px] gap-[14px] pointer-events-none">
			<LayersetList layersets={layerset} enableLayerset={enable} disableLayerset={disable}></LayersetList>
		</div>
	);
}
