import axios from "axios";
import { useEffect } from "react";
import { ENTRYPOINT } from "../map/Endpoints";

// Generated by https://quicktype.io

export interface ClassifierResponse {
	classificator: string;
	reference_list: { [key: string]: string[] };
	values: Value[];
}

export interface Value {
	value: string;
	title: string;
}

export const CLASSIFIERS_NEW: { [tableName: string]: { [key: string]: { [val: string]: string } } } = {};

export const ClassifierComponent = () => {
	useEffect(() => {
		axios.get<ClassifierResponse[]>(ENTRYPOINT + "/api/classificators").then((res) => {
			res.data.forEach((classificator) => {
				const items = classificator.reference_list;
				let values: { [name: string]: string } = {};
				classificator.values.forEach((v) => {
					values[v.value] = v.title;
				});
				if (Object.keys(values).length > 0) {
					Object.keys(items).forEach((key) => {
						items[key].forEach((fieldName) => (CLASSIFIERS_NEW[key] = { ...(CLASSIFIERS_NEW[key] ?? {}), [fieldName]: values }));
					});
				}
			});
		});
	}, []);

	return <></>;
};
