import { LayerSpecification } from "maplibre-gl";

export const LAU_POINT: LayerSpecification = {
	id: "lau-point",
	source: "lau",
	"source-layer": "public.lau_transportunit",
	type: "symbol",
	filter: ["all", ["==", ["geometry-type"], "Point"]],
	layout: {
		"icon-image": "c_lau",
		"icon-rotation-alignment": "viewport",
		"icon-size": 1,
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};
