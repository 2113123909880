import { createStore } from "@ngneat/elf";
import { withEntities, selectAllEntities, setEntities } from "@ngneat/elf-entities";
// Generated by https://quicktype.io

export interface MainLayerResponse {
	base: LayerResponse[];
	remote: RemoteResponse[];
	data: DataResponse[];
}

export interface ParametersParameters {
	version?: number | string;
	name?: string;
	metadata?: any;
	sources?: any;
	glyphs?: string;
	layers?: any[];
	id?: string;
	sprite?: string;
	srs?: string;
	style?: string;
}

export interface RemoteResponse {
	id: number;
	name: string;
	layercategorybase_ptr_id: number;
	layers: LayerResponse[];
	attributions: string;
	url_to_datasource: string;
	use_uvis_popups: boolean;
}

export interface LayerSetResponse {
	id: number;
	name: string;
	published: boolean;
	data_layers: number[];
	enabled: boolean;
	svg_symbol: string;
}

export interface LayerParameters {
	LAYER: string;
	VERSION: string;
	SERVICE: string;
}

export interface LayerResponse {
	id: number;
	name: string;
	opacity: number;
	enabled: boolean;
	visible: boolean;
	attributions: string;
	type: string;
	url: string;
	parameters: any;
	thumbnail: string | null;
	remotesourcesbase_ptr_id: number;
}

export interface DataResponse {
	id: number;
	name: string;
	layercategorybase_ptr_id: number;
	attributions: string;
	url_to_datasource: string;
	layers: LayerStyle[];
}

export interface LayerStyle {
	mvt_url: string;
	id: number;
	category_id: number;
	data_api_filter: string | null;
	name: string;
	groupName: string;
	table: string;
	merged_tiles: string | null;
	original_table?: string;
	enabled: boolean;
	visible: boolean;
	label: string;
	label_style: string;
	attributes: string;
	attributions: string;
	zoom_from: number;
	zoom_to: number;
	opacity: number;
	points: PointStyle[];
	lines: LineStyle[];
	clustered: boolean;
	index: number;
	use_uvis_popups?: boolean;
	records_exist: boolean;
}

// Generated by https://quicktype.io

export interface PointStyle {
	id: number;
	layer_id: number;
	name: string;
	layerName: string;
	groupName: string;
	filter: string;
	piktogram: string;
	color: string;
	size: number;
	rotation: number;
	offset_x: number;
	offset_y: number;
	minZoom: number;
	maxZoom: number;
	opacity: number;
	source?: string;
	popup_title?: string;
	clustered: boolean;
}

export interface LineStyle {
	id: number;
	layer_id: number;
	name: string;
	layerName: string;
	groupName: string;
	filter: string | null;
	width: number;
	offset: number | null;
	stroke_style: string;
	color: string;
	join_style: string;
	cap_style: string;
	piktogram: string;
	minZoom: number;
	maxZoom: number;
	opacity: number;
	source?: string;
	label_style: string;
	label: string;
}

export interface LayerEntity extends LayerResponse {
	group: "base" | "data" | "remote";
}

export interface AltLayerEntity extends LayerStyle {
	group: "base" | "data" | "remote";
}

export interface GroupEntity {
	attributions?: string;
	id: string;
	name: string;
	group: "base" | "data" | "remote";
	layers: LayerEntity[] | AltLayerEntity[];
	dataSource?: string;
	use_uvis_popups: boolean;
	thumbnail?: string;
}

const STORE = createStore({ name: "streets" }, withEntities<GroupEntity>());

export const getGroupEntities = () => {
	return STORE.pipe(selectAllEntities());
};

export const setLayerEntities = (results: GroupEntity[]) => {
	STORE.update(setEntities(results));
};
