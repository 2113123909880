import { createStore, select, setProp, withProps } from "@ngneat/elf";

export interface SettingstoreProps {
	open?: boolean;
	showLegendBtn?: boolean;
	showAttributionBtn?: boolean;
	showPanBtn?: boolean;
	showBaseOpacity?: boolean;
	baseOpacity?: number;
	showObjectOpacity?: boolean;
	objectOpacity?: number;
	showLayerSets?: boolean;
}

const storage = JSON.parse(localStorage.getItem("setting") as string);

const settingStore = createStore(
	{ name: "setting" },
	withProps<SettingstoreProps>({
		open: false,
		showLegendBtn: storage?.showLegendBtn ?? true,
		showAttributionBtn: storage?.showAttributionBtn ?? true,
		showPanBtn: storage?.showPanBtn ?? true,
		showBaseOpacity: storage?.showBaseOpacity ?? true,
		showObjectOpacity: storage?.showObjectOpacity ?? true,
		showLayerSets: storage?.showObjectOpacity ?? true,
	})
);

export function getSettingStore() {
	return settingStore.pipe(select((state) => state));
}

export function updateSettingStore(props: SettingstoreProps) {
	for (const key in props) {
		if (Object.prototype.hasOwnProperty.call(props, key)) {
			settingStore.update(setProp(key as keyof SettingstoreProps, (props as any)[key]));
		}
	}

	localStorage.setItem("setting", JSON.stringify(settingStore.getValue()));
}
