import { useEffect, useRef, useState } from "react";
import SearchResult from "./Search-result";
import { getSettingStore, updateSettingStore } from "../settings/Settings.repository";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/use-observable";
import { getSearchStore, updateSearchStore } from "./Search.repository";
import Button1Component from "../shared/UI kit/button-1";
import { CloseOutlined } from "@ant-design/icons";
import gif from "../shared/img/lvc-loading-dots.gif";
import LayerSwitcherWrapper from "../data-layers/layer-switcher/Layer-switcher-wrapper";
import { BehaviorSubject, debounceTime } from "rxjs";
import { LogoBtn } from "./Logo-btn";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Tooltip } from "antd";
import { LayersSVG } from "../shared/svg/layers-svg";
import { SearchSVG } from "../shared/svg/search-svg";
import { BackSVG } from "../shared/svg/back-svg";
import LayersetWrapper from "../map/layer-set/Layerset-wrapper";
import { overviewActive$ } from "../map/bubble/Bubble-wrapper";

let maxWidth = 0;
const val$ = new BehaviorSubject<string>("");

function SearchBar({ searchItems }: { searchItems: any }) {
	const [val, setVal] = useState(window.location.hash?.split("&")[2]?.includes("q=") ? decodeURI(window.location.hash?.split("&")[2].replace("q=", "")) : "");
	const [searchParams, setSearchParams] = useSearchParams();
	const [expended, setExpended] = useState(!searchParams.has("min"));
	const [toggle] = useState(false);
	const [menu, setMenu] = useState(false);
	const [mobileSearch, setMobileSearch] = useState(false);
	const [settings] = useObservable(getSettingStore());
	const [overview] = useObservable(overviewActive$);

	const [t] = useTranslation();
	const element = useRef<HTMLDivElement | null>(null);
	const [searchStore] = useObservable(getSearchStore());

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	const searchRef = useRef<HTMLInputElement>(null);

	const handleSubmit = (event: any) => {
		event.preventDefault();
		searchItems(searchRef.current?.value);
	};

	const getMaxWidth = () => {
		const width = element.current?.offsetWidth ?? 0;
		if (100 < width) maxWidth = width;
		return maxWidth ?? 1;
	};

	const getMaxHeight = () => {
		return window.innerHeight - 128;
	};

	useEffect(() => {
		const sub = val$.pipe(debounceTime(500)).subscribe((v) => searchItems(v));
		return () => sub.unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isMobile)
		document.addEventListener("click", (e) => {
			if (!document.getElementById("container")?.contains(e.target as any)) {
				setMenu(false);
			}
		});

	return (
		<>
			<div
				id="container"
				className={classNames(searchParams.has("min") ? "sm:absolute" : "absolute", "top-2 left-2 sm:top-4 sm:left-4 right-2 sm:right-auto drop-shadow-md z-50")}
			>
				<div ref={element} className="flex items-center pointer-events-auto w-full">
					<div
						className={classNames(
							expended ? "rounded-t" : "rounded",
							"px-[15px] py-[19px] bg-white h-[74px] shadow-md flex gap-2 sm:gap-4 items-center relative w-full"
						)}
					>
						{mobileSearch ? (
							<button
								onClick={() => {
									setVal("");
									updateSearchStore({ data: undefined });
									setMobileSearch(!mobileSearch);
								}}
								className="w-[24px] h-[24px] flex items-center"
							>
								<BackSVG></BackSVG>
							</button>
						) : (
							<LogoBtn overviewOpened={() => setMenu(false)}></LogoBtn>
						)}
						<div className={classNames(expended ? "" : "hidden", "flex items-center gap-5 w-full")}>
							{isMobile && !mobileSearch && (
								<button onClick={() => setMobileSearch(!mobileSearch)} className="mx-auto p-1 ring-npp-blue-500 active:ring-2 rounded-lg">
									<SearchSVG></SearchSVG>
								</button>
							)}
							{(!isMobile || mobileSearch) && (
								<Tooltip
									title={t("searchTooltipHelp")}
									placement="right"
									overlayClassName="font-bold leading-tight"
									color="#4d4f50"
									overlayInnerStyle={{ padding: ".25rem .75rem", borderRadius: ".25rem", textAlign: "center", lineHeight: 1.25, width: "16rem" }}
								>
									<form onSubmit={handleSubmit} className="flex-grow sm:basis-1/2 relative flex items-center">
										<input
											onClick={() =>
												setTimeout(() => {
													if ((searchRef.current as any).value === "") {
														updateSearchStore({ data: undefined });
													}
												}, 50)
											}
											onChange={(e) => {
												setVal(e.target.value);
												val$.next(e.target.value);
												if (e.target.value === "") {
													updateSearchStore({ data: undefined });
												}
											}}
											ref={searchRef}
											className="p-1 pl-2 border-2 w-full border-neutral-300 rounded-sm hover:ring-2 ring-npp-blue-500 focus:outline-none sm:w-[344px] placeholder:pl-1 focus:ring"
											id="q"
											placeholder={t("search") + "..."}
											type="input"
											name="q"
											value={val}
										/>

										{val && (
											<div className="absolute inset-y-0 right-0 flex items-center mr-1">
												<button
													onClick={() => {
														setVal("");
														updateSearchStore({ data: undefined });
													}}
													type="button"
													className="inline-flex"
												>
													{searchStore.loading ? (
														<img className="h-6 w-8" alt="loading" src={gif}></img>
													) : (
														<CloseOutlined className="mr-1" rev={undefined} style={{ color: "#4d4f50", fontSize: "16px" }} />
													)}
												</button>
											</div>
										)}
									</form>
								</Tooltip>
							)}

							{!mobileSearch && (
								<>
									<Button1Component
										icon={LayersSVG({ className: "mr-2 -ml-1 fill-white" })}
										text={t("layers")}
										onClick={() => setMenu(!menu)}
										class={
											"px-4 py-1 bg-npp-blue-500 rounded-7 shadow-npp-blue-sm flex justify-evenly items-center hover:bg-npp-blue-600 active:border-none active:bg-npp-blue-500 active:shadow-npp-blue-lg"
										}
									></Button1Component>

									<button onClick={() => updateSettingStore({ open: true })} title="Kartes uzstādījumi" className="flex items-center grayscale">
										<span className="material-icons text-[#c1c2c2] rotate-[30deg] !text-xl">settings</span>
									</button>
								</>
							)}
						</div>
						<button
							className={`hidden sm:flex absolute bg-white/90 h-[42px] right-[-1.7rem] w-[27px] rounded-r-md items-center justify-center`}
							onClick={() => {
								setExpended(!expended);
								const minimized = expended ? { min: true } : {};
								searchParams.delete("min");
								setSearchParams({ ...(Object.fromEntries([...(searchParams as any)]) as any), ...minimized }, { replace: true });
							}}
						>
							<span className={`material-icons !text-base text-neutral-400 antialiased ${expended ? "rotate-180" : ""}`}>keyboard_double_arrow_right</span>
						</button>
					</div>
				</div>
				<div style={{ maxHeight: getMaxHeight() }} className="flex flex-col min-h-0 bg-white/95">
					{expended && !toggle && (
						<div
							style={{ maxWidth: getMaxWidth() }}
							className={`flex flex-col min-h-0 max-h-[25%] pointer-events-auto ${searchStore.data === undefined ? "hidden" : ""}`}
						>
							<SearchResult
								onClear={() => {
									if (searchRef.current) searchRef.current.value = "";
								}}
							></SearchResult>
						</div>
					)}
					{expended && !toggle && menu && (
						<div style={{ maxWidth: getMaxWidth() }} id="layer-switcher-wrapper" className={`flex flex-col min-h-0 flex-auto h-1/2 ${expended && menu ? "" : "hidden"}`}>
							<LayerSwitcherWrapper></LayerSwitcherWrapper>
						</div>
					)}
				</div>
			</div>
			{settings.showLayerSets && (
				<div hidden={overview || !expended}>
					<LayersetWrapper></LayersetWrapper>
				</div>
			)}
		</>
	);
}

export default SearchBar;
