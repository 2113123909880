import { ROAD_LAYER, POLY_LAYER, PGTILES_1, PGTILES_2, LAU_TILES } from "./definitions/uvis-definition";
import { CAMERA_LAYER } from "./definitions/camera-definition";
import { BASE_MAP } from "./definitions/base-map-definition";
import { createContext, useEffect, useState } from "react";
import { AttributionControl, LayerSpecification, Map, SpriteSpecification } from "maplibre-gl";
import {
	ACCIDENT_ROAD_LINE,
	ACCIDENT_ROAD_POINT,
	CLOSED_ROAD_LINE,
	CLOSED_ROAD_LINE_ALT,
	CLOSED_ROAD_POINT,
	CLOSED_ROAD_POINT_ALT,
	OTHER_ROAD_LINE,
	OTHER_ROAD_LINE_ALT,
	OTHER_ROAD_POINT,
	OTHER_ROAD_POINT_ALT,
} from "./definitions/event-definition";

import EmbedMapHover from "../embed-map-click/embed-map-hover";
import EmbedMapClick from "../embed-map-click/embed-map-click";

import "maplibre-gl/dist/maplibre-gl.css";
import { EVENT_DETOUR_ARROWS, EVENT_DETOUR_LINE } from "./definitions/event-detour";
import { LAU_POINT } from "./definitions/lau-definition";
import { EmbedWebsocket } from "./embed-websocket";

interface EmbedMapContextInterface {
	map?: Map;
	layers: LayerSpecification[];
}

export const EmbedMapContext = createContext<EmbedMapContextInterface>({ layers: [] });

export default function EmbedMapProvider() {
	const [map, setMap] = useState<Map>();
	const [layers, setLayers] = useState<LayerSpecification[]>([]);

	useEffect(() => {
		const sprite: SpriteSpecification = [
			{ id: "osm", url: BASE_MAP.sprite as string },
			{ id: "default", url: window.location.origin + "/embed-sprites/sprite" },
		];

		const layers = [
			{
				id: "osm-layer",
				type: "raster",
				source: "osm",
				minzoom: 0,
				maxzoom: 19,
			} as LayerSpecification,
			ROAD_LAYER,
			EVENT_DETOUR_LINE,
			EVENT_DETOUR_ARROWS,
			OTHER_ROAD_LINE,
			OTHER_ROAD_LINE_ALT,
			POLY_LAYER,
			CLOSED_ROAD_LINE,
			ACCIDENT_ROAD_LINE,
			CLOSED_ROAD_LINE_ALT,
			CAMERA_LAYER,
			LAU_POINT,
			CLOSED_ROAD_POINT,
			ACCIDENT_ROAD_POINT,
			OTHER_ROAD_POINT,
			OTHER_ROAD_POINT_ALT,
			CLOSED_ROAD_POINT_ALT,
		];

		const style = {
			version: 8,
			sources: {
				pgtiles: PGTILES_1,
				pgtiles_2: PGTILES_2,
				lau: LAU_TILES,
				osm: {
					type: "raster",
					tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
					tileSize: 256,
				},
			},
			layers,
			sprite,
		};
		const mapInstance = new Map({
			style: style as any,
			container: "map",
			center: [24.1056, 56.9677],
			zoom: 8,
			maxZoom: 19,
			antialias: false,
			hash: true,
			fadeDuration: 0,
			attributionControl: false, // Disable default attribution control
		}).addControl(
			new AttributionControl({
				compact: true, // This will make the attribution collapsed by default
				customAttribution: [
					"<a href='https://maplibre.org/'>MapLibre</a>",
					"© <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap contributors</a>",
					"© Latvijas Valsts Ceļi",
				],
			}),
			"bottom-right"
		);

		setMap(mapInstance);
		setLayers(layers);

		return () => {
			mapInstance.remove();
		};
	}, []);

	return (
		<EmbedMapContext.Provider value={{ map, layers }}>
			<div id="map" className="!absolute !inset-0 z-10"></div>
			<EmbedMapClick />
			<EmbedMapHover />
			<EmbedWebsocket />
		</EmbedMapContext.Provider>
	);
}
