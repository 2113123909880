import { useContext, useEffect, useState } from "react";
import { StyleLayerItem, StyleLayerProp, updateStyleItems } from "../Style-layer.repository";
import Checkbox1Component from "../../shared/UI kit/checkbox-1";
import { LayerSwitcherItem } from "./Layer-switcher-item";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../../map/Map.repository";
import DataLayersSource from "../Data-layers-source";
import { GlobalSettingsContext } from "../../Settings";

export const LayerSwitcherExpander = ({
	group,
	onGroupChange,
	forceExpand,
}: {
	group: StyleLayerProp;
	onGroupChange: (group: StyleLayerProp) => void;
	forceExpand: boolean;
}) => {
	const [expand, setExpand] = useState(forceExpand);
	const [checked, setChecked] = useState(group.visible);
	const [mapStore] = useObservable(getMapStore());
	const [zoom, setZoom] = useState(mapStore.map?.getView().getZoom() ?? 0);
	const { system_code } = useContext(GlobalSettingsContext);

	useEffect(() => {
		const map = mapStore.map!;

		const keyEvent = map.on("moveend", () => {
			setZoom(map.getView().getZoom()!);
		});

		return () => map.un("moveend", keyEvent.listener);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<li className="px-1 py-2.5 last:pb-0 break-words">
			<div className="items-center font-bold flex gap-2">
				<button onClick={() => setExpand(!expand)} className="inline-flex">
					<span style={{ fontSize: "20px" }} className="material-icons text-[#dbdcdc]">
						{expand || forceExpand ? "remove_circle_outline" : "add_circle_outline"}
					</span>
				</button>
				<Checkbox1Component
					label={
						<p style={{ fontWeight: 1000 }} className="text-black/75 leading-tight">
							{group.name}
						</p>
					}
					id={group.id.toString()}
					checked={checked}
					onChange={() => {
						const toggleAll = !group.layers.find((l) => l.visible);
						const newGroup = {
							...group,
							visible: !checked,
							layers: !checked
								? group.layers.map((l) => ({ ...l, visible: system_code === "NPP" || toggleAll ? true : l.visible }))
								: system_code === "NPP"
								? group.layers.map((l) => ({ ...l, visible: !checked }))
								: group.layers,
						};
						setChecked(!checked);
						updateStyleItems([newGroup]);
						onGroupChange(newGroup);
					}}
					name=""
				></Checkbox1Component>
				{(expand || forceExpand) && <DataLayersSource group={group}></DataLayersSource>}
			</div>
			<ul hidden={!(expand || forceExpand)}>
				{group.layers
					.reduce((arr, b) => {
						if (!arr.map((a) => a.name).includes(b.name)) {
							const c = { ...b, records: !!group.layers.filter((l) => l.name === b.name).find((l) => l.records) };
							arr.push(c);
						}
						return arr;
					}, [] as StyleLayerItem[])
					.map((l) => (
						<LayerSwitcherItem
							key={l.id + "-" + l.visible}
							item={l}
							zoom={zoom}
							onChange={(item: StyleLayerItem) => {
								const layers = group.layers.map((l) => {
									if (l.name === item.name)
										return {
											...l,
											visible: item.visible,
											lines: l.lines.map((line) => ({ ...line, opacity: item.lines.find((i) => i.opacity || i.opacity === 0)?.opacity ?? 1 })),
											points: l.points.map((point) => ({ ...point, opacity: item.points.find((i) => i.opacity || i.opacity === 0)?.opacity ?? 1 })),
										};
									return l;
								});

								const groupVisible = !!layers.find((l) => l.visible);
								const newGroup = {
									...group,
									layers,
									visible: groupVisible,
								};
								setChecked(groupVisible);
								updateStyleItems([newGroup]);
								onGroupChange(newGroup);
							}}
						></LayerSwitcherItem>
					))}
			</ul>
		</li>
	);
};
