import type { Feature } from "ol";
import EmbedPopupLinearRef from "../../embed/popup/embed-popup-linear-ref";
import { useEffect, useState } from "react";

export default function OverlayLinearRefWrapper({ feature }: { feature: Feature }) {
	const [expand, setExpand] = useState(false);

	useEffect(() => {
		setExpand(false);
	}, [feature]);

	if (!feature) return <></>;

	if (feature.get("linear_reference")) {
		const total = feature
			.get("linear_reference")
			.split(";")
			.map((lr: string) => lr.trim()) as string[];
		let refList = total;

		if (refList.length > 2 && !expand) {
			refList = [refList[0], refList[1]];
		}

		return (
			<>
				<div className="flex divide-x-2">
					<div className="flex flex-col gap-2 pr-2">
						{refList.map((lr, i) => {
							return <EmbedPopupLinearRef key={i} linRef={lr} />;
						})}
					</div>

					{feature.get("geographic_name") && (
						<div className="flex gap-1 pl-2 items-center text-[#4C4E4F]">
							<span className="material-icons">location_on</span>
							<p>{feature.get("geographic_name")}</p>
						</div>
					)}
				</div>

				{total.length > 2 && (
					<button className="flex gap-1 items-center text-sm font-normal focus:outline-none select-none mt-0.5" onClick={() => setExpand(!expand)}>
						<p className="underline">{!expand ? "Rādīt visu" : "Rādīt mazāk"}</p>
						<span className={`material-icons text-lg ${expand && "rotate-180"}`}>arrow_drop_down</span>
					</button>
				)}
			</>
		);
	}

	const lr = feature.get("ac_index") ?? feature.get("routeid");
	if (lr) {
		return <EmbedPopupLinearRef linRef={`${lr} `} />;
	}

	return <></>;
}
