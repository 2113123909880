import { useContext, useEffect, useState } from "react";
import { HoverManagerContext } from "./Hover-wrapper";
import { HoverOverlayList } from "./Hover-overlay-list";
import { Feature } from "ol";
import RenderFeature from "ol/render/Feature";
import { createTitle } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { getColor, getStyle } from "../overlay/Overlay";
import { FeatureLike } from "ol/Feature";
import { determineHoveredStyle } from "../Feature-highlighter";
import { getMapStore } from "../Map.repository";
import { useObservable } from "@ngneat/use-observable";
import { Geometry } from "ol/geom";
import OverlayLinearRefWrapper from "../overlay/Overlay-linear-ref-wrapper";

export const HoverOverlay = () => {
	const { context } = useContext(HoverManagerContext);
	const feature = context.feature as Feature<Geometry>;
	const [mapStore] = useObservable(getMapStore());
	const [olStyleState, setOlStyle] = useState();

	useEffect(() => {
		setOlStyle(undefined);
	}, [context.feature]);

	const getIcon = (feature: Feature<Geometry>) => {
		if (feature.get("layer").includes("kafkamessages_cms")) {
			if (feature.get("status") !== "active") {
				return (
					<div className="bg-[#c1c2c2] p-6 text-white rounded-full relative shadow-md mt-0.5">
						<div className="absolute inset-0 flex items-center justify-center font-semibold">
							<p>-</p>
						</div>
					</div>
				);
			}

			const olStyle = olStyleState ?? determineHoveredStyle(mapStore.map!, context.event!, feature);
			if (!olStyleState) {
				setOlStyle(olStyle);
			}
			const url = (olStyle?.[0]?.getImage() as any)?.iconImage_.src_ ?? "";
			if (url.includes("c_cms_temperatura")) {
				const temp = feature.get("t1");
				return (
					<div className="flex items-center justify-center h-11 w-11">
						<div className={`bg-[#4b4d4f] h-5 w-11 text-white flex text-sm justify-center font-semibold items-center rounded-md`}>
							<p>{temp ? `${temp > 0 ? "+" : ""}${Number(temp).toFixed(0)}°` : ""}</p>
						</div>
					</div>
				);
			} else {
				return <img src={url} alt="icon" className="w-11 h-11" />;
			}
		}

		if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
			const style = getStyle(feature, feature.get("internal"));

			if (
				(feature.get("layer").replace("public.", "") === "kafkamessages_uvis" || feature.get("layer").replace("public.", "") === "kafkamessages_uvismessage") &&
				(feature.getGeometry()?.getType() === "Point" || feature.getGeometry()?.getType() === "Polygon")
			) {
				return (
					<div className={`h-12 w-12 relative shrink-0 flex items-center justify-center`}>
						<div
							style={{
								background: style?.color ?? getColor(feature),
							}}
							className="h-2 w-2 rounded-full absolute"
						></div>
						<div
							style={{
								background: style?.color ?? getColor(feature),
								opacity: 0.5,
							}}
							className="h-12 w-12 rounded-full absolute"
						></div>
					</div>
				);
			}
			if (style) return <img src={style["piktogram"]} alt="feature_icon"></img>;
		}

		return <img src={feature.get("internal")["icon"]} alt="feature_icon"></img>;
	};

	const getTitle = () => {
		if (!feature) return "";

		const style = feature.get("layer").includes("kafkamessages_cms")
			? determineHoveredStyle(mapStore.map!, context.event!, feature, { returnStyleDef: true })
			: getStyle(feature, feature.get("internal"));

		if (style) {
			const title = style?.popup_title ? createTitle(feature, style.popup_title) : style ? style.layerName ?? style.name : "";
			return title;
		}
		return "";
	};

	const getProps = (feature: Feature) => {
		const props = feature.getProperties();
		return props;
	};

	const getLineIcon = (feature: Feature | FeatureLike, i: number, style: any | undefined) => {
		switch (style?.stroke_style) {
			case "contour":
				return (
					<button key={feature.get("id") + "-" + +i} className={`flex select-none rounded-full relative`}>
						{
							<div className="h-12 w-12 flex flex-col gap-1 justify-center">
								<div
									style={{
										background: style.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
								<div
									style={{
										background: style.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
							</div>
						}
					</button>
				);
			case "dot":
				return (
					<button key={feature.get("id") + "-" + +i} className={`flex select-none rounded-full`}>
						<div className="relative h-12 w-12 shrink-0">
							{
								<div className="h-12 w-12 flex items-center justify-center gap-1">
									{[1, 2, 3, 4, 5].map((i) => (
										<div
											key={i}
											style={{
												background: style.color ?? getColor(feature),
											}}
											className="h-2 w-2 rounded-full shrink-0"
										></div>
									))}
								</div>
							}
						</div>
					</button>
				);
			default:
				return (
					<button key={feature.get("id") + "-" + +i} className={`flex select-none rounded-full`}>
						<div className="relative h-12 w-12 shrink-0">
							{
								<div className="h-12 w-12 flex flex-col justify-center">
									<div
										style={{
											background: style?.color ?? getColor(feature),
										}}
										className="w-full h-1.5 rounded-full"
									></div>
								</div>
							}
						</div>
					</button>
				);
		}
	};

	return (
		<section id="hoverlay" className="p-4 rounded w-[30em] border-2 border-npp-grey-500 bg-white flex flex-col z-50 shadow-md mt-0.5">
			<div className="flex px-4 mb-4 mt-2">
				{feature?.getGeometry()?.getType() === "LineString"
					? getLineIcon(feature, 0, getStyle(feature, feature.get("internal")))
					: feature &&
					  (feature.get("internal")["icon"] || feature.get("layer").includes("kafkamessages_cms") || !feature.get("internal")?.["style"]) && (
							<div>{getIcon(feature)}</div>
					  )}
			</div>
			<div className="flex-grow mx-4">
				<div className="flex flex-col gap-2 mb-4">
					<p className="text-xl font-semibold text-[#303030]" dangerouslySetInnerHTML={{ __html: getTitle() }}></p>
					<OverlayLinearRefWrapper feature={feature} />
				</div>
				<div className="w-full h-[1px] bg-[#d9d9d9] mb-3 mt-4"></div>
				{feature && <HoverOverlayList props={getProps(feature as any)} feature={feature}></HoverOverlayList>}
			</div>
		</section>
	);
};
