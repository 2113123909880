import type { LayerSpecification } from "maplibre-gl";

export const CLOSED_ROAD_POINT: LayerSpecification = {
	id: "closed-road-point",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_public",
	type: "symbol",
	filter: ["all", ["==", ["geometry-type"], "Point"], ["==", ["get", "restrictions"], "['roadBlocked']"]],
	layout: {
		"icon-image": "pins-slegts-celjsh",
		"icon-rotation-alignment": "viewport",
		"icon-size": 1,
		"icon-offset": [0, -20],
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};

export const CLOSED_ROAD_POINT_ALT: LayerSpecification = {
	id: "closed-road-point-alt",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_plannedevent_public",
	type: "symbol",
	filter: [
		"all",
		["==", ["geometry-type"], "Point"],
		["==", ["get", "restrictions"], "['roadBlocked']"],
		["==", ["get", "datex_subclass"], "massEvent"],
		["==", ["get", "status"], "ACTIVE"],
	],
	layout: {
		"icon-image": "pins-slegts-celjsh",
		"icon-rotation-alignment": "viewport",
		"icon-size": 1,
		"icon-offset": [0, -20],
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};

export const CLOSED_ROAD_LINE_ALT: LayerSpecification = {
	id: "closed-road-line-alt",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_plannedevent_public",
	type: "line",
	filter: [
		"all",
		["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]],
		["==", ["get", "restrictions"], "['roadBlocked']"],
		["==", ["get", "datex_subclass"], "massEvent"],
		["==", ["get", "status"], "ACTIVE"],
	],
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", "#545454"],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 16, 10],
	},
};

export const CLOSED_ROAD_LINE: LayerSpecification = {
	id: "closed-road-line",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_public",
	type: "line",
	filter: ["all", ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]], ["==", ["get", "restrictions"], "['roadBlocked']"]],
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", "#545454"],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 16, 10],
	},
};

export const ACCIDENT_ROAD_LINE: LayerSpecification = {
	id: "accident-road-line",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_public",
	type: "line",
	filter: [
		"all",
		["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]],
		[
			"any",
			["==", ["get", "datex_subclass"], "accidentType"],
			["all", ["==", ["get", "datex_subclass"], "obstructionType"], ["==", ["get", "datex_enum"], "rescueAndRecoveryWork"]],
			["all", ["==", ["get", "datex_subclass"], "obstructionType"], ["==", ["get", "datex_enum"], "unprotectedAccidentArea"]],
		],
		["!=", ["get", "restrictions"], "['roadBlocked']"],
	],
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", "#545454"],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 16, 10],
	},
};

export const ACCIDENT_ROAD_POINT: LayerSpecification = {
	id: "accident-road-point",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_public",
	type: "symbol",
	filter: [
		"all",
		["==", ["geometry-type"], "Point"],
		[
			"any",
			["==", ["get", "datex_subclass"], "accidentType"],
			["all", ["==", ["get", "datex_subclass"], "obstructionType"], ["==", ["get", "datex_enum"], "rescueAndRecoveryWork"]],
			["all", ["==", ["get", "datex_subclass"], "obstructionType"], ["==", ["get", "datex_enum"], "unprotectedAccidentArea"]],
		],
		["!=", ["get", "restrictions"], "['roadBlocked']"],
	],
	layout: {
		"icon-image": "pins-avarija",
		"icon-rotation-alignment": "viewport",
		"icon-size": 1,
		"icon-offset": [0, -20],
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};

export const OTHER_ROAD_LINE: LayerSpecification = {
	id: "other-road-line",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_public",
	type: "line",
	filter: [
		"all",
		["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]],
		[
			"any",
			["==", ["get", "datex_subclass"], "nonWeatherRelatedRoadConditionType"],
			["==", ["get", "datex_subclass"], "animalPresenceType"],
			["==", ["get", "datex_subclass"], "infrastructureDamageType"],
			["==", ["get", "datex_subclass"], "vehicleObstructionType"],
			["==", ["get", "datex_subclass"], "environmentalObstructionType"],
			["all", ["==", ["get", "datex_subclass"], "obstructionType"], ["!=", ["get", "datex_enum"], "unprotectedAccidentArea"]],
		],
		["!=", ["get", "restrictions"], "['roadBlocked']"],
	],
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", "#545454"],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 16, 10],
	},
};

export const OTHER_ROAD_POINT: LayerSpecification = {
	id: "other-road-point",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_public",
	type: "symbol",
	filter: [
		"all",
		["==", ["geometry-type"], "Point"],
		[
			"any",
			["==", ["get", "datex_subclass"], "nonWeatherRelatedRoadConditionType"],
			["==", ["get", "datex_subclass"], "animalPresenceType"],
			["==", ["get", "datex_subclass"], "infrastructureDamageType"],
			["==", ["get", "datex_subclass"], "vehicleObstructionType"],
			["==", ["get", "datex_subclass"], "environmentalObstructionType"],
			["all", ["==", ["get", "datex_subclass"], "obstructionType"], ["!=", ["get", "datex_enum"], "unprotectedAccidentArea"]],
		],
		["!=", ["get", "restrictions"], "['roadBlocked']"],
	],
	layout: {
		"icon-image": "pins-izsaukuma-zime",
		"icon-rotation-alignment": "viewport",
		"icon-size": 1,
		"icon-offset": [0, -20],
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};

export const OTHER_ROAD_POINT_ALT: LayerSpecification = {
	id: "other-road-point-alt",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_plannedevent_public",
	type: "symbol",
	filter: [
		"all",
		["==", ["geometry-type"], "Point"],
		["==", ["get", "datex_subclass"], "massEvent"],
		["!=", ["get", "restrictions"], "['roadBlocked']"],
		["==", ["get", "status"], "ACTIVE"],
	],
	layout: {
		"icon-image": "pins-izsaukuma-zime",
		"icon-rotation-alignment": "viewport",
		"icon-size": 1,
		"icon-offset": [0, -20],
		"icon-allow-overlap": true,
		"icon-ignore-placement": true,
	},
};

export const OTHER_ROAD_LINE_ALT: LayerSpecification = {
	id: "other-road-line-alt",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_plannedevent_public",
	type: "line",
	filter: [
		"all",
		["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]],
		["==", ["get", "datex_subclass"], "massEvent"],
		["!=", ["get", "restrictions"], "['roadBlocked']"],
		["==", ["get", "status"], "ACTIVE"],
	],
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", "#545454"],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 16, 10],
	},
};
