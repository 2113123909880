import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./Error-page";
import UiKit from "./shared/UI kit/UIkit";
import { MapMarkerForm } from "./map-marker/Map-marker-form";
import { StyleProvider } from "@ant-design/cssinjs";
import EmbedMapWrapper from "./embed/embed-map/embed-map";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App></App>,
		errorElement: <ErrorPage></ErrorPage>,
	},
	{
		path: "/UIkit",
		element: <UiKit></UiKit>,
		errorElement: <ErrorPage></ErrorPage>,
	},
	{
		path: "/mini",
		element: <MapMarkerForm></MapMarkerForm>,
		errorElement: <ErrorPage></ErrorPage>,
	},
	{
		path: "/public",
		element: <EmbedMapWrapper></EmbedMapWrapper>,
		errorElement: <ErrorPage></ErrorPage>,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Removed strictMode for now to display fgb in dev
/* root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
) */

root.render(
	<StyleProvider hashPriority="high">
		<RouterProvider router={router} />
	</StyleProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
