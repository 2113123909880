import { createContext, useState } from "react";
import { Feature, MapBrowserEvent } from "ol";
import { Hover } from "./Hover";
import { HoverOverlay } from "./Hover-overlay";

export interface OverlayManagerData {
	feature?: Feature;
	event?: MapBrowserEvent<any>;
}

export const HoverManagerContext = createContext<{
	context: OverlayManagerData;
	setContext: React.Dispatch<React.SetStateAction<OverlayManagerData>>;
}>({} as any);

export const HoverWrapper = () => {
	const [context, setContext] = useState({} as OverlayManagerData);

	return (
		<HoverManagerContext.Provider value={{ context, setContext }}>
			<HoverOverlay></HoverOverlay>
			<Hover></Hover>
		</HoverManagerContext.Provider>
	);
};
