import type { MapGeoJSONFeature } from "maplibre-gl";
import { useContext, useEffect, useState } from "react";
import EmbedPopupHeader from "./embed-popup-header";
import EmbedPopupLinearRef from "./embed-popup-linear-ref";
import EmbedPopupDetails, { getValue } from "./embed-popup-details";
import { EmbedMapContext } from "../embed-map/embed-map";

export default function EmbedPopup({ features }: { features: MapGeoJSONFeature[] }) {
	const [expand, setExpand] = useState(false);
	const [currentFeature, setCurrentFeature] = useState(features[0]);
	const { map, layers } = useContext(EmbedMapContext);

	const isDetour = !!currentFeature.layer.id.includes("detour");

	useEffect(() => {
		setCurrentFeature(features[0]);
	}, [features]);

	useEffect(() => {
		setExpand(false);
	}, [currentFeature]);

	// Look for linked point feature for detours
	const getLinkedFeature = () => {
		if (isDetour) {
			const ids = layers.filter((l) => l.id.includes("point")).map((l) => l.id);
			const linkedFeature = map?.queryRenderedFeatures({ layers: ids }).find((f) => f.id === currentFeature.id);
			return linkedFeature ?? currentFeature;
		}
		return currentFeature;
	};

	const getTitle = () => {
		if (["road_layer", "poly_layer"].includes(currentFeature.layer.id)) {
			switch (currentFeature.properties["road_conditions"]) {
				case "satisfying":
					return "Apmierinoši apstākļi";
				case "unsatisfying":
					return "Apgrūtināti apstākļi";
				case "very_unsatisfying":
					return "Ļoti apgrūtināti apstākļi";
				case "extreme":
					return "Ekstrēmi apstākļi";
				default:
					return "Nezināmi apstākļi";
			}
		}

		switch (currentFeature.layer.id) {
			case "cms_device":
				return "CMS iekārta";
			case "lau-point":
				return "Uzturētaju tehnika";
			case "event-detour-layer-line":
				const linkedFeature = getLinkedFeature();
				return (
					<div>
						<p>Apbraukšanas maršruts</p>
						<p>{getValue(linkedFeature.properties["datex_enum"], "datex_enum", linkedFeature)}</p>
					</div>
				);
			case "other-road-line":
			case "other-road-point":
			case "other-road-point-alt":
			case "other-road-point-ends":
			case "accident-road-point":
			case "closed-road-point":
			case "accident-road-line":
			case "closed-road-line":
			case "other-road-line-alt":
			case "closed-road-line-alt":
			case "closed-road-point-alt":
				return getValue(currentFeature.properties["datex_enum"], "datex_enum", currentFeature);
			default:
				return currentFeature.layer.id;
		}
	};

	const lr = getLinkedFeature()
		.properties.linear_reference?.split(";")
		.map((lr: string) => lr.trim()) as string[];

	let lrList = lr;

	if (lrList?.length > 2 && !expand) {
		lrList = [lrList[0], lrList[1]];
	}

	return (
		<>
			<EmbedPopupHeader selected={(f) => setCurrentFeature(f)} features={features} />
			<div>
				<h3 className="text-xl font-semibold text-[#303030] mb-2">{getTitle()}</h3>
				<div className="flex divide-x-2">
					{currentFeature?.layer.type !== "fill" && lr && (
						<div className="flex flex-col gap-2 pr-2">
							{lrList.map((lr, i) => {
								return <EmbedPopupLinearRef key={i} linRef={lr} />;
							})}
						</div>
					)}

					{currentFeature.properties["geographic_name"] && (
						<div className="flex gap-1 pl-2 items-center 	">
							<span className="material-icons text-[#4C4E4F]">location_on</span>
							<p className="text-base font-normal">{currentFeature.properties["geographic_name"]}</p>
						</div>
					)}
				</div>
				{lr?.length > 2 && (
					<button className="flex gap-1 items-center text-sm font-normal focus:outline-none select-none mt-0.5" onClick={() => setExpand(!expand)}>
						<p className="underline">{!expand ? "Rādīt visu" : "Rādīt mazāk"}</p>
						<span className={`material-icons text-lg ${expand && "rotate-180"}`}>arrow_drop_down</span>
					</button>
				)}
			</div>
			<div className="w-full h-[1px] bg-[#d9d9d9] mb-3 mt-4"></div>
			<EmbedPopupDetails feature={currentFeature} />
		</>
	);
}
