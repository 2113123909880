import useWebSocket from "react-use-websocket";
import { EmbedMapContext } from "./embed-map";
import { useContext, useEffect } from "react";

export const EmbedWebsocket = () => {
	const { lastJsonMessage } = useWebSocket<{ message: string }>(`wss://map.transportdata.gov.lv/ws/ws/lau/transportunit`, {
		// Will attempt to reconnect on all close events, such as server shutting down
		shouldReconnect: () => !window?.location?.origin?.includes("localhost"),
	});
	const { map } = useContext(EmbedMapContext);

	useEffect(() => {
		if (lastJsonMessage && map) {
			const source = map.getSource("lau") as maplibregl.VectorTileSource;
			const layer = map.getLayer("lau-point");

			if (source && layer) {
				map.removeLayer("lau-point");
				map.removeSource("lau");
				map.addSource("lau", source.serialize());
				map.addLayer(layer.serialize());
			}
		}
	}, [lastJsonMessage, map]);

	return <></>;
};
