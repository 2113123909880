import { LineString, MultiLineString, Point, Polygon } from "ol/geom";
import { determineHoveredStyle } from "../Feature-highlighter";
import { Feature } from "ol";
import { getMapStore } from "../Map.repository";
import { useContext, useEffect, useState } from "react";
import { useObservable } from "@ngneat/use-observable";
import { getColor, getStyle } from "./Overlay";
import { OverlayManagerContext } from "./Overlay-wrapper";
import arrow from "../../shared/img/arrow.png";

export default function OverlayHeaderIcon({ feat }: { feat: Feature }) {
	const { context } = useContext(OverlayManagerContext);
	const [mapStore] = useObservable(getMapStore());
	const [olStyleState, setOlStyle] = useState();

	useEffect(() => {
		setOlStyle(undefined);
	}, [feat]);

	const getLineIcon = (feature: Feature, style: any | undefined) => {
		if (feature.get("layer").includes("_detour")) {
			return (
				<div className="relative h-11 w-11 shrink-0 overflow-visible">
					<div className="absolute inset-0 h-11 w-[52px] -left-1 flex items-center justify-center">
						<div className="w-full h-2 rounded-full absolute" style={{ background: `#4b4d4f` }}></div>
					</div>
					<div className="absolute inset-0 h-11 w-12 -left-0.5 -right-3 gap-1 flex items-center justify-center">
						{[1, 2, 3].map((i) => (
							<img src={arrow} alt="arrow" key={i} className="h-3 w-3 rounded-full shrink-0" />
						))}
					</div>
				</div>
			);
		}

		switch (style?.stroke_style) {
			case "contour":
				return (
					<div className="h-11 w-14 flex flex-col gap-1 justify-center absolute inset-0 -left-1.5 -z-10">
						<div
							style={{
								background: style?.color ?? getColor(feature),
							}}
							className="w-full h-1.5 rounded-full"
						></div>
						<div
							style={{
								background: style?.color ?? getColor(feature),
							}}
							className="w-full h-1.5 rounded-full"
						></div>
					</div>
				);
			case "dot":
				return (
					<div className="h-11 w-11 shrink-0 absolute inset-0 -left-1.5 -z-10">
						{
							<div className="h-11 w-14 flex items-center justify-center gap-1">
								{[1, 2, 3, 4, 5].map((i) => (
									<div
										key={i}
										style={{
											background: style?.color ?? getColor(feature),
										}}
										className="h-2 w-2 rounded-full shrink-0"
									></div>
								))}
							</div>
						}
					</div>
				);
			default:
				return (
					<div className="h-11 w-11 shrink-0 absolute inset-0 -left-1.5 -z-10 overflow-visible">
						{
							<div className="h-11 w-14 flex flex-col justify-center">
								<div
									style={{
										background: style?.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
							</div>
						}
					</div>
				);
		}
	};

	const getIcon = (feature: Feature) => {
		const props = feature.getProperties();
		const internal = props["internal"];

		const style = getStyle(feature, internal);

		if (feature.getGeometry() instanceof Polygon && feature.get("layer").includes("kafkamessages_uvismessage") && internal["use_uvis_popups"]) {
			return (
				<div
					className="h-10 w-10 rounded-full flex items-center justify-center"
					style={{
						background: style?.color + "80",
					}}
				>
					<div
						style={{
							background: style?.color,
						}}
						className="h-3 w-3 rounded-full shrink-0"
					></div>
				</div>
			);
		}

		if (props["layer"].includes("kafkamessages_cms")) {
			const olStyle = olStyleState ?? determineHoveredStyle(mapStore.map!, context.event!, feature, { overrideDetermined: !!feature.get("overrideDetermined") });
			if (!olStyleState) {
				setOlStyle(olStyle);
			}
			const url = (olStyle?.[0]?.getImage() as any)?.iconImage_.src_ ?? "";

			if (url.includes("c_cms_temperatura")) {
				const temp = feature.get("t1");
				const status = feature.get("status");

				return (
					<div className="flex items-center justify-center h-11 w-11">
						<div className={`bg-[#4b4d4f] h-5 w-11 text-white flex text-sm justify-center font-semibold items-center rounded-md`}>
							<p>{status === "active" ? (temp ? `${temp > 0 ? "+" : ""}${Number(temp).toFixed(0)}°` : "") : "-"}</p>
						</div>
					</div>
				);
			} else {
				return <img src={url} alt="icon" className="w-11 h-11" />;
			}
		}

		const url: string = internal?.["icon"] ?? Array.isArray(style) ? style?.find((s: any) => !!s.piktogram)?.piktogram : style?.piktogram;

		if (feature.getGeometry() instanceof Point) {
			if (url) {
				return <img src={url} alt="icon" className="w-11 h-11" />;
			} else {
				return (
					<div className="w-11 h-11 flex items-center justify-center">
						<div className="rounded-full w-4 h-4" style={{ background: style?.color }}></div>
					</div>
				);
			}
		}

		if (feature.getGeometry() instanceof LineString || feature.getGeometry() instanceof MultiLineString) {
			return (
				<div className="relative h-11 w-11">
					{url && !feature.get("layer").includes("_detour") && <img src={url} alt="icon" className="w-11 h-11 z-50" />}
					{getLineIcon(feature, style)}
				</div>
			);
		}
	};

	return <>{getIcon(feat)}</>;
}
