import { LayerSpecification } from "maplibre-gl";

export const EVENT_DETOUR_LINE: LayerSpecification = {
	id: "event-detour-layer-line",
	type: "line",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_detour",
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	filter: ["all", ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]]],
	paint: {
		"line-color": ["case", ["boolean", ["feature-state", "highlighted"], false], "#00ccf5", "#F7B513"],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 16, 12],
	},
};

// Add a symbol layer for arrows
export const EVENT_DETOUR_ARROWS: LayerSpecification = {
	id: "event-detour-layer-arrows",
	type: "symbol",
	source: "pgtiles",
	"source-layer": "public.kafkamessages_notplannedevent_detour",
	layout: {
		"symbol-placement": "line",
		"symbol-spacing": 20, // Adjust spacing between arrows as needed
		"icon-image": "bultinja-apvedceljsh",
		"icon-size": ["interpolate", ["linear"], ["zoom"], 10, 0.4, 16, 0.8], // Adjust arrow size
		"icon-rotate": 0, // Adjust arrow rotation if needed
		"icon-allow-overlap": true, // Allow arrows to overlap other features
	},
	filter: ["all", ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]]],
};
