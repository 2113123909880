import { Feature } from "ol";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { ENTRYPOINT } from "../Endpoints";
import RenderFeature from "ol/render/Feature";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ImageDisplay from "../hover/Image-preview";
import { CLASSIFIERS_NEW } from "../../classifiers/Classifiers";
import { getStyle } from "./Overlay";

let LABELS = {};

export const OverlayList = ({ feature, attributes }: { feature: Feature | RenderFeature; attributes: string[] }) => {
	const [labels, setLabels] = useState<{ [key: string]: string }>();
	const [t] = useTranslation();

	useEffect(() => {
		const props = feature.getProperties();
		const table = props.layer !== "features" ? props.layer.replace("public.", "") : props.internal.table;
		LABELS[table as keyof typeof LABELS]
			? setLabels(LABELS[table as keyof typeof LABELS])
			: axios
					.post(ENTRYPOINT + "/api/fields", {
						table: table.replace("_detour", ""),
					})
					.then((res) => {
						LABELS = { ...LABELS, [table]: res.data };
						setLabels(res.data);
					});
	}, [feature]);
	const props = feature.getProperties();

	const getMiniTitle = () => {
		const table = props.layer !== "features" ? props.layer.replace("public.", "") : props.internal.table;
		if (table === "kafkamessages_sup") {
			const style = feature.get("internal")?.["style"] || getStyle(feature, feature.get("internal"));
			if (!style) return <></>;
			return style.piktogram.includes("c_bars_") ? (
				<p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("sup20")}</p>
			) : (
				<p className="font-base mb-1 text-npp-grey-500 font-semibold">{t("sup60")}</p>
			);
		}

		return <></>;
	};

	const getMiniDesc = () => {
		const table = props.layer !== "features" ? props.layer.replace("public.", "") : props.internal.table;
		if (table === "lau_transportunit") {
			switch (feature.get("vehicle_type")) {
				case "Lorry":
					return <p>{t("lauWarningLorry")}.</p>;
				case "Grader":
				case "Tractor":
					return <p>{t("lauWarningGrader")}.</p>;
				case "Car":
					return <p>{t("lauWarningCar")}.</p>;
			}
		}

		return <></>;
	};

	const altValues = (key: string, groupName: string, layer: string) => {
		switch (key) {
			case "datex_enum":
			case "event_enum":
				if (groupName === "Braukšanas apstākļu brīdinājumi" || groupName === "Driving conditions warnings") {
					return t("warningType");
				}
				return null;
			case "date_from":
				if (groupName === "Braukšanas apstākļu brīdinājumi" || groupName === "Driving conditions warnings") {
					return t("warningFrom");
				}
				if (groupName === "Masas un gabarītu ierobežojumi" || groupName === "Weight and dimensions restrictions") {
					return t("timeFrom");
				}
				return null;
			case "detour":
				if (layer.includes("detour")) {
					return t("detourNotes");
				}
				return null;
			default:
				return null;
		}
	};

	return (
		<>
			<ul className="max-h-96 !overflow-y-auto scroll-bar sm:pr-4 mr-[1px] leading-relaxed break-words space-y-0.5">
				{getMiniTitle()}
				{attributes
					?.filter((attr) => props[attr] !== undefined || props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup"))
					.sort((keyA, keyB) => (attributes.indexOf(keyA) > attributes.indexOf(keyB) ? 1 : -1))
					.map((key) => {
						const value = props[key];
						let array: string[] = [];

						const returnValue = () => {
							const table = feature.get("layer").replace("public.", "");

							//format arrays
							if (array.length > 0) {
								return array.map((item) => CLASSIFIERS_NEW[table]?.[key]?.[item] ?? item).join(",");
							}

							//format classifiers
							const classifierValue = CLASSIFIERS_NEW[table]?.[key]?.[value];
							if (classifierValue) {
								if (key === "vehicle_type") {
									return classifierValue + ".";
								}
								return classifierValue;
							}

							//format empty 'arrays'
							if (value === "[]") {
								return "-";
							}

							//format booleans and 'booleans'
							if (value === true || value === false || value?.toString().toLowerCase() === "false" || value?.toString().toLowerCase() === "true") {
								return t(value.toString().toLowerCase());
							}

							//format dates
							if (moment(value, true).isValid() && value?.length > 10) {
								return moment(value).format("YYYY-MM-DD HH:mm");
							}

							//exception for missing value
							if (!value && (props.layer.includes("kafkamessages_cms") || props.layer.includes("kafkamessages_sup"))) {
								return t("temporarilyUnavailable");
							}

							return value;
						};

						const style = getStyle(feature, feature.get("internal"));

						if (
							value === null ||
							value === "" ||
							(Array.isArray(value) && value.length === 0) ||
							value === "[]" ||
							value === "[None]" ||
							value === "none" ||
							(key === "srti" && value === false)
						) {
							return <Fragment key={key}></Fragment>;
						}

						try {
							const val = JSON.parse(value.replaceAll("'", '"'));
							if (Array.isArray(val)) array = val;
						} catch (err) {}

						return key === "file_field" ? (
							<ImageDisplay key={key} hexData={props[key]}></ImageDisplay>
						) : (
							<li key={key} className="break-keep">
								<span style={{ whiteSpace: "nowrap" }} className="font-semibold mr-2 break-keep" title={key === "srti" ? t("srtiTooltip") + "" : ""}>
									{altValues(key, style?.groupName ?? "", props?.layer.replace("public.", "")) ?? (labels ? labels[key] : key)}:
								</span>
								{returnValue()}
							</li>
						);
					})}

				{getMiniDesc()}
			</ul>
		</>
	);
};
