import type { MapGeoJSONFeature } from "maplibre-gl";
import { useEffect, useState } from "react";
import PopupArrowSVG from "./popup-arrow-svg";
import EmbedPopupHeaderIcon from "./embed-popup-header-icon";

export default function EmbedPopupHeader({ features, selected }: { features: MapGeoJSONFeature[]; selected: (feature: MapGeoJSONFeature) => void }) {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		setCurrentIndex(0);
	}, [features]);

	const scrollBy = (value: number) => {
		document.getElementById("embed-popup-scroller")?.scrollBy({ left: value, behavior: "smooth" });
	};

	return (
		<div className="flex items-center mb-2 gap-1">
			{features.length > 5 && (
				<button onClick={() => scrollBy(-192)} className="px-1.5 h-16 bg-[#ECECEC] rounded-md flex items-center">
					<PopupArrowSVG />
				</button>
			)}
			<ul id="embed-popup-scroller" className="flex gap-4 p-1 overflow-x-hidden max-w-[308px]">
				{features.map((f, i) => (
					<li
						onClick={() => {
							setCurrentIndex(i);
							selected(f);
						}}
						key={i}
						className={`p-[4px] cursor-pointer shrink-0 rounded-md flex ${currentIndex === i && "ring-npp-blue-500 ring-2"}`}
					>
						<EmbedPopupHeaderIcon feature={f} />
					</li>
				))}
			</ul>
			{features.length > 5 && (
				<button onClick={() => scrollBy(192)} className="px-1.5 h-16 bg-[#ECECEC] rounded-md flex items-center rotate-180">
					<PopupArrowSVG />
				</button>
			)}
		</div>
	);
}
