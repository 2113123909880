export const CLASSIFIERS = {
	kafkamessages_notplannedevent_public: {
		likelihood: {
			certain: "noteikta",
			probable: "iespējama",
			riskOf: "mazāk iespējama",
		},
		restrictions: {
			roadBlocked: "Ceļš bloķēts",
			roadPartiallyObstructed: "Ceļš daļēji bloķēts",
			carriagewayBlocked: "Brauktuve bloķēta",
			carriagewayPartiallyObstructed: "Brauktuve daļēji bloķēta",
			lanesPartiallyObstructed: "Joslas daļēji bloķētas",
			lanesBlocked: "Joslas bloķētas",
		},
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		significance: {
			high: "Augsta",
			highest: "Augstākā",
			none: "Nekāda",
			unknown: "Nezināma",
			medium: "Vidēja",
			low: "Zema",
			lowest: "Zemākā",
		},
		datex_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		event_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		datex_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		event_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		animal_alive_status: {
			Dzīvs: "Dzīvs",
			Miris: "Miris",
		},
	},
	kafkamessages_plannedevent_public: {
		restrictions: {
			roadBlocked: "Ceļš bloķēts",
			roadPartiallyObstructed: "Ceļš daļēji bloķēts",
			carriagewayBlocked: "Brauktuve bloķēta",
			carriagewayPartiallyObstructed: "Brauktuve daļēji bloķēta",
			lanesPartiallyObstructed: "Joslas daļēji bloķētas",
			lanesBlocked: "Joslas bloķētas",
		},
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		datex_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		event_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		datex_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		event_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		restriction_type: {
			permanent: "Pastāvīgs",
			temporary: "Īstermiņa",
		},
	},
	kafkamessages_sorailroadcrossing: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
	},
	kafkamessages_sobordercrossing: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
	},
	kafkamessages_solorriesparking: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
		road_side: {
			right: "Labā",
			left: "Kreisā",
		},
		pavement_type: {
			asphalt: "Asfalts",
			pavement: "Bruģis",
			gravel: "Grants",
		},
		amenities: {
			"EquipmentTypeEnum->freshWater": "Ūdens uzpildes vieta",
			"ParkingSecurityEnum->fences": "Žogs",
			"EquipmentTypeEnum->lighting": "Apgaismojums",
			"ServiceFacilityTypeEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->other": "Cits",
			"ParkingUsageScenarioEnum->guidanceToAvailableSpaces": "Stāvlaukumā ir vizuālas norādes uz brīvajām stāvvietām",
			"ParkingUsageScenarioEnum->eventParking": "Stāvvieta pasākumu apmeklētājiem",
			"ParkingUsageScenarioEnum->delivery": "Stāvvieta piegādātājiem",
			"ParkingUsageScenarioEnum->dropOff": "The parking site is designed for drop off only",
			"ParkingUsageScenarioEnum->vehicleLift": "Vehicle lift",
			"ParkingUsageScenarioEnum->kissAndRide": "Stāvvieta ar iespēju ātri paņemt vai izlaist pasažierus sabiedriskajam transportam",
			"EquipmentTypeEnum->refuseBin": "Atkritumu urnas",
			"ServiceFacilityTypeEnum->leisureActivities": "Atpūtas aktivitātes",
			"EquipmentTypeEnum->informatonStele": "Informācijas stends",
			"EquipmentTypeEnum->internetTerminal": "Interneta terminālis",
			"ServiceFacilityTypeEnum->cafe": "Kafejnīca",
			"EquipmentTypeEnum->dumpingStation": "Kanālizācijas iztukšošanas vieta",
			"EquipmentTypeEnum->payDesk": "Kase",
			"ServiceFacilityTypeEnum->kiosk": "Kiosks",
			"ParkingUsageScenarioEnum->carSharing": "Koplietošanas automašīnu stāvvieta",
			"EquipmentTypeEnum->copyMachineOrService": "Kopētājs vai kopēšanas pakalpojumi",
			"ServiceFacilityTypeEnum->truckWash": "Kravas automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->truckRepair": "Kravas automašīnu remonts",
			"EquipmentTypeEnum->lanesForWeighingLoad": "Kravu svēršanas joslas",
			"EquipmentTypeEnum->elevator": "Lifts",
			"ParkingUsageScenarioEnum->dropOffMechanical": "A mechanical drop off service",
			"ParkingUsageScenarioEnum->dropOffWithValet": "A valet drop off service",
			"ParkingSecurityEnum->lighting": "Apgaismojums",
			"EquipmentTypeEnum->iceFreeScaffold": "Aprīkojums ledus noņemšanai no kravas automašīnu jumtiem",
			"ParkingSecurityEnum->guard24hours": "Apsardze 24 stundas diennaktī",
			"ParkingSecurityEnum->securityStaff": "Apsardzes darbinieki",
			"ServiceFacilityTypeEnum->pharmacy": "Aptieka",
			"ParkingSecurityEnum->externalSecurity": "Ar stāvlaukumu nesaistīta apsardze",
			"EquipmentTypeEnum->wasteDisposal": "Atkritumu konteineri",
			"ParkingUsageScenarioEnum->restArea": "Atpūtas vieta",
			"ServiceFacilityTypeEnum->carWash": "Automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->vehicleMaintenance": "Automašīnu remonts",
			"EquipmentTypeEnum->luggageLocker": "Bagāžas skapītis",
			"EquipmentTypeEnum->cashMachine": "Bankomāts",
			"EquipmentTypeEnum->internetWireless": "Bezvadu internets",
			"EquipmentTypeEnum->other": "Cits",
			"ServiceFacilityTypeEnum->other": "Cits",
			"ParkingUsageScenarioEnum->staffGuidesToSpace": "Darbinieki palīdz atrast stāvvietu",
			"EquipmentTypeEnum->defibrillator": "Defibrilators",
			"ServiceFacilityTypeEnum->petrolStation": "Degvielas uzpildes stacija",
			"ServiceFacilityTypeEnum->docstop": "Docstop",
			"EquipmentTypeEnum->shower": "Duša",
			"EquipmentTypeEnum->electricChargingStation": "Elektroautomobiļu uzlādes stacija",
			"EquipmentTypeEnum->faxMachineOrService": "Fakss vai faksa pakalpojumi",
			"EquipmentTypeEnum->informationPoint": "Informācijas punkts",
			"ParkingSecurityEnum->areaSeperatedFromSurroundings": "Stāvlaukums atdalīts no apkārtējās teritorijas",
			"ParkingUsageScenarioEnum->nearbyMotorwayParking": "Stāvlaukums atrodas netālu no šosejas",
			"ParkingUsageScenarioEnum->motorwayParking": "Stāvlaukums atrodas tieši pie šosejas",
			"EquipmentTypeEnum->fireHose": "Ugunsdzēsības šļūtene",
			"EquipmentTypeEnum->fireExtingiusher": "Ugunsdzēšamais aparāts",
			"ServiceFacilityTypeEnum->bikeSharing": "Velosipēdu koplietošana",
			"EquipmentTypeEnum->bikeParking": "Velosipēdu novietne",
			"ServiceFacilityTypeEnum->laundry": "Veļas mazgātava",
			"ParkingSecurityEnum->cctv": "Videonovērošana",
			"ServiceFacilityTypeEnum->hotel": "Viesnīca",
			"EquipmentTypeEnum->paymentMachine": "Maksājumu automāts",
			"ServiceFacilityTypeEnum->motorwayRestaurantSmall": "Mazs restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->medicalFacility": "Medicīnas iestāde",
			"ServiceFacilityTypeEnum->motel": "Motelis",
			"ServiceFacilityTypeEnum->overnightAccommodation": "Naktsmājas",
			"EquipmentTypeEnum->none": "Nav",
			"ParkingSecurityEnum->none": "Nav",
			"ServiceFacilityTypeEnum->unknown": "Nezināms",
			"ParkingSecurityEnum->unknown": "Nezināms",
			"EquipmentTypeEnum->unknown": "Nezināms",
			"ParkingUsageScenarioEnum->parkAndDrive": "Parking for users that share their cars",
			"ServiceFacilityTypeEnum->restaurantSelfService": "Pašapkalpošanās restorāns",
			"EquipmentTypeEnum->picnicFacilities": "Piknika vietas",
			"ParkingUsageScenarioEnum->automatedParkingGarage": "Pilnībā automatizēta autostāvvieta",
			"EquipmentTypeEnum->firstAidEquipment": "Pirmās palīdzības aprīkojums",
			"ServiceFacilityTypeEnum->police": "Policijas iecirknis",
			"ParkingSecurityEnum->floodLight": "Prožektori",
			"ServiceFacilityTypeEnum->foodShopping": "Pārtikas veikals",
			"ServiceFacilityTypeEnum->restaurant": "Restorāns",
			"ServiceFacilityTypeEnum->motorwayRestaurant": "Restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->sparePartsShopping": "Rezerves daļu veikals",
			"ServiceFacilityTypeEnum->tyreRepair": "Riepu remonta serviss",
			"EquipmentTypeEnum->playground": "Rotaļu laukums",
			"EquipmentTypeEnum->safeDeposit": "Seifs",
			"ParkingUsageScenarioEnum->zone": "Stāvlaukuma zona",
			"ParkingUsageScenarioEnum->parkAndWalk": "Stāvlaukums cilvēkiem, kas tuprina ceļu kājām",
			"ParkingUsageScenarioEnum->parkAndCycle": "Stāvlaukums cilvēkiem, kas turpina ceļu ar velosipēdu",
			"ParkingUsageScenarioEnum->specialLocation": "Stāvlaukums ir saistīts ar īpašu vietu",
			"ParkingUsageScenarioEnum->overnightParking": "Stāvlaukums paredzēts nakšņošanai",
			"ParkingUsageScenarioEnum->poiParking": "Stāvlaukums saistīts ar apskates objektu",
			"ParkingUsageScenarioEnum->serviceArea": "Stāvlaukums saistīts ar servisa vietu",
			"ParkingUsageScenarioEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->dog": "Suns",
			"EquipmentTypeEnum->publicPhone": "Telefons",
			"EquipmentTypeEnum->publicCardPhone": "Telefons, kas lietojams ar karti",
			"EquipmentTypeEnum->publicCoinPhone": "Telefons, kas lietojams ar monētām",
			"ServiceFacilityTypeEnum->shop": "Tirdzniecība",
			"EquipmentTypeEnum->vendingMachine": "Tirdzniecības automāts",
			"EquipmentTypeEnum->toilet": "Tualete",
			"ServiceFacilityTypeEnum->touristInformation": "Tūrisma informācijas centrs",
			"EquipmentTypeEnum->fireHydrant": "Ugunsdzēsības hidrants",
			"ParkingUsageScenarioEnum->parkAndRide": "Stāvlaukums cilvēkiem, kas turpina ceļu ar sabiedrisko transportu",
			"ServiceFacilityTypeEnum->catering": "Ēdināšana",
			"ParkingUsageScenarioEnum->liftshare": "Liftshare",
			"EquipmentTypeEnum->tollTerminal": "Maksājumu automāts par ceļa lietošanu",
		},
		services: {
			"EquipmentTypeEnum->freshWater": "Ūdens uzpildes vieta",
			"ParkingSecurityEnum->fences": "Žogs",
			"EquipmentTypeEnum->lighting": "Apgaismojums",
			"ServiceFacilityTypeEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->other": "Cits",
			"ParkingUsageScenarioEnum->guidanceToAvailableSpaces": "Stāvlaukumā ir vizuālas norādes uz brīvajām stāvvietām",
			"ParkingUsageScenarioEnum->eventParking": "Stāvvieta pasākumu apmeklētājiem",
			"ParkingUsageScenarioEnum->delivery": "Stāvvieta piegādātājiem",
			"ParkingUsageScenarioEnum->dropOff": "The parking site is designed for drop off only",
			"ParkingUsageScenarioEnum->vehicleLift": "Vehicle lift",
			"ParkingUsageScenarioEnum->kissAndRide": "Stāvvieta ar iespēju ātri paņemt vai izlaist pasažierus sabiedriskajam transportam",
			"EquipmentTypeEnum->refuseBin": "Atkritumu urnas",
			"ServiceFacilityTypeEnum->leisureActivities": "Atpūtas aktivitātes",
			"EquipmentTypeEnum->informatonStele": "Informācijas stends",
			"EquipmentTypeEnum->internetTerminal": "Interneta terminālis",
			"ServiceFacilityTypeEnum->cafe": "Kafejnīca",
			"EquipmentTypeEnum->dumpingStation": "Kanālizācijas iztukšošanas vieta",
			"EquipmentTypeEnum->payDesk": "Kase",
			"ServiceFacilityTypeEnum->kiosk": "Kiosks",
			"ParkingUsageScenarioEnum->carSharing": "Koplietošanas automašīnu stāvvieta",
			"EquipmentTypeEnum->copyMachineOrService": "Kopētājs vai kopēšanas pakalpojumi",
			"ServiceFacilityTypeEnum->truckWash": "Kravas automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->truckRepair": "Kravas automašīnu remonts",
			"EquipmentTypeEnum->lanesForWeighingLoad": "Kravu svēršanas joslas",
			"EquipmentTypeEnum->elevator": "Lifts",
			"ParkingUsageScenarioEnum->dropOffMechanical": "A mechanical drop off service",
			"ParkingUsageScenarioEnum->dropOffWithValet": "A valet drop off service",
			"ParkingSecurityEnum->lighting": "Apgaismojums",
			"EquipmentTypeEnum->iceFreeScaffold": "Aprīkojums ledus noņemšanai no kravas automašīnu jumtiem",
			"ParkingSecurityEnum->guard24hours": "Apsardze 24 stundas diennaktī",
			"ParkingSecurityEnum->securityStaff": "Apsardzes darbinieki",
			"ServiceFacilityTypeEnum->pharmacy": "Aptieka",
			"ParkingSecurityEnum->externalSecurity": "Ar stāvlaukumu nesaistīta apsardze",
			"EquipmentTypeEnum->wasteDisposal": "Atkritumu konteineri",
			"ParkingUsageScenarioEnum->restArea": "Atpūtas vieta",
			"ServiceFacilityTypeEnum->carWash": "Automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->vehicleMaintenance": "Automašīnu remonts",
			"EquipmentTypeEnum->luggageLocker": "Bagāžas skapītis",
			"EquipmentTypeEnum->cashMachine": "Bankomāts",
			"EquipmentTypeEnum->internetWireless": "Bezvadu internets",
			"EquipmentTypeEnum->other": "Cits",
			"ServiceFacilityTypeEnum->other": "Cits",
			"ParkingUsageScenarioEnum->staffGuidesToSpace": "Darbinieki palīdz atrast stāvvietu",
			"EquipmentTypeEnum->defibrillator": "Defibrilators",
			"ServiceFacilityTypeEnum->petrolStation": "Degvielas uzpildes stacija",
			"ServiceFacilityTypeEnum->docstop": "Docstop",
			"EquipmentTypeEnum->shower": "Duša",
			"EquipmentTypeEnum->electricChargingStation": "Elektroautomobiļu uzlādes stacija",
			"EquipmentTypeEnum->faxMachineOrService": "Fakss vai faksa pakalpojumi",
			"EquipmentTypeEnum->informationPoint": "Informācijas punkts",
			"ParkingSecurityEnum->areaSeperatedFromSurroundings": "Stāvlaukums atdalīts no apkārtējās teritorijas",
			"ParkingUsageScenarioEnum->nearbyMotorwayParking": "Stāvlaukums atrodas netālu no šosejas",
			"ParkingUsageScenarioEnum->motorwayParking": "Stāvlaukums atrodas tieši pie šosejas",
			"EquipmentTypeEnum->fireHose": "Ugunsdzēsības šļūtene",
			"EquipmentTypeEnum->fireExtingiusher": "Ugunsdzēšamais aparāts",
			"ServiceFacilityTypeEnum->bikeSharing": "Velosipēdu koplietošana",
			"EquipmentTypeEnum->bikeParking": "Velosipēdu novietne",
			"ServiceFacilityTypeEnum->laundry": "Veļas mazgātava",
			"ParkingSecurityEnum->cctv": "Videonovērošana",
			"ServiceFacilityTypeEnum->hotel": "Viesnīca",
			"EquipmentTypeEnum->paymentMachine": "Maksājumu automāts",
			"ServiceFacilityTypeEnum->motorwayRestaurantSmall": "Mazs restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->medicalFacility": "Medicīnas iestāde",
			"ServiceFacilityTypeEnum->motel": "Motelis",
			"ServiceFacilityTypeEnum->overnightAccommodation": "Naktsmājas",
			"EquipmentTypeEnum->none": "Nav",
			"ParkingSecurityEnum->none": "Nav",
			"ServiceFacilityTypeEnum->unknown": "Nezināms",
			"ParkingSecurityEnum->unknown": "Nezināms",
			"EquipmentTypeEnum->unknown": "Nezināms",
			"ParkingUsageScenarioEnum->parkAndDrive": "Parking for users that share their cars",
			"ServiceFacilityTypeEnum->restaurantSelfService": "Pašapkalpošanās restorāns",
			"EquipmentTypeEnum->picnicFacilities": "Piknika vietas",
			"ParkingUsageScenarioEnum->automatedParkingGarage": "Pilnībā automatizēta autostāvvieta",
			"EquipmentTypeEnum->firstAidEquipment": "Pirmās palīdzības aprīkojums",
			"ServiceFacilityTypeEnum->police": "Policijas iecirknis",
			"ParkingSecurityEnum->floodLight": "Prožektori",
			"ServiceFacilityTypeEnum->foodShopping": "Pārtikas veikals",
			"ServiceFacilityTypeEnum->restaurant": "Restorāns",
			"ServiceFacilityTypeEnum->motorwayRestaurant": "Restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->sparePartsShopping": "Rezerves daļu veikals",
			"ServiceFacilityTypeEnum->tyreRepair": "Riepu remonta serviss",
			"EquipmentTypeEnum->playground": "Rotaļu laukums",
			"EquipmentTypeEnum->safeDeposit": "Seifs",
			"ParkingUsageScenarioEnum->zone": "Stāvlaukuma zona",
			"ParkingUsageScenarioEnum->parkAndWalk": "Stāvlaukums cilvēkiem, kas tuprina ceļu kājām",
			"ParkingUsageScenarioEnum->parkAndCycle": "Stāvlaukums cilvēkiem, kas turpina ceļu ar velosipēdu",
			"ParkingUsageScenarioEnum->specialLocation": "Stāvlaukums ir saistīts ar īpašu vietu",
			"ParkingUsageScenarioEnum->overnightParking": "Stāvlaukums paredzēts nakšņošanai",
			"ParkingUsageScenarioEnum->poiParking": "Stāvlaukums saistīts ar apskates objektu",
			"ParkingUsageScenarioEnum->serviceArea": "Stāvlaukums saistīts ar servisa vietu",
			"ParkingUsageScenarioEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->dog": "Suns",
			"EquipmentTypeEnum->publicPhone": "Telefons",
			"EquipmentTypeEnum->publicCardPhone": "Telefons, kas lietojams ar karti",
			"EquipmentTypeEnum->publicCoinPhone": "Telefons, kas lietojams ar monētām",
			"ServiceFacilityTypeEnum->shop": "Tirdzniecība",
			"EquipmentTypeEnum->vendingMachine": "Tirdzniecības automāts",
			"EquipmentTypeEnum->toilet": "Tualete",
			"ServiceFacilityTypeEnum->touristInformation": "Tūrisma informācijas centrs",
			"EquipmentTypeEnum->fireHydrant": "Ugunsdzēsības hidrants",
			"ParkingUsageScenarioEnum->parkAndRide": "Stāvlaukums cilvēkiem, kas turpina ceļu ar sabiedrisko transportu",
			"ServiceFacilityTypeEnum->catering": "Ēdināšana",
			"ParkingUsageScenarioEnum->liftshare": "Liftshare",
			"EquipmentTypeEnum->tollTerminal": "Maksājumu automāts par ceļa lietošanu",
		},
	},
	kafkamessages_soblackpoints: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
	},
	kafkamessages_soevchargingpoint: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
		connectorType: {
			other: "Cits",
			iec60309x2three32: "IEC 60309-2 rūpnieciskais savienotājs trīs fāzes 32 ampēri",
			domesticG: "Mājsaimniecības rozetes tips G",
			domesticH: "Mājsaimniecības rozetes tips H",
			cee3: "CEE3, 230 V, 16 A",
			cee5: "CEE5, 400 V, 16-63 A",
			chademo: "CHAdeMO, 600V, DC",
			iec62196T1COMBO: "Combo 1. tipa bāze",
			iec62196T2COMBO: "Combo 2. tipa bāze",
			iec62196T2: "EN 17186 identifikators 'C'",
			iec60309x2three64: "IEC 60309-2 rūpnieciskais savienotājs trīs fāzes 64 ampēri",
			iec60309x2single16: "IEC 60309-2 rūpnieciskais savienotājs vienfāzes 16 ampēri",
			iec62196T1: "IEC 62196 tips 1",
			iec62196T3A: "IEC 62196 tips 3A",
			iec62196T3C: "IEC 62196 tips 3C 'Scame'",
			domesticA: "Mājsaimniecības rozetes tips A",
			domesticB: "Mājsaimniecības rozetes tips B",
			domesticC: "Mājsaimniecības rozetes tips C",
			domesticD: "Mājsaimniecības rozetes tips D",
			domesticE: "Mājsaimniecības rozetes tips E",
			domesticF: "Mājsaimniecības rozetes tips F",
			domesticI: "Mājsaimniecības rozetes tips I",
			domesticJ: "Mājsaimniecības rozetes tips J",
			domesticK: "Mājsaimniecības rozetes tips K",
			domesticL: "Mājsaimniecības rozetes tips L",
			domesticM: "Mājsaimniecības rozetes tips M",
			domesticN: "Mājsaimniecības rozetes tips N",
			domesticO: "Mājsaimniecības rozetes tips O",
			domestic: "Nenoteikta tipa mājsaimniecības rozetes",
			pantographTopDown: "No borta no augšas uz leju pantogrāfs",
			teslaR: "Tesla savienotājs 'Roadster'-tipa",
			teslaConnectorEurope: "Tesla savienotājs EU",
			teslaConnectorAmerica: "Tesla savienotājs, ko izmanto Amerikā",
			pantographBottomUp: "Uz borta no apakšas uz augšu pantogrāfs",
			yazaki: "Yazaki, 400 VDC, 125 A",
			teslaS: "teslaS",
			iec60309x2three16: "IEC 60309-2 rūpnieciskais savienotājs trīs fāzes 16 ampēri",
		},
		chargingMode: {
			mode2AC1p: "2. režīms, AC 1 fāze",
			mode2AC3p: "2. režīms, AC 3 fāzes",
			mode3AC3p: "3. režīms, AC 3 fāzes",
			mode4DC: "4. režīms, DC",
			other: "Cits",
			unknown: "Nezināms",
			ccs: "Uzlāde ar kombinēto uzlādes risinājumu (CCS)",
			legacyInductive: "Vecmodīga-induktīvā",
			mode1AC1p: "1. režīms, AC 1 fāze",
			mode1AC3p: "1. režīms, AC 3 fāzes",
		},
		connectionFormat: {
			otherCable: "Cits kabelis",
			socket: "Rozete",
			cableMode2: "Savienotājs ir pievienots kabelis; EV lietotāja automobilim jābūt ar piemērotu ieplūdi modeļa 2 kabelim",
			cableMode3: "Savienotājs ir pievienots kabelis; EV lietotāja automobilim jābūt ar piemērotu ieplūdi modeļa 3 kabelim",
		},
		pricingPolicy: {
			free: "Bezmaksas",
			pricePerChargingTime: "Cena par uzlādes laiku",
			pricePerDeliveryUnit: "Cena par vienību",
			other: "Cits",
			flatRate: "Konstanta likme",
			contract: "Līgums",
		},
		paymentMethods: {
			mobileAccount: "Aplikācija",
			tollTag: "Ceļa nodevas birka",
			paymentDebitCard: "Debitkarte",
			paymentValueCard: "Maksājumu karte",
			paymentCreditCard: "Maksājumu kredītkarte",
			unknown: "Nezināma",
			prepay: "Priekšapmaksa",
			cashBillsOnly: "Skaidra nauda (banknotes)",
			cashCoinsOnly: "Skaidra nauda (monētas)",
			cashCoinsAndBills: "Skaidrā naudā ar banknotēm vai monētām",
		},
	},
	kafkamessages_soalternativefuelstation: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
		fuel_type: {
			CNG: "Saspiestā dabasgāze",
			LNG: "Sašķidrinātā dabasgāze",
			oilGas: "Naftas gāze",
			naturalGas: "Dabasgāze",
			hydrogen: "Ūdeņradis",
		},
		payment_methods: {
			mobileAccount: "Aplikācija",
			tollTag: "Ceļa nodevas birka",
			paymentDebitCard: "Debitkarte",
			paymentValueCard: "Maksājumu karte",
			paymentCreditCard: "Maksājumu kredītkarte",
			unknown: "Nezināma",
			prepay: "Priekšapmaksa",
			cashBillsOnly: "Skaidra nauda (banknotes)",
			cashCoinsOnly: "Skaidra nauda (monētas)",
			cashCoinsAndBills: "Skaidrā naudā ar banknotēm vai monētām",
		},
	},
	kafkamessages_soparking: {
		status: {
			DRAFT: "Melnraksts",
			ACTIVE: "aktīvs",
			NOT_ACTIVATED: "deaktivizēts",
			PLANNED: "plānots",
		},
		data_type: {
			"SafeParking->LorriesAndCommercialTransport": "Drošas stāvvietas kravas automobiļiem un komerciālajiem transportlīdzekļiem",
			RailroadCrossing: "Dzelzceļa pārbrauktuve",
			"EV->ChargingPointsLocationsAndTermsOfUse": "Elektrisko transportlīdzekļu uzlādes punktu atrašanās vietas un to izmantošanas nosacījumi",
			BlackPoints: "Melnie punkti",
			"Bordercrossing locations": "Robežšķēršošanas vietas",
			LocationOfParkingPlacesAndServiceAreas: "Stāvlaukumi un servisa zonas",
			AlternativeFuelStations: "Citas alternatīvās uzpildes vietas",
			FuelStations: "Degvielas uzpildes vietas",
		},
		road_side: {
			right: "Labā",
			left: "Kreisā",
		},
		pavement_type: {
			asphalt: "Asfalts",
			pavement: "Bruģis",
			gravel: "Grants",
		},
		amenities: {
			"EquipmentTypeEnum->freshWater": "Ūdens uzpildes vieta",
			"ParkingSecurityEnum->fences": "Žogs",
			"EquipmentTypeEnum->lighting": "Apgaismojums",
			"ServiceFacilityTypeEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->other": "Cits",
			"ParkingUsageScenarioEnum->guidanceToAvailableSpaces": "Stāvlaukumā ir vizuālas norādes uz brīvajām stāvvietām",
			"ParkingUsageScenarioEnum->eventParking": "Stāvvieta pasākumu apmeklētājiem",
			"ParkingUsageScenarioEnum->delivery": "Stāvvieta piegādātājiem",
			"ParkingUsageScenarioEnum->dropOff": "The parking site is designed for drop off only",
			"ParkingUsageScenarioEnum->vehicleLift": "Vehicle lift",
			"ParkingUsageScenarioEnum->kissAndRide": "Stāvvieta ar iespēju ātri paņemt vai izlaist pasažierus sabiedriskajam transportam",
			"EquipmentTypeEnum->refuseBin": "Atkritumu urnas",
			"ServiceFacilityTypeEnum->leisureActivities": "Atpūtas aktivitātes",
			"EquipmentTypeEnum->informatonStele": "Informācijas stends",
			"EquipmentTypeEnum->internetTerminal": "Interneta terminālis",
			"ServiceFacilityTypeEnum->cafe": "Kafejnīca",
			"EquipmentTypeEnum->dumpingStation": "Kanālizācijas iztukšošanas vieta",
			"EquipmentTypeEnum->payDesk": "Kase",
			"ServiceFacilityTypeEnum->kiosk": "Kiosks",
			"ParkingUsageScenarioEnum->carSharing": "Koplietošanas automašīnu stāvvieta",
			"EquipmentTypeEnum->copyMachineOrService": "Kopētājs vai kopēšanas pakalpojumi",
			"ServiceFacilityTypeEnum->truckWash": "Kravas automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->truckRepair": "Kravas automašīnu remonts",
			"EquipmentTypeEnum->lanesForWeighingLoad": "Kravu svēršanas joslas",
			"EquipmentTypeEnum->elevator": "Lifts",
			"ParkingUsageScenarioEnum->dropOffMechanical": "A mechanical drop off service",
			"ParkingUsageScenarioEnum->dropOffWithValet": "A valet drop off service",
			"ParkingSecurityEnum->lighting": "Apgaismojums",
			"EquipmentTypeEnum->iceFreeScaffold": "Aprīkojums ledus noņemšanai no kravas automašīnu jumtiem",
			"ParkingSecurityEnum->guard24hours": "Apsardze 24 stundas diennaktī",
			"ParkingSecurityEnum->securityStaff": "Apsardzes darbinieki",
			"ServiceFacilityTypeEnum->pharmacy": "Aptieka",
			"ParkingSecurityEnum->externalSecurity": "Ar stāvlaukumu nesaistīta apsardze",
			"EquipmentTypeEnum->wasteDisposal": "Atkritumu konteineri",
			"ParkingUsageScenarioEnum->restArea": "Atpūtas vieta",
			"ServiceFacilityTypeEnum->carWash": "Automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->vehicleMaintenance": "Automašīnu remonts",
			"EquipmentTypeEnum->luggageLocker": "Bagāžas skapītis",
			"EquipmentTypeEnum->cashMachine": "Bankomāts",
			"EquipmentTypeEnum->internetWireless": "Bezvadu internets",
			"EquipmentTypeEnum->other": "Cits",
			"ServiceFacilityTypeEnum->other": "Cits",
			"ParkingUsageScenarioEnum->staffGuidesToSpace": "Darbinieki palīdz atrast stāvvietu",
			"EquipmentTypeEnum->defibrillator": "Defibrilators",
			"ServiceFacilityTypeEnum->petrolStation": "Degvielas uzpildes stacija",
			"ServiceFacilityTypeEnum->docstop": "Docstop",
			"EquipmentTypeEnum->shower": "Duša",
			"EquipmentTypeEnum->electricChargingStation": "Elektroautomobiļu uzlādes stacija",
			"EquipmentTypeEnum->faxMachineOrService": "Fakss vai faksa pakalpojumi",
			"EquipmentTypeEnum->informationPoint": "Informācijas punkts",
			"ParkingSecurityEnum->areaSeperatedFromSurroundings": "Stāvlaukums atdalīts no apkārtējās teritorijas",
			"ParkingUsageScenarioEnum->nearbyMotorwayParking": "Stāvlaukums atrodas netālu no šosejas",
			"ParkingUsageScenarioEnum->motorwayParking": "Stāvlaukums atrodas tieši pie šosejas",
			"EquipmentTypeEnum->fireHose": "Ugunsdzēsības šļūtene",
			"EquipmentTypeEnum->fireExtingiusher": "Ugunsdzēšamais aparāts",
			"ServiceFacilityTypeEnum->bikeSharing": "Velosipēdu koplietošana",
			"EquipmentTypeEnum->bikeParking": "Velosipēdu novietne",
			"ServiceFacilityTypeEnum->laundry": "Veļas mazgātava",
			"ParkingSecurityEnum->cctv": "Videonovērošana",
			"ServiceFacilityTypeEnum->hotel": "Viesnīca",
			"EquipmentTypeEnum->paymentMachine": "Maksājumu automāts",
			"ServiceFacilityTypeEnum->motorwayRestaurantSmall": "Mazs restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->medicalFacility": "Medicīnas iestāde",
			"ServiceFacilityTypeEnum->motel": "Motelis",
			"ServiceFacilityTypeEnum->overnightAccommodation": "Naktsmājas",
			"EquipmentTypeEnum->none": "Nav",
			"ParkingSecurityEnum->none": "Nav",
			"ServiceFacilityTypeEnum->unknown": "Nezināms",
			"ParkingSecurityEnum->unknown": "Nezināms",
			"EquipmentTypeEnum->unknown": "Nezināms",
			"ParkingUsageScenarioEnum->parkAndDrive": "Parking for users that share their cars",
			"ServiceFacilityTypeEnum->restaurantSelfService": "Pašapkalpošanās restorāns",
			"EquipmentTypeEnum->picnicFacilities": "Piknika vietas",
			"ParkingUsageScenarioEnum->automatedParkingGarage": "Pilnībā automatizēta autostāvvieta",
			"EquipmentTypeEnum->firstAidEquipment": "Pirmās palīdzības aprīkojums",
			"ServiceFacilityTypeEnum->police": "Policijas iecirknis",
			"ParkingSecurityEnum->floodLight": "Prožektori",
			"ServiceFacilityTypeEnum->foodShopping": "Pārtikas veikals",
			"ServiceFacilityTypeEnum->restaurant": "Restorāns",
			"ServiceFacilityTypeEnum->motorwayRestaurant": "Restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->sparePartsShopping": "Rezerves daļu veikals",
			"ServiceFacilityTypeEnum->tyreRepair": "Riepu remonta serviss",
			"EquipmentTypeEnum->playground": "Rotaļu laukums",
			"EquipmentTypeEnum->safeDeposit": "Seifs",
			"ParkingUsageScenarioEnum->zone": "Stāvlaukuma zona",
			"ParkingUsageScenarioEnum->parkAndWalk": "Stāvlaukums cilvēkiem, kas tuprina ceļu kājām",
			"ParkingUsageScenarioEnum->parkAndCycle": "Stāvlaukums cilvēkiem, kas turpina ceļu ar velosipēdu",
			"ParkingUsageScenarioEnum->specialLocation": "Stāvlaukums ir saistīts ar īpašu vietu",
			"ParkingUsageScenarioEnum->overnightParking": "Stāvlaukums paredzēts nakšņošanai",
			"ParkingUsageScenarioEnum->poiParking": "Stāvlaukums saistīts ar apskates objektu",
			"ParkingUsageScenarioEnum->serviceArea": "Stāvlaukums saistīts ar servisa vietu",
			"ParkingUsageScenarioEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->dog": "Suns",
			"EquipmentTypeEnum->publicPhone": "Telefons",
			"EquipmentTypeEnum->publicCardPhone": "Telefons, kas lietojams ar karti",
			"EquipmentTypeEnum->publicCoinPhone": "Telefons, kas lietojams ar monētām",
			"ServiceFacilityTypeEnum->shop": "Tirdzniecība",
			"EquipmentTypeEnum->vendingMachine": "Tirdzniecības automāts",
			"EquipmentTypeEnum->toilet": "Tualete",
			"ServiceFacilityTypeEnum->touristInformation": "Tūrisma informācijas centrs",
			"EquipmentTypeEnum->fireHydrant": "Ugunsdzēsības hidrants",
			"ParkingUsageScenarioEnum->parkAndRide": "Stāvlaukums cilvēkiem, kas turpina ceļu ar sabiedrisko transportu",
			"ServiceFacilityTypeEnum->catering": "Ēdināšana",
			"ParkingUsageScenarioEnum->liftshare": "Liftshare",
			"EquipmentTypeEnum->tollTerminal": "Maksājumu automāts par ceļa lietošanu",
		},
		services: {
			"EquipmentTypeEnum->freshWater": "Ūdens uzpildes vieta",
			"ParkingSecurityEnum->fences": "Žogs",
			"EquipmentTypeEnum->lighting": "Apgaismojums",
			"ServiceFacilityTypeEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->other": "Cits",
			"ParkingUsageScenarioEnum->guidanceToAvailableSpaces": "Stāvlaukumā ir vizuālas norādes uz brīvajām stāvvietām",
			"ParkingUsageScenarioEnum->eventParking": "Stāvvieta pasākumu apmeklētājiem",
			"ParkingUsageScenarioEnum->delivery": "Stāvvieta piegādātājiem",
			"ParkingUsageScenarioEnum->dropOff": "The parking site is designed for drop off only",
			"ParkingUsageScenarioEnum->vehicleLift": "Vehicle lift",
			"ParkingUsageScenarioEnum->kissAndRide": "Stāvvieta ar iespēju ātri paņemt vai izlaist pasažierus sabiedriskajam transportam",
			"EquipmentTypeEnum->refuseBin": "Atkritumu urnas",
			"ServiceFacilityTypeEnum->leisureActivities": "Atpūtas aktivitātes",
			"EquipmentTypeEnum->informatonStele": "Informācijas stends",
			"EquipmentTypeEnum->internetTerminal": "Interneta terminālis",
			"ServiceFacilityTypeEnum->cafe": "Kafejnīca",
			"EquipmentTypeEnum->dumpingStation": "Kanālizācijas iztukšošanas vieta",
			"EquipmentTypeEnum->payDesk": "Kase",
			"ServiceFacilityTypeEnum->kiosk": "Kiosks",
			"ParkingUsageScenarioEnum->carSharing": "Koplietošanas automašīnu stāvvieta",
			"EquipmentTypeEnum->copyMachineOrService": "Kopētājs vai kopēšanas pakalpojumi",
			"ServiceFacilityTypeEnum->truckWash": "Kravas automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->truckRepair": "Kravas automašīnu remonts",
			"EquipmentTypeEnum->lanesForWeighingLoad": "Kravu svēršanas joslas",
			"EquipmentTypeEnum->elevator": "Lifts",
			"ParkingUsageScenarioEnum->dropOffMechanical": "A mechanical drop off service",
			"ParkingUsageScenarioEnum->dropOffWithValet": "A valet drop off service",
			"ParkingSecurityEnum->lighting": "Apgaismojums",
			"EquipmentTypeEnum->iceFreeScaffold": "Aprīkojums ledus noņemšanai no kravas automašīnu jumtiem",
			"ParkingSecurityEnum->guard24hours": "Apsardze 24 stundas diennaktī",
			"ParkingSecurityEnum->securityStaff": "Apsardzes darbinieki",
			"ServiceFacilityTypeEnum->pharmacy": "Aptieka",
			"ParkingSecurityEnum->externalSecurity": "Ar stāvlaukumu nesaistīta apsardze",
			"EquipmentTypeEnum->wasteDisposal": "Atkritumu konteineri",
			"ParkingUsageScenarioEnum->restArea": "Atpūtas vieta",
			"ServiceFacilityTypeEnum->carWash": "Automašīnu mazgāšana",
			"ServiceFacilityTypeEnum->vehicleMaintenance": "Automašīnu remonts",
			"EquipmentTypeEnum->luggageLocker": "Bagāžas skapītis",
			"EquipmentTypeEnum->cashMachine": "Bankomāts",
			"EquipmentTypeEnum->internetWireless": "Bezvadu internets",
			"EquipmentTypeEnum->other": "Cits",
			"ServiceFacilityTypeEnum->other": "Cits",
			"ParkingUsageScenarioEnum->staffGuidesToSpace": "Darbinieki palīdz atrast stāvvietu",
			"EquipmentTypeEnum->defibrillator": "Defibrilators",
			"ServiceFacilityTypeEnum->petrolStation": "Degvielas uzpildes stacija",
			"ServiceFacilityTypeEnum->docstop": "Docstop",
			"EquipmentTypeEnum->shower": "Duša",
			"EquipmentTypeEnum->electricChargingStation": "Elektroautomobiļu uzlādes stacija",
			"EquipmentTypeEnum->faxMachineOrService": "Fakss vai faksa pakalpojumi",
			"EquipmentTypeEnum->informationPoint": "Informācijas punkts",
			"ParkingSecurityEnum->areaSeperatedFromSurroundings": "Stāvlaukums atdalīts no apkārtējās teritorijas",
			"ParkingUsageScenarioEnum->nearbyMotorwayParking": "Stāvlaukums atrodas netālu no šosejas",
			"ParkingUsageScenarioEnum->motorwayParking": "Stāvlaukums atrodas tieši pie šosejas",
			"EquipmentTypeEnum->fireHose": "Ugunsdzēsības šļūtene",
			"EquipmentTypeEnum->fireExtingiusher": "Ugunsdzēšamais aparāts",
			"ServiceFacilityTypeEnum->bikeSharing": "Velosipēdu koplietošana",
			"EquipmentTypeEnum->bikeParking": "Velosipēdu novietne",
			"ServiceFacilityTypeEnum->laundry": "Veļas mazgātava",
			"ParkingSecurityEnum->cctv": "Videonovērošana",
			"ServiceFacilityTypeEnum->hotel": "Viesnīca",
			"EquipmentTypeEnum->paymentMachine": "Maksājumu automāts",
			"ServiceFacilityTypeEnum->motorwayRestaurantSmall": "Mazs restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->medicalFacility": "Medicīnas iestāde",
			"ServiceFacilityTypeEnum->motel": "Motelis",
			"ServiceFacilityTypeEnum->overnightAccommodation": "Naktsmājas",
			"EquipmentTypeEnum->none": "Nav",
			"ParkingSecurityEnum->none": "Nav",
			"ServiceFacilityTypeEnum->unknown": "Nezināms",
			"ParkingSecurityEnum->unknown": "Nezināms",
			"EquipmentTypeEnum->unknown": "Nezināms",
			"ParkingUsageScenarioEnum->parkAndDrive": "Parking for users that share their cars",
			"ServiceFacilityTypeEnum->restaurantSelfService": "Pašapkalpošanās restorāns",
			"EquipmentTypeEnum->picnicFacilities": "Piknika vietas",
			"ParkingUsageScenarioEnum->automatedParkingGarage": "Pilnībā automatizēta autostāvvieta",
			"EquipmentTypeEnum->firstAidEquipment": "Pirmās palīdzības aprīkojums",
			"ServiceFacilityTypeEnum->police": "Policijas iecirknis",
			"ParkingSecurityEnum->floodLight": "Prožektori",
			"ServiceFacilityTypeEnum->foodShopping": "Pārtikas veikals",
			"ServiceFacilityTypeEnum->restaurant": "Restorāns",
			"ServiceFacilityTypeEnum->motorwayRestaurant": "Restorāns šosejas atpūtas zonā",
			"ServiceFacilityTypeEnum->sparePartsShopping": "Rezerves daļu veikals",
			"ServiceFacilityTypeEnum->tyreRepair": "Riepu remonta serviss",
			"EquipmentTypeEnum->playground": "Rotaļu laukums",
			"EquipmentTypeEnum->safeDeposit": "Seifs",
			"ParkingUsageScenarioEnum->zone": "Stāvlaukuma zona",
			"ParkingUsageScenarioEnum->parkAndWalk": "Stāvlaukums cilvēkiem, kas tuprina ceļu kājām",
			"ParkingUsageScenarioEnum->parkAndCycle": "Stāvlaukums cilvēkiem, kas turpina ceļu ar velosipēdu",
			"ParkingUsageScenarioEnum->specialLocation": "Stāvlaukums ir saistīts ar īpašu vietu",
			"ParkingUsageScenarioEnum->overnightParking": "Stāvlaukums paredzēts nakšņošanai",
			"ParkingUsageScenarioEnum->poiParking": "Stāvlaukums saistīts ar apskates objektu",
			"ParkingUsageScenarioEnum->serviceArea": "Stāvlaukums saistīts ar servisa vietu",
			"ParkingUsageScenarioEnum->truckParking": "Stāvvietas kravas transportam",
			"ParkingSecurityEnum->dog": "Suns",
			"EquipmentTypeEnum->publicPhone": "Telefons",
			"EquipmentTypeEnum->publicCardPhone": "Telefons, kas lietojams ar karti",
			"EquipmentTypeEnum->publicCoinPhone": "Telefons, kas lietojams ar monētām",
			"ServiceFacilityTypeEnum->shop": "Tirdzniecība",
			"EquipmentTypeEnum->vendingMachine": "Tirdzniecības automāts",
			"EquipmentTypeEnum->toilet": "Tualete",
			"ServiceFacilityTypeEnum->touristInformation": "Tūrisma informācijas centrs",
			"EquipmentTypeEnum->fireHydrant": "Ugunsdzēsības hidrants",
			"ParkingUsageScenarioEnum->parkAndRide": "Stāvlaukums cilvēkiem, kas turpina ceļu ar sabiedrisko transportu",
			"ServiceFacilityTypeEnum->catering": "Ēdināšana",
			"ParkingUsageScenarioEnum->liftshare": "Liftshare",
			"EquipmentTypeEnum->tollTerminal": "Maksājumu automāts par ceļa lietošanu",
		},
	},
	kafkamessages_itpwarning: {
		datex_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		event_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		datex_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		event_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
	},
	kafkamessages_uvis: {
		datex_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		event_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		datex_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		event_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		rainfall: {
			clear: "Skaidrs",
			cloudy: "Apmācies",
			slightSnow: "Snieg (nedaudz)",
			heavySnow: "Snieg (stipri)",
			placesSnow: "Snieg (vietām)",
			wetSnow: "Slapjš sniegs",
			snowWithRain: "Sniegs ar lietu",
			slightRain: "Līst (nedaudz)",
			heavyRain: "Līst (stipri)",
			placesRain: "Līst (vietām)",
			freezingRain: "Sasalstošs lietus",
			hail: "Krusa",
		},
		meteo: {
			fog: "Migla",
			blizzard: "Putenis",
			lowBlizzard: "Zemais putenis",
			thaw: "Atkala",
			frost: "Sarma",
			strongWind: "Stiprs vējš",
			storm: "Vētra",
		},
		road_conditions_extra_status: {
			iced: "Apledojis",
			slipperyInPlaces: "Vietām slidens",
			blackIce: "Melnais ledus",
			dry: "Sauss",
			humid: "Mitrs",
			wet: "Slapjš",
			wetInPlaces: "Vietām slapjš",
			hoarfrost: "Apsarmojis",
			snowed: "Sniegots",
			snowyInPlaces: "Vietām sniegots",
			snowDusting: "Aizputinājumi",
			snowyDusty: "Sniegots, aizputinājumi",
			snowWithSandAndSalt: "Sniegs ar smilti un sāli",
		},
		zd_operation: {
			cleaning: "Tīra",
			sprinkling: "Kaisa",
			sprinklingPreventives: "Kaisa preventīvi",
			cleaningAndSprinkling: "Tīra un kaisa",
			cleaningAndSprinklingRepeatedly: "Tīra un kaisa atkārtoti",
			cleaningAndSprinkling3rdTime: "Tīra un kaisa atkārtoti 3. reizi",
			cleaningAndSprinkling4thTime: "Tīra un kaisa atkārtoti 4. reizi",
			cleaningAndSprinkling5thTime: "Tīra un kaisa atkārtoti 5. reizi",
			sprinklingRepeatedly: "Kaisa atkārtoti",
			sprinklingRepeatedly3rdTime: "Kaisa atkārtoti 3. reizi",
			sprinklingRepeatedly4thTime: "Kaisa atkārtoti 4. reizi",
			sprinklingRepeatedly5thTime: "Kaisa atkārtoti 5. reizi",
			cleaned: "Notīrīts",
			sprinkled: "Nokaisīts",
			cleanedAndSprinkled: "Notīrīts un nokaisīts",
			grooving: "Rievo",
			grooved: "Norievots",
		},
		road_conditions: {
			satisfying: "Apmierinoši",
			unsatisfying: "Neapmierinoši",
			very_unsatisfying: "Ļoti apgrūtināti",
			"very-unsatisfying": "Ļoti apgrūtināti",
			extreme: "Ārkārtas",
		},
	},
	kafkamessages_uvismessage: {
		datex_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		event_enum: {
			roadSurfaceInPoorCondition: "Ceļa segums sliktā stāvoklī",
			roadMarkingWork: "Ceļu marķēšanas darbi",
			roadBlocked: "Ceļš bloķēts",
			slippery: "Slidens",
			laneClosures: "Slēgta josla",
			roadClosed: "Slēgts ceļš",
			roadblocked: "roadblocked",
			heightRestrictions: "Augstuma ierobežojums (metri)",
			constructionWork: "Celtniecības darbi",
			roadworks: "Ceļa darbi",
			ice: "Ledus",
			mudOnRoad: "Dubļi uz ceļa",
			animalsByTheRoad: "Dzīvnieki pie ceļa",
			other: "Cits",
			vehicleOnFire: "Degošs transportlīdzeklis",
			animalsOnTheRoad: "Dzīvnieki uz ceļa",
			oilOnRoad: "Eļļa uz ceļa",
			rescueAndRecoveryWork: "Glābšanas un negadījumu seku novēršanas darbi",
			herdOfAnimalsOnTheRoad: "Dzīvnieku bars uz ceļa",
			seriousFire: "Ievērojams ugunsgrēks",
			lanesBlocked: "Joslas bloķētas",
			unloadingPoint: "Kraušanas darbi",
			roadSurfaceMelting: "Kūstošs ceļa segums",
			slushStrings: "Kūstošs sniegs",
			largeAnimalsOnTheRoad: "Lieli dzīvnieki uz ceļa",
			heavyFrost: "Liels aukstums",
			extremeHeat: "Liels karstums",
			sleet: "Lietus ar sniegu",
			showers: "Lietus gāzes",
			massRestrictions: "Masas ierobežojums (tonnas)",
			denseFog: "Bieza migla",
			damagedBridge: "Bojāts tilts",
			deepSnow: "Dziļš sniegs",
			hazardsOnTheRoad: "Apdraudējumi uz ceļa",
			frost: "Aukstums",
			smokeHazard: "Bīstami dūmi",
			peopleOnRoadway: "Cilvēki uz brauktuves",
			blackIce: "Melnais ledus",
			fog: "Migla",
			unprotectedAccidentArea: "Neaizsargāta negadījuma vieta",
			visibilityReduced: "Samazināta redzamība",
			vehicleOnWrongCarriageway: "Transportlīdzeklis uz nepareizās joslas",
			wet: "wet",
			veryStrongGustsOfWind: "Ļoti stiprs vējš",
			looseChippings: "Šķembas",
			damagedCrashBarrier: "Bojāta trieciena barjera",
			massEvent: "Masu pasākums",
			massPotholeRepairs: "Masveida bedru remontdarbu plāns",
			abandonedVehicle: "Pamests transportlīdzeklis",
			looseSandOnRoad: "Smiltis uz ceļa",
			UVIS: "UVIS notikums",
			surfaceWater: "Uz virsmas ūdens",
			accidentInvolvingHeavyLorries: "Negadījumi, kuros iesaistīti kravas automobīļi",
			accident: "Negadījums",
			widthRestrictions: "Platuma ierobežojums (metri)",
			flooding: "Plūdi",
			thunderstorms: "Pērkona negaiss",
			fallenTrees: "Sakrituši koki",
			slushOnRoad: "Sniega putra",
			snowDrifts: "Sniega sanesumi",
			snowfall: "Sniegputenis",
			blowingSnow: "Sniegputenis",
			snowOnTheRoad: "Sniegs uz ceļa",
			strongGustsOfWind: "Stipras vēja brāzmas",
			heavyRain: "Stiprs lietus",
			heavySnowfall: "Stiprs putenis",
			extremeCold: "Stiprs sals",
			strongWinds: "Stiprs vējš",
			crosswinds: "Sānvējš",
			maintenanceWork: "Uzturēšanas darbi",
			cyclistsOnRoadway: "Velosipēdisti uz brauktuves",
			moderateFog: "Vidēji bieza migla",
			patchyFog: "Vietām migla",
			icyPatches: "Vietām slidens 1",
			virtualMassRestriction: "Virtuālais masas ierobežojums",
			snowChangingToRain: "Sniegs, kas kļūst par lietu",
			virtualMassRestrictionWithException: "Virtuālais masas ierobežojums (ar izņēmumiem)",
			stormForceWinds: "Vētra",
			accidentInvolvingPublicTransport: "Negaidījumi saistīti ar sabiedrisko transportu",
			precipitationInTheArea: "Nokrišņi",
			freezingRain: "Salstošs lietus",
			brokenDownVehicle: "Salūzis transportlīdzeklis",
			temperatureFalling: "Temperatūras samazināšanās",
			objectOnTheRoad: "Objekts uz ceļa",
			freezingFog: "Stindzinoša migla",
			shedLoad: "Transportēto preču izkrišana uz ceļa",
			landslips: "Zemes nogruvumi",
			blizzard: "blizzard",
			freezingOfWetRoads: "freezingOfWetRoads",
			hail: "hail",
			rain: "rain",
			rainChangingToSnow: "rainChangingToSnow",
			snow: "snow",
			streamingWater: "Ūdens straumes",
		},
		datex_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		event_subclass: {
			accidentTypes: "Negadījumu veidi",
			constructionWorks: "Celtniecības darbi",
			environmentalObstructionTypes: "Vides šķēršļu tipi",
			generalObstructionTypes: "Vispārīgi šķēršļu tipi",
			nonWeatherRelatedRoadConditionTypes: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			poorEnvironmentTypes: "Sliktas vides veidi",
			roadMaintenanceTypes: "Ceļa uzturēšanas darbu veidi",
			RoadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			trafficConstrictionTypes: "Satiksmes Sašaurināšanās veidi",
			vehicleObstructionTypes: "Transportlīdzekļa šķēršļu tipi",
			weatherRelatedRoadConditionTypes: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			infrastructureDamageObstructionTypes: "Infrastruktūras bojājumu šķēršlu veidi",
			constructionWork: "Celtniecības darbi",
			"NonDatex->MassEvent": "Masu pasākums",
			nonWeatherRelatedRoadConditionType: "Ar laikapstākļiem nesaistīti ceļa apstākļu veidi",
			infrastructureDamageType: "Infrastruktūras bojājumu šķēršlu veidi",
			unloadingPoint: "Kraušanas darbi",
			UVIS: "UVIS notikums",
			weatherRelatedRoadConditionType: "Ar laikapstākļiem saistīto ceļa apstākļu veidi",
			roadMaintenanceType: "Ceļa uzturēšanas darbu veidi",
			roadOrCarriagewayOrLaneManagementType: "Ceļa vai brauktuves vai joslas pārvaldības veids",
			animalPresenceType: "Dzīvnieka klātbūtnes veids",
			massAndDimensionRestrictions: "Masas un gabarītu ierobežojumi",
			accidentType: "Negadījumu veidi",
			trafficConstrictionType: "Satiksmes Sašaurināšanās veidi",
			poorEnvironmentType: "Sliktas vides veidi",
			vehicleObstructionType: "Transportlīdzekļa šķēršļu tipi",
			environmentalObstructionType: "Vides šķēršļu tipi",
			obstructionType: "Vispārīgi šķēršļu tipi",
		},
		rainfall: {
			clear: "Skaidrs",
			cloudy: "Apmācies",
			slightSnow: "Snieg (nedaudz)",
			heavySnow: "Snieg (stipri)",
			placesSnow: "Snieg (vietām)",
			wetSnow: "Slapjš sniegs",
			snowWithRain: "Sniegs ar lietu",
			slightRain: "Līst (nedaudz)",
			heavyRain: "Līst (stipri)",
			placesRain: "Līst (vietām)",
			freezingRain: "Sasalstošs lietus",
			hail: "Krusa",
		},
		meteo: {
			fog: "Migla",
			blizzard: "Putenis",
			lowBlizzard: "Zemais putenis",
			thaw: "Atkala",
			frost: "Sarma",
			strongWind: "Stiprs vējš",
			storm: "Vētra",
		},
		road_conditions_extra_status: {
			iced: "Apledojis",
			slipperyInPlaces: "Vietām slidens",
			blackIce: "Melnais ledus",
			dry: "Sauss",
			humid: "Mitrs",
			wet: "Slapjš",
			wetInPlaces: "Vietām slapjš",
			hoarfrost: "Apsarmojis",
			snowed: "Sniegots",
			snowyInPlaces: "Vietām sniegots",
			snowDusting: "Aizputinājumi",
			snowyDusty: "Sniegots, aizputinājumi",
			snowWithSandAndSalt: "Sniegs ar smilti un sāli",
		},
		zd_operation: {
			cleaning: "Tīra",
			sprinkling: "Kaisa",
			sprinklingPreventives: "Kaisa preventīvi",
			cleaningAndSprinkling: "Tīra un kaisa",
			cleaningAndSprinklingRepeatedly: "Tīra un kaisa atkārtoti",
			cleaningAndSprinkling3rdTime: "Tīra un kaisa atkārtoti 3. reizi",
			cleaningAndSprinkling4thTime: "Tīra un kaisa atkārtoti 4. reizi",
			cleaningAndSprinkling5thTime: "Tīra un kaisa atkārtoti 5. reizi",
			sprinklingRepeatedly: "Kaisa atkārtoti",
			sprinklingRepeatedly3rdTime: "Kaisa atkārtoti 3. reizi",
			sprinklingRepeatedly4thTime: "Kaisa atkārtoti 4. reizi",
			sprinklingRepeatedly5thTime: "Kaisa atkārtoti 5. reizi",
			cleaned: "Notīrīts",
			sprinkled: "Nokaisīts",
			cleanedAndSprinkled: "Notīrīts un nokaisīts",
			grooving: "Rievo",
			grooved: "Norievots",
		},
		road_conditions: {
			satisfying: "Apmierinoši",
			unsatisfying: "Apgrūtināti",
			very_unsatisfying: "Ļoti neapmierinoši",
			"very-unsatisfying": "Ļoti neapmierinoši",
			extreme: "Ārkārtas",
		},
	},
	kafkamessages_staticobject: {
		data_subtype: {
			"PricingPolicyEnum->free": "Bezmaksas",
			"ServiceFacilityTypeEnum->truckRepair": "Kravas automašīnu remonts",
			"EquipmentTypeEnum->other": "Aprīkojums: cits",
			"EquipmentTypeEnum->none": "Aprīkojums: nav",
			"EquipmentTypeEnum->unknown": "Aprīkojums: nezināms",
			"ServiceFacilityTypeEnum->pharmacy": "Aptieka",
			"EquipmentTypeEnum->refuseBin": "Atkritumu grozs",
			"EquipmentTypeEnum->wasteDisposal": "Atkritumu likvidēšana",
			"ServiceFacilityTypeEnum->motorwayRestaurant": "Automaģistrāles restorāns",
			"EquipmentTypeEnum->luggageLocker": "Bagāžas glabāšana",
			"EquipmentTypeEnum->internetWireless": "Bezvadu internets",
			"ServiceFacilityTypeEnum->leisureActivities": "Brīvā laika aktivitātes",
			"ChargingModeEnum->ccs": "CCS",
			"PricingPolicyEnum->pricePerChargingTime": "Cena atkarīga no lādēšanas ilguma",
			"PricingPolicyEnum->pricePerDeliveryUnit": "Cena par vienību",
			"ServiceFacilityTypeEnum->foodShopping": "Pārtikas veikals",
			"ServiceFacilityTypeEnum->restaurant": "Restorāns",
			"ServiceFacilityTypeEnum->sparePartsShopping": "Rezerves daļu veikals",
			"ChargingPointUsageTypeEnum->electricBike": "Lādēšanas punkta lietošanas veids: elektriskais velosipēds",
			"ChargingPointUsageTypeEnum->electricBoat": "Lādēšanas punkta lietošanas veids: elektriskā laiva",
			"ChargingPointUsageTypeEnum->electricVehicle": "Lādēšanas punkta lietošanas veids: elektriskā mašīna",
			"ChargingPointUsageTypeEnum->electricalDevices": "Lādēšanas punkta lietošanas veids: iekārtas",
			"ChargingPointUsageTypeEnum->motorhomeOrCaravanSupply": "Lādēšanas punkta lietošanas veids: kemperiem",
			"ChargingPointUsageTypeEnum->lorryPowerConsumption": "Lādēšanas punkta lietošanas veids: kravas automašīna enerģijas patēriņš",
			"ChargingModeEnum->other": "Lādēšanas režīms",
			"ChargingModeEnum->unknown": "Lādēšanas režīms: nezināms",
			"PricingPolicyEnum->contract": "Līgums",
			"EquipmentTypeEnum->publicCoinPhone": "Maksas telefons",
			"EquipmentTypeEnum->tollTerminal": "Maksas terminālis",
			"EquipmentTypeEnum->paymentMachine": "Maksāšanas terminālis",
			"ServiceFacilityTypeEnum->motorwayRestaurantSmall": "Mazs automaģistrāles restorāns",
			"ServiceFacilityTypeEnum->carWash": "Mašīnu mazgāšana",
			"ServiceFacilityTypeEnum->medicalFacility": "Medicīnas iestāde",
			"ServiceFacilityTypeEnum->motel": "Motelis",
			"ServiceFacilityTypeEnum->overnightAccommodation": "Naktsmājas",
			"ServiceFacilityTypeEnum->other": "Pakalpojums: cits",
			"ServiceFacilityTypeEnum->unknown": "Pakalpojums: nezināms",
			"ServiceFacilityTypeEnum->restaurantSelfService": "Pašapkalpošanās restorāns",
			"EquipmentTypeEnum->picnicFacilities": "Pikniks",
			"EquipmentTypeEnum->firstAidEquipment": "Pirmās palīdzības aptieciņa",
			"ServiceFacilityTypeEnum->police": "Policija",
			"EquipmentTypeEnum->copyMachineOrService": "Printeris",
			"ChargingModeEnum->mode1AC1p": "Režīms 1, AC 1 fāze",
			"ChargingModeEnum->mode1AC3p": "Režīms 1, AC 3 fāzes",
			"ChargingModeEnum->mode2AC1p": "Režīms 2, AC 1 fāze",
			"ChargingModeEnum->mode2AC3p": "Režīms 2, AC 3 fāzes",
			"ChargingModeEnum->mode3AC3p": "Režīms 3, AC 3 fāzes",
			"ChargingModeEnum->mode4DC": "Režīms 4, DC",
			"ServiceFacilityTypeEnum->tyreRepair": "Riepu remonts",
			"EquipmentTypeEnum->playground": "Rotaļu laukums",
			"ConnectorFormatTypeEnum->cableMode2": "Savienojuma formāts: cableMode2",
			"ConnectorFormatTypeEnum->cableMode3": "Savienojuma formāts: cableMode3",
			"ConnectorFormatTypeEnum->otherCable": "Savienojuma formāts: cits",
			"ConnectorFormatTypeEnum->socket": "Savienojuma formāts: kontaktligzda",
			"ConnectorTypeEnum->cee5": "Savienojuma veids: ",
			"ConnectorTypeEnum->cee3": "Savienojuma veids: cee3",
			"ChargingPointUsageTypeEnum->electricMotorcycle": "Lādēšanas punkta lietošanas veids: elektriskais motocikls",
			"ConnectorTypeEnum->pantographBottomUp": "Savienojuma veids: pantographBottomUp",
			"ConnectorTypeEnum->pantographTopDown": "Savienojuma veids: pantographTopDown",
			"ConnectorTypeEnum->teslaConnectorAmerica": "Savienojuma veids: teslaConnectorAmerica",
			"EquipmentTypeEnum->cashMachine": "cashMachine",
			"ConnectorTypeEnum->other": "Savienojuma veids: cits",
			"ConnectorTypeEnum->domestic": "Savienojuma veids: domestic",
			"ConnectorTypeEnum->domesticA": "Savienojuma veids: domesticA",
			"ConnectorTypeEnum->domesticB": "Savienojuma veids: domesticB",
			"ConnectorTypeEnum->domesticC": "Savienojuma veids: domesticC",
			"ConnectorTypeEnum->domesticD": "Savienojuma veids: domesticD",
			"ConnectorTypeEnum->domesticE": "Savienojuma veids: domesticE",
			"ConnectorTypeEnum->domesticF": "Savienojuma veids: domesticF",
			"ConnectorTypeEnum->domesticG": "Savienojuma veids: domesticG",
			"ConnectorTypeEnum->domesticH": "Savienojuma veids: domesticH",
			"ConnectorTypeEnum->domesticI": "Savienojuma veids: domesticI",
			"ConnectorTypeEnum->domesticJ": "Savienojuma veids: domesticJ",
			"ConnectorTypeEnum->domesticL": "Savienojuma veids: domesticL",
			"ConnectorTypeEnum->domesticM": "Savienojuma veids: domesticM",
			"ConnectorTypeEnum->domesticN": "Savienojuma veids: domesticN",
			"ConnectorTypeEnum->domesticO": "Savienojuma veids: domesticO",
			"ConnectorTypeEnum->iec60309x2three16": "Savienojuma veids: iec60309x2three16",
			"ConnectorTypeEnum->iec60309x2three32": "Savienojuma veids: iec60309x2three32",
			"ConnectorTypeEnum->iec60309x2three64": "Savienojuma veids: iec60309x2three64",
			"ConnectorTypeEnum->iec62196T1": "Savienojuma veids: iec62196T1",
			"ConnectorTypeEnum->iec62196T1COMBO": "Savienojuma veids: iec62196T1COMBO",
			"ConnectorTypeEnum->iec62196T2": "Savienojuma veids: iec62196T2",
			"ConnectorTypeEnum->iec62196T2COMBO": "Savienojuma veids: iec62196T2COMBO",
			"ConnectorTypeEnum->iec62196T3A": "Savienojuma veids: iec62196T3A",
			"ConnectorTypeEnum->iec62196T3C": "Savienojuma veids: iec62196T3C",
			"ConnectorTypeEnum->teslaConnectorEurope": "Savienojuma veids: teslaConnectorEurope",
			"ConnectorTypeEnum->teslaR": "Savienojuma veids: teslaR",
			"ConnectorTypeEnum->teslaS": "Savienojuma veids: teslaS",
			"ConnectorTypeEnum->yazaki": "Savienojuma veids: yazaki",
			"EquipmentTypeEnum->safeDeposit": "Seifs",
			"EquipmentTypeEnum->publicPhone": "Telefons",
			"ServiceFacilityTypeEnum->vehicleMaintenance": "Transportlīdzekļa apkope",
			"EquipmentTypeEnum->toilet": "Tualete",
			"EquipmentTypeEnum->fireExtingiusher": "Uguns dzēšamais aparāts",
			"EquipmentTypeEnum->fireHydrant": "Ugunsdzēsības hidrants",
			"EquipmentTypeEnum->fireHose": "Ugunsdzēsības šļūtene",
			"ServiceFacilityTypeEnum->shop": "Veikals",
			"ServiceFacilityTypeEnum->bikeSharing": "Velosipēdu koplietošana",
			"EquipmentTypeEnum->bikeParking": "Velosipēdu novietne",
			"ServiceFacilityTypeEnum->laundry": "Veļas mazgāšana",
			"ServiceFacilityTypeEnum->hotel": "Viesnīca",
			"ChargingModeEnum->legacyInductive": "legacyInductive",
			"EquipmentTypeEnum->payDesk": "payDesk",
			"EquipmentTypeEnum->publicCardPhone": "publicCardPhone",
			"EquipmentTypeEnum->vendingMachine": "Ēdiena automāts",
			"EquipmentTypeEnum->freshWater": "Ūdens",
			"ConnectorTypeEnum->domesticK": "Savienojuma veids: domesticK",
			"EquipmentTypeEnum->informatonStele": "Informācijas stends",
			"EquipmentTypeEnum->iceFreeScaffold": "Instrumenti ledus notīrīšanai",
			"EquipmentTypeEnum->internetTerminal": "Interneta terminālis",
			"ConnectorTypeEnum->iec60309x2single16": "Savienojuma veids: iec60309x2single16",
			"PricingPolicyEnum->flatRate": "flatRate",
			"PricingPolicyEnum->other": "Cenu politika: cita",
			"PricingPolicyEnum->unknown": "Cenu politika: nezināma",
			"ServiceFacilityTypeEnum->petrolStation": "DUS",
			"EquipmentTypeEnum->defibrillator": "Defibrilators",
			"EquipmentTypeEnum->dumpingStation": "Dempinga stacija",
			"ServiceFacilityTypeEnum->docstop": "Docstop",
			"EquipmentTypeEnum->shower": "Duša",
			"ServiceFacilityTypeEnum->cafe": "Kafejnīca",
			"ServiceFacilityTypeEnum->kiosk": "Kiosks",
			"ServiceFacilityTypeEnum->truckWash": "Kravas automašīnu mazgāšana",
			"EquipmentTypeEnum->elevator": "Lifts",
			"ChargingPointUsageTypeEnum->overheadLineDrivenVehicles": "Lādēšanas punkta lietošanas veids: Gaisvadu līnijas vadīti transportlīdzekļi",
			"ChargingPointUsageTypeEnum->other": "Lādēšanas punkta lietošanas veids: cits",
			"ConnectorTypeEnum->chademo": "Savienojuma veids: chademo",
			"EquipmentTypeEnum->electricChargingStation": "Elektrības uzlādes stacija",
			"EquipmentTypeEnum->faxMachineOrService": "Fakss",
			"ServiceFacilityTypeEnum->touristInformation": "Informācija tūristiem",
			"EquipmentTypeEnum->informationPoint": "Informācijas punkts",
		},
	},
	caps_microroadnetwork: {
		ac_type: {
			P: "P - valsts reģionālais autoceļš",
			V: "V - valsts vietējais autoceļš",
			A: "A - valsts galvenais autoceļš",
		},
	},
	caps_macroroadnetwork: {
		ac_type: {
			P: "P - valsts reģionālais autoceļš",
			V: "V - valsts vietējais autoceļš",
			A: "A - valsts galvenais autoceļš",
		},
	},
	caps_maintenanceclasswinter: {
		ac_type: {
			P: "P - valsts reģionālais autoceļš",
			V: "V - valsts vietējais autoceļš",
			A: "A - valsts galvenais autoceļš",
		},
	},
	caps_regionaldepartments: {
		ac_type: {
			P: "P - valsts reģionālais autoceļš",
			V: "V - valsts vietējais autoceļš",
			A: "A - valsts galvenais autoceļš",
		},
	},
	lau_transportunit: {
		status: {
			working: "Strādā",
		},
		vehicle_type: {
			Grader: "Traktortehnika",
			Tractor: "Traktortehnika",
			Car: "Patruļauto",
			Lorry: "Kravas automašīna",
		},
	},
};
