export default function EmbedPopupLinearRef(props: { linRef: string }) {
	const [road, coord] = props.linRef.split(" ");

	const checkRoad = (road: string) => {
		switch (road.toLowerCase().charAt(0)) {
			case "a":
				return "#E51335";
			case "p":
				return "#00CCF5";
			case "v":
				return "#4c4e4f";
			default:
				return "#E51335";
		}
	};

	return (
		<div className="flex items-center gap-3 text-base pr-3">
			<div className={`px-2 font-semibold flex items-center text-white rounded-[2px]`} style={{ backgroundColor: checkRoad(road) }}>
				<p>{road}</p>
			</div>
			<p className="font-normal">{coord?.replaceAll("(", "").replaceAll(")", "")}</p>
		</div>
	);
}
