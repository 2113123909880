export default function EmbedPopupCloseSvg() {
	return (
		<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.2212 27.2212L27.2231 14.2212M27.2231 27.2212L14.2212 14.2212"
				stroke="black"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
