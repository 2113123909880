import { Dispatch, SetStateAction, useContext } from "react";
import { OverlayManagerContext } from "./Overlay-wrapper";

import PopupArrowSVG from "../../embed/popup/popup-arrow-svg";

import OverlayHeaderIcon from "./Overlay-header-icon";

export default function OverlayHeader({ setItem, item }: { setItem: Dispatch<SetStateAction<number>>; item: number }) {
	const { context } = useContext(OverlayManagerContext);

	const scrollBy = (value: number) => {
		document.getElementById("overlay-scroller")?.scrollBy({ left: value, behavior: "smooth" });
	};

	return (
		<div className="flex">
			{context.features.length > 5 && (
				<button onClick={() => scrollBy(-192)} className="px-1.5 h-16 bg-[#ECECEC] rounded-md flex items-center">
					<PopupArrowSVG />
				</button>
			)}
			<div id="overlay-scroller" className={`p-2 flex gap-4 max-w-[340px] ${context.features.length > 5 ? "overflow-hidden" : "overflow-visible"}`}>
				{context.features.map((f, i) => {
					return (
						<button onClick={() => setItem(i)} key={i} className={`p-[4px] shrink-0 rounded-md flex ${item === i && "ring-npp-blue-500 ring-2"}`}>
							<OverlayHeaderIcon feat={f} />
						</button>
					);
				})}
			</div>
			{context.features.length > 5 && (
				<button onClick={() => scrollBy(192)} className="px-1.5 h-16 bg-[#ECECEC] rounded-md flex items-center rotate-180">
					<PopupArrowSVG />
				</button>
			)}
		</div>
	);
}
