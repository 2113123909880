import { useContext, useEffect, useRef } from "react";
import { EmbedMapContext } from "../embed-map/embed-map";
import { MapGeoJSONFeature, MapMouseEvent } from "maplibre-gl";

export default function EmbedMapHover() {
	const { map } = useContext(EmbedMapContext);
	const highlightedFeature = useRef<MapGeoJSONFeature>();

	useEffect(() => {
		if (!map) return;

		const onMove = (e: MapMouseEvent) => {
			const [feature] = map.queryRenderedFeatures(e.point).filter((f) => f.layer.source !== "openmaptiles");
			/* if (highlightedFeature.current && highlightedFeature.current.id !== feature?.id) {
				const prevFeature = highlightedFeature.current;
				map.setFeatureState({ source: prevFeature.layer.source, sourceLayer: (prevFeature.layer as any)["source-layer"], id: prevFeature.id }, { highlighted: false });
			} */

			if (feature && feature.id) {
				//highlightedFeature.current = feature;
				//map.setFeatureState({ source: feature.layer.source, sourceLayer: (feature.layer as any)["source-layer"], id: feature.id }, { highlighted: true });
				map.getCanvas().style.cursor = "pointer";
			} else {
				map.getCanvas().style.cursor = "";
			}
		};

		map.on("mousemove", onMove);

		return () => {
			map.off("mousemove", onMove);
		};
	}, [map]);

	return <></>;
}
