import { MapGeoJSONFeature } from "maplibre-gl";
import { useContext } from "react";
import { EmbedMapContext } from "../embed-map/embed-map";

export interface LineColor {
	r: number;
	g: number;
	b: number;
	a: number;
}

const getColor = (color: LineColor) => {
	const r = Math.round(color.r * 255);
	const g = Math.round(color.g * 255);
	const b = Math.round(color.b * 255);
	return { r, g, b };
};

export default function EmbedPopupHeaderIcon({ feature }: { feature: MapGeoJSONFeature }) {
	const { map } = useContext(EmbedMapContext);

	if (feature.layer.type === "line") {
		const { r, g, b } = getColor(feature.layer.paint!["line-color"] as any);

		if (feature.layer.id.includes("detour")) {
			return (
				<div className="relative h-11 w-11 shrink-0 overflow-visible">
					<div className="absolute inset-0 h-11 w-[50px] -left-1 flex items-center justify-center">
						<div className="w-full h-2 rounded-full absolute" style={{ background: `rgb(${r}, ${g}, ${b})` }}></div>
					</div>
					<div className="absolute inset-0 h-11 w-12 -left-0.5 -right-3 gap-1 flex items-center justify-center">
						{[1, 2, 3].map((i) => (
							<img src={window.location.origin + "/embed-svgs/bultinja-apvedceljsh.svg"} alt="arrow" key={i} className="h-3 w-3 rounded-full shrink-0" />
						))}
					</div>
				</div>
			);
		}

		//Look for linked point feature. Ignore for detours
		const pointFeature = map
			?.queryRenderedFeatures({ layers: [feature.layer.id.replace("line", "point")] })
			.find((f) => f.id === feature.id && f.layer.type === "symbol");

		return (
			<div className="flex items-center h-11 w-11 relative">
				{pointFeature && pointFeature.layer.type === "symbol" && (
					<img className="h-11 w-11 z-10" alt="svg" src={"/embed-svgs/" + pointFeature.layer.layout?.["icon-image"] + ".svg"} />
				)}
				<div className="w-full h-2 rounded-full absolute" style={{ background: `rgb(${r}, ${g}, ${b})` }}></div>
			</div>
		);
	}

	if (feature.layer.type === "symbol") {
		const lineFeature = map?.queryRenderedFeatures({ layers: [feature.layer.id.replace("point", "line")] }).find((f) => f.id === feature.id && f.layer.type === "line");

		if (lineFeature && lineFeature.layer.type === "line") {
			const { r, g, b } = getColor(lineFeature.layer.paint!["line-color"] as any);

			return (
				<div className="flex items-center h-11 w-11 relative">
					<img className="h-11 w-11 z-10" alt="svg" src={"/embed-svgs/" + feature.layer.layout?.["icon-image"] + ".svg"} />
					<div className="w-full h-2 rounded-full absolute" style={{ background: `rgb(${r}, ${g}, ${b})` }}></div>
				</div>
			);
		}

		return <img className="h-11 w-11" alt="svg" src={"/embed-svgs/" + feature.layer.layout?.["icon-image"] + ".svg"} />;
	}

	if (feature.layer.type === "fill") {
		const { r, g, b } = getColor(feature.layer.paint!["fill-color"] as any);

		return (
			<div className="h-11 w-11 rounded-full flex justify-center items-center" style={{ background: `rgba(${r}, ${g}, ${b}, ${0.35})` }}>
				<div className="h-6 w-6 rounded-full" style={{ background: `rgba(${r}, ${g}, ${b}, ${0.35})` }}></div>
			</div>
		);
	}

	return (
		<div className="font-semibold h-11 w-11 rounded-full bg-black text-white flex justify-center items-center">
			<p>{feature["properties"]["id"]}</p>
		</div>
	);
}
