import type { LayerSpecification, SourceSpecification } from "maplibre-gl";
import { IS_LOCALHOST } from "../../../map/Endpoints";

const origin = IS_LOCALHOST || window.location.origin.includes("map-test") ? "map-test.lvceli.lv" : "map.transportdata.gov.lv";

export const PGTILES_1: SourceSpecification = {
	tiles: [
		`https://${origin}/gis/pg_tileserv/public.mvt_kafkamessages_notplannedevent_public,public.mvt_kafkamessages_plannedevent_public,public.mvt_kafkamessages_notplannedevent_detour,public.mvt_kafkamessages_plannedevent_detour/{z}/{x}/{y}.pbf`,
	],
	type: "vector",
	promoteId: "kafka_id",
};

export const PGTILES_2: SourceSpecification = {
	tiles: [`https://${origin}/gis/pg_tileserv/public.mvt_kafkamessages_uvismessage,public.mvt_kafkamessages_cms/{z}/{x}/{y}.pbf`],
	type: "vector",
	promoteId: "kafka_id",
};

export const LAU_TILES: SourceSpecification = {
	tiles: [`https://${origin}/gis/pg_tileserv/public.mvt_lau_transportunit/{z}/{x}/{y}.pbf`],
	type: "vector",
	promoteId: "id",
};

export const ROAD_LAYER: LayerSpecification = {
	id: "road_layer",
	source: "pgtiles_2",
	"source-layer": "public.kafkamessages_uvismessage",
	type: "line",
	filter: ["any", ["==", ["geometry-type"], "MultiLineString"], ["==", ["geometry-type"], "LineString"]],
	paint: {
		"line-color": [
			"case",
			["boolean", ["feature-state", "highlighted"], false],
			"#00ccf5",
			["==", ["get", "road_conditions"], "satisfying"],
			"#41C954",
			["==", ["get", "road_conditions"], "unsatisfying"],
			"#10ACE8",
			["==", ["get", "road_conditions"], "very_unsatisfying"],
			"#F75600",
			"#000000",
		],
		"line-width": ["interpolate", ["linear"], ["zoom"], 10, 6, 16, 13],
	},
	layout: {
		"line-join": "round",
		"line-cap": "round",
		"line-round-limit": 0,
	},
};

export const POLY_LAYER: LayerSpecification = {
	id: "poly_layer",
	source: "pgtiles_2",
	"source-layer": "public.kafkamessages_uvismessage",
	type: "fill",
	paint: {
		"fill-color": [
			"case",
			["==", ["get", "road_conditions"], "unsatisfying"],
			"#3AADEA",
			["==", ["get", "road_conditions"], "very_unsatisfying"],
			"#BF0000",
			["==", ["get", "road_conditions"], "extreme"],
			"#B03568",
			"#000000",
		],
		"fill-opacity": 0.35,
	},
	filter: [
		"all",
		["==", ["geometry-type"], "Polygon"],
		["any", ["==", ["get", "road_conditions"], "extreme"], ["==", ["get", "road_conditions"], "very_unsatisfying"], ["==", ["get", "road_conditions"], "unsatisfying"]],
	],
};
