import { Icon, Style } from "ol/style";
import highlightR from "../shared/img/highlight-r.png";
import highlightS from "../shared/img/highlight-s.png";
import borderCMS from "../shared/img/border-cms.png";
import { IS_LOCALHOST } from "./Endpoints";

export const getPointHighlight = (square?: boolean) => {
	const style = new Style({
		image: new Icon({
			src: square ? highlightS : highlightR,
			scale: 0.75,
		}),
		zIndex: -Infinity,
	});
	//@ts-expect-error
	style.isHighlighted_ = true;
	return style;
};

export const getCMSHighlight = () => {
	const style = new Style({
		image: new Icon({
			src: borderCMS,
			scale: 1,
			anchor: [0.5, 1.65],
		}),
		zIndex: -Infinity,
	});
	//@ts-expect-error
	style.isHighlighted_ = true;
	return style;
};

export const getCMSMeteo = (t1: string) => {
	const url =
		(IS_LOCALHOST ? "https://npp-test.lvceli.lv" : window.location.origin.replace("map-", "npp-")) +
		"/gfiles/visualization/cms/c_cms_temperatura" +
		Math.round(Number(t1)) +
		".png";

	const style = new Style({
		image: new Icon({
			src: url,
			scale: 1,
			anchor: [0.5, 1.9],
		}),
		zIndex: -Infinity,
	});
	//@ts-expect-error
	style.isHighlighted_ = true;
	return style;
};
