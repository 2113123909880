import { Feature, Map, MapBrowserEvent } from "ol";
import { setFeatureState } from "ol-mapbox-style";
import RenderFeature from "ol/render/Feature";
import { useEffect, useRef } from "react";
import { Icon, Style } from "ol/style";
import { getCMSHighlight, getCMSMeteo, getPointHighlight } from "./Highlight-style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { findStyle } from "../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { LineString, Point } from "ol/geom";
import { getStyle } from "./overlay/Overlay";
import { FeatureLike } from "ol/Feature";
import { FEATURE_LIST } from "../data-layers/Data-layers-new-layer-manager";
import { getPointStyle } from "../data-layers/Style-generator";
import { getStyleItems, StyleLayerItem } from "../data-layers/Style-layer.repository";
import { take } from "rxjs";
import { LAYER_SET_SELECTED$ } from "./layer-set/Layerset-wrapper";

export const HIGHLIGHT_LAYER = new VectorLayer({
	properties: { name: "Highlight", id: "highlight" },
	source: new VectorSource(),
});

const getCursorRelativePosition = (map: Map, event: MapBrowserEvent<any>, feature: FeatureLike) => {
	const geometry = feature.getGeometry();
	if (!(geometry instanceof Point)) return null;

	const featureCenter = geometry.getCoordinates();
	const centerPixel = map.getPixelFromCoordinate(featureCenter); // Dynamically compute feature pixel position
	const cursorPixel = event.pixel; // Cursor position in pixel coordinates

	const relativeX = cursorPixel[0] - centerPixel[0];
	const relativeY = cursorPixel[1] - centerPixel[1];

	return { relativeX, relativeY };
};

const isCursorInStyleBounds = (relativePosition: { relativeX: number; relativeY: number }, bounds: { minX: number; maxX: number; minY: number; maxY: number }) => {
	const { relativeX, relativeY } = relativePosition;
	return relativeX >= bounds.minX && relativeX <= bounds.maxX && relativeY >= bounds.minY && relativeY <= bounds.maxY;
};

export const determineHoveredStyle = (
	map: Map,
	event: MapBrowserEvent<any>,
	feature: Feature,
	options?: { returnStyleDef?: boolean; overrideDetermined?: boolean }
): any => {
	const { returnStyleDef, overrideDetermined } = { ...options };
	let styleItems: StyleLayerItem[] | undefined;

	getStyleItems()
		.pipe(take(1))
		.subscribe((sl) => (styleItems = sl.flatMap((s) => s.layers)));

	// Always calculate the relative position dynamically
	const relativePosition = getCursorRelativePosition(map, event, feature);
	if (!relativePosition) return null;

	const style1Bounds = { minX: -14, maxX: 14, minY: -14, maxY: 14 };
	const style2Bounds = { minX: -14, maxX: 14, minY: -36, maxY: -14 };

	// First check for style2Bounds, then style1Bounds
	if (overrideDetermined ? isCursorInStyleBounds(relativePosition, style2Bounds) : isCursorInStyleBounds(relativePosition, style1Bounds)) {
		const style = styleItems!.find((sl) => ["Kameras", "Cameras", "Kameras un meteostacijas"].includes(sl.name))!;
		const point = style.points[0];
		if (["Ziemas", "Winter"].find((v) => (LAYER_SET_SELECTED$.value?.name ?? "").includes(v))) {
			point.piktogram = point.piktogram.replace("c_cctv_icon_02.svg", "c_cctv_icon_01.svg");
		} else {
			point.piktogram = point.piktogram.replace("c_cctv_icon_01.svg", "c_cctv_icon_02.svg");
		}
		return returnStyleDef ? point : getPointStyle(point);
	} else if (overrideDetermined ? isCursorInStyleBounds(relativePosition, style1Bounds) : isCursorInStyleBounds(relativePosition, style2Bounds)) {
		const style = styleItems!.find((sl) => ["Meteostacijas", "Weather stations"].includes(sl.name))!;
		return returnStyleDef ? style.points[0] : findStyle({ table: "" }, feature, style.points[0]);
	}
	return undefined;
};

export const FeatureHighlighter = ({ map }: { map: Map }) => {
	const features = useRef<(Feature | RenderFeature)[]>([]);

	useEffect(() => {
		if (
			!map ||
			map
				.getLayers()
				.getArray()
				.find((l) => l.get("id") === "highlight")
		)
			return;

		map.addLayer(HIGHLIGHT_LAYER);
	}, [map]);

	useEffect(() => {
		if (!map) return;

		const removeCurrHighlight = () => {
			HIGHLIGHT_LAYER.getSource()?.clear(true);
			features.current.forEach((f) => {
				const props = f.getProperties();
				if (props) {
					const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
					if (layer) {
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"] + "-LineString", source: props["layer"] },
							undefined
						);
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"] + "-MultiLineString", source: props["layer"] },
							undefined
						);
					}
				}
			});
			features.current = [];
		};

		const keyEvent = map.on("pointermove", (e) => {
			const [feat] = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 1 });

			if (feat && features.current.find((f) => f.getId() === feat.getId())) return;

			if (feat && !feat.get("features") && feat.getProperties()["internal"]) {
				removeCurrHighlight();
				const highlightPoint = (feature: FeatureLike) => {
					if (feature instanceof Feature && !features.current.find((f) => f.getId() === feature.getId()) && feature.getGeometry() instanceof Point) {
						const styleDef = getStyle(feature, feature.get("internal"));

						let style: Style[];
						let useAltHighlight = true;

						if (feature.get("layer").includes("kafkamessages_cms") && feature.get("status") === "active") {
							style = determineHoveredStyle(map, e, feature)!;
							if (style && (style[0]?.getImage() as any).iconImage_.src_.includes("c_cms_temperatura")) {
								useAltHighlight = false;
							}
						} else {
							style = findStyle({ table: feature.get("layer").replace("public.", "") }, feature, styleDef);
						}

						const clone = feature.clone();
						//@ts-expect-error
						clone.id_ = feature.getId();
						let square = false;
						//@ts-expect-error
						const url = (style?.[0]?.getImage() as Icon)?.iconImage_?.src_?.split("/") as string[];
						if (url && url[url.length - 1].split("_")[0] === "s") {
							square = true;
						}

						const newStyle = [...style, useAltHighlight ? getPointHighlight(square) : getCMSHighlight()];

						if (style && (style[0]?.getImage() as any).iconImage_.src_.includes("c_cms_temperatura")) {
							newStyle.push(getCMSMeteo(feat.get("t1")));
						}

						clone.setStyle(newStyle);
						HIGHLIGHT_LAYER.getSource()?.addFeature(clone);
						features.current = [...features.current, feat];
					}
				};
				highlightPoint(feat);
				const props = feat.getProperties();
				if (feat instanceof RenderFeature || !feat?.get("internal")?.["style"]) {
					const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
					if (layer) {
						features.current = [...features.current, feat];
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"] + "-LineString", source: props["layer"] },
							{ highlighted: true }
						);
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"] + "-MultiLineString", source: props["layer"] },
							{ highlighted: true }
						);
					}
					if (feat.getGeometry() instanceof LineString) {
						const linkedFeature = FEATURE_LIST[props["linked_to"] + "-" + props["layer"] + "-Point"]?.feature;
						highlightPoint(linkedFeature);
					}
				}
			} else {
				removeCurrHighlight();
			}
		});

		return () => {
			map.un("pointermove", keyEvent.listener);
		};
	}, [map]);

	return <></>;
};
