import { createContext, useEffect, useState } from "react";
import MapWrapper from "./map/Map";
import axios from "axios";
import { ENTRYPOINT } from "./map/Endpoints";
import { DataResponse, GroupEntity, LayerResponse, LayerSetResponse, RemoteResponse, setLayerEntities } from "./store/Layers.repository";
import { Cookies } from "./interceptors/Cookies";
import { LangHeader } from "./interceptors/Lang-header";
import { ClassifierComponent } from "./classifiers/Classifiers";
import { isMobile } from "react-device-detect";
import { TitleComponent } from "./settings/Title";
import { updateSettingStore } from "./settings/Settings.repository";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_LAYERSET, LAYER_SET$ } from "./map/layer-set/Layerset-wrapper";
import { overviewActive$ } from "./map/bubble/Bubble-wrapper";

const defaultContextValues: GlobalSettingsContextInterface = {
	refresh_rate: 500,
	system_code: "NPP",
	use_overview: true,
	predefined_layers: undefined,
};

export const GlobalSettingsContext = createContext(defaultContextValues);

const PREDEFINED_LAYER_PRESETS = {
	unexpectedEvent: {
		mainGroup: 5,
		features: [] as string[],
		groups: [
			{ groupId: 5, layerId: [10, 11, 12, 6, 13, 14] },
			{ groupId: 6, layerId: [15, 8] },
			{ groupId: 13, layerId: [45] },
		],
	},
	plannedEvent: {
		mainGroup: 4,
		features: [] as string[],
		groups: [{ groupId: 4, layerId: [16, 17, 18, 19, 20, 21, 22, 23, 24] }],
	},
	UVIS: {
		mainGroup: 14,
		features: [] as string[],
		groups: [{ groupId: 14, layerId: [58, 61] }],
	},
	camera: {
		mainGroup: 6,
		features: [],
		groups: [{ groupId: 6, layerId: [8] }],
	},
	VMS: {
		mainGroup: 6,
		features: [],
		groups: [{ groupId: 6, layerId: [15] }],
	},
};

interface Props {
	name?: string;
	ids?: string[];
}

interface GlobalSettingsContextInterface {
	refresh_rate: number;
	system_code: "NPP" | "SIPR";
	use_overview?: boolean;
	predefined_layers?: typeof PREDEFINED_LAYER_PRESETS.unexpectedEvent;
}

export const GlobalSettings = (layerData: Props) => {
	const [context, setContext] = useState<GlobalSettingsContextInterface>({
		...defaultContextValues,
		predefined_layers:
			layerData.name && layerData.ids
				? {
						...PREDEFINED_LAYER_PRESETS[layerData.name as keyof typeof PREDEFINED_LAYER_PRESETS],
						features: layerData.ids,
				  }
				: undefined,
	});
	const [loaded, setLoaded] = useState(false);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const paramsObject = Object.fromEntries(searchParams.entries());
		if (paramsObject.layers === undefined) {
			overviewActive$.next(true);
		}
		if (searchParams.get("baseOpacity")) {
			updateSettingStore({
				baseOpacity: Number(searchParams.get("baseOpacity")) ?? 100,
				showAttributionBtn: searchParams.get("showAttributionBtn") === "true",
				showBaseOpacity: searchParams.get("showBaseOpacity") === "true",
				showLegendBtn: searchParams.get("showLegendBtn") === "true",
				showObjectOpacity: searchParams.get("showObjectOpacity") === "true",
				showPanBtn: searchParams.get("showPanBtn") === "true",
				showLayerSets: searchParams.get("showLayerSets") === "true",
			});
		}
	}, []);

	useEffect(() => {
		axios
			.get<{
				refresh_rate: number;
				system_code: "NPP" | "SIPR";
				use_overview: boolean;
			}>(ENTRYPOINT + "/settings/")
			.then((res) => {
				setContext({ ...context, ...res.data });
			});
		const updateLayers = () => {
			axios
				.get<{
					base: LayerResponse[];
					data: RemoteResponse[];
					remote: RemoteResponse[];
					layer_sets: LayerSetResponse[];
				}>(ENTRYPOINT + "/layer/layers")
				.then((res) => {
					const entities: GroupEntity[] = Object.keys(res.data)
						.map((key) => {
							if (key === "layer_sets") {
								const layersets = res.data[key];
								const enabledLs = layersets.find((ls) => ls.enabled);
								if (enabledLs) {
									DEFAULT_LAYERSET.data_layers = enabledLs.data_layers;
									DEFAULT_LAYERSET.name = enabledLs.name;
									DEFAULT_LAYERSET.id = enabledLs.id;
								}
								LAYER_SET$.next(layersets);
								return [];
							}

							if (key === "base") {
								return {
									name: "BaseLayers",
									group: key,
									id: key + "-" + 0,
									layers: res.data[key].map((l) => ({ ...l, group: key })),
								} as any;
							} else {
								return (res.data[key as keyof typeof res.data] as RemoteResponse[]).map(
									(d) =>
										({
											attributions: d.attributions,
											name: d.name,
											id: key + "-" + d.id,
											group: key,
											dataSource: d.url_to_datasource,
											use_uvis_popups: d.use_uvis_popups,
											layers: (d as RemoteResponse | DataResponse).layers.map((l) => ({
												...l,
												group: key,
												groupName: d.name,
												merged_tiles: (l as any).merged_tiles,
												zoom_from: isMobile ? (l as any).zoom_from - 2 : (l as any).zoom_from,
											})),
										} as GroupEntity)
								);
							}
						})
						.flat();
					setLayerEntities(entities);
					setLoaded(true);
				});
		};

		updateLayers();
		const interval = setInterval(() => {
			updateLayers();
		}, 30000);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<GlobalSettingsContext.Provider value={context}>
			<Cookies></Cookies>
			<LangHeader></LangHeader>
			<ClassifierComponent></ClassifierComponent>
			{context.system_code === "NPP" && <TitleComponent></TitleComponent>}
			{loaded && <MapWrapper></MapWrapper>}
		</GlobalSettingsContext.Provider>
	);
};
