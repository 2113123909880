import { useContext, useEffect, useRef, useState } from "react";
import { OverlayManagerContext } from "./Overlay-wrapper";
import { OverlayList } from "./Overlay-list";
import { OVERLAY_MANAGER } from "./Select";
import { Feature } from "ol";
import { Button } from "antd";
import { GlobalSettingsContext } from "../../Settings";
import { Circle, Style } from "ol/style";
import { Point, Polygon } from "ol/geom";
import { FeatureLike } from "ol/Feature";
import { doDarkMagic, strToFilter } from "../../data-layers/Style-generator";
import RenderFeature from "ol/render/Feature";
import { StyleLayerProp, getStyleItems } from "../../data-layers/Style-layer.repository";
import { LineStyle, PointStyle } from "../../store/Layers.repository";
import { take } from "rxjs";
import { createTitle } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { FEATURE_LIST } from "../../data-layers/Data-layers-new-layer-manager";
import OverlayHeader from "./Overlay-header";
import OverlayLinearRefWrapper from "./Overlay-linear-ref-wrapper";
import { determineHoveredStyle } from "../Feature-highlighter";
import { useObservable } from "@ngneat/use-observable";
import { getMapStore } from "../Map.repository";

export const getStyle = (feature: Feature | RenderFeature, internal: any, getAll?: boolean): any | undefined => {
	let styleItems: StyleLayerProp[] = [];

	getStyleItems()
		.pipe(take(1))
		.subscribe((sl) => {
			styleItems = sl;
		});

	if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
		const names = Array.from(new Set(styleItems.map((s) => s.name)));
		const styles = (feature.getGeometry()?.getType() === "Point" || feature.getGeometry()?.getType() === "Polygon" ? internal["points"] : internal["lines"])
			?.filter((s: LineStyle | PointStyle) =>
				styleItems
					.filter((styleItem) => styleItem.visible)
					.map((styleItem) => styleItem.layers)
					.flat()
					.find((l) => l.visible && s.layerName === l.name)
			)
			.sort((a: LineStyle, b: LineStyle) => {
				const aVal = names.indexOf(a.groupName);
				const bVal = names.indexOf(b.groupName);
				if (aVal > bVal) {
					return -1;
				}

				if (aVal < bVal) {
					return 1;
				}

				return 0;
			});

		const style = styles?.filter(
			(s: any) =>
				s.source === feature.get("layer") &&
				(!s.filter ||
					doDarkMagic(feature, { ...s, filter: strToFilter(s.filter), style: true }) ||
					(s.filter === "other" &&
						styles.filter((sty: any) => sty.groupName === s.groupName && doDarkMagic(feature, { ...sty, filter: strToFilter(sty.filter), style: true })).length < 2))
		);
		if (!style) {
			return styles?.find((s: any) => s.filter === "other");
		}
		return getAll ? style : style[0];
	} else if (feature && feature instanceof Feature) {
		return feature.get("internal")?.["style"];
	} else {
		return undefined;
	}
};

export const getColor = (feature: Feature | FeatureLike) => {
	const style = feature.get("internal")?.["style"] as Style[];
	return style?.length > 0
		? style
				.map((s) => {
					let color = (s.getStroke() ?? s.getFill())?.getColor()?.toString();
					if (!color && s.getImage() instanceof Circle) {
						color = (s.getImage() as Circle)?.getFill()?.getColor()?.toString() ?? "";
					}
					return color;
				})
				.find((s) => !!s)
		: "";
};

export const Overlay = () => {
	const [item, setItem] = useState(0);

	const { context } = useContext(OverlayManagerContext);
	const { system_code } = useContext(GlobalSettingsContext);
	const feature = context.features[item] as Feature;

	const [title, setTitle] = useState("");

	const [mapStore] = useObservable(getMapStore());

	useEffect(() => {
		setItem(0);
	}, [context]);

	const [attributes, setAttributes] = useState(undefined);

	useEffect(() => {
		setAttributes(undefined);
	}, [feature]);

	const getTitle = () => {
		if (!feature) return "";
		const lr = feature.get("linear_reference");

		if (feature.get("layer").includes("detour")) {
			const internal = feature.get("internal");
			const [style] = getStyle(feature, internal);

			return `Apbraukšanas maršruts <br> ${
				createTitle(feature, style.popup_title) ?? style
					? style.layerName + " " + (lr ?? "")
					: internal["title"] ?? (internal?.["styleName"] ?? internal?.["layerName"]) + (lr ? "<br>" + lr : "")
			}`;
		}

		if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
			let tempf = feature;
			if (feature.getGeometry() instanceof Point && feature.get("linked_to")) {
				const linkedFeature = FEATURE_LIST[feature.get("linked_to") + "-" + feature.get("layer") + "-LineString"]?.feature;

				if (linkedFeature) {
					tempf = linkedFeature;
				}
			}
			const style = feature.get("layer").includes("kafkamessages_cms")
				? feature.get("overrideStyleDef") ?? determineHoveredStyle(mapStore.map!, context.event!, feature, { returnStyleDef: true })
				: getStyle(tempf, tempf.get("internal"));

			if (style) {
				/* const hideDots = !isExpanded && style.layerName?.length > (context.features.length > 1 ? 21 : 31);
				if (dots === hideDots) {
					setDots(!dots);
				} */
				const title = style?.popup_title ? createTitle(tempf, style.popup_title) : style ? style.layerName ?? style.name : "";
				return title;
			}
			return "";
		} else if (feature instanceof Feature) {
			const internal = feature.get("internal");
			return internal["title"] ?? (internal?.["styleName"] ?? internal?.["layerName"]) + " " + (lr ?? "");
		}
		return "";
	};

	const getButton = () => {
		const value = feature.get("layer") !== "features" ? feature.get("layer") : feature.get("internal")["table"];

		if (value?.includes("kafkamessages_notplannedevent")) {
			return (
				<a target="_blank" rel="noreferrer" href={`${window.location.origin}/unexpected_events/${feature.get("kafka_id")}`}>
					<Button type="primary" className="mt-6 ml-2 mb-4">
						Slēgt vai rediģēt brīdinājumu
					</Button>
				</a>
			);
		} else if (value?.includes("kafkamessages_staticobject")) {
			return (
				<a target="_blank" rel="noreferrer" href={`${window.location.origin}/static_objects/${feature.get("kafka_id")}`}>
					<Button type="primary" className="mt-6 ml-2 mb-4">
						Rediģēt
					</Button>
				</a>
			);
		} else if (value?.includes("kafkamessages_plannedevent")) {
			return (
				<a target="_blank" rel="noreferrer" href={`${window.location.origin}/planned_events/${feature.get("kafka_id")}`}>
					<Button type="primary" className="mt-6 ml-2 mb-4">
						Rediģēt
					</Button>
				</a>
			);
		}
		return <></>;
	};

	const getAttributes = () => {
		const internal = feature.get("internal");
		if (internal.attributes) {
			return internal.attributes;
		}

		if (feature.get("layer").includes("kafkamessages_cms")) {
			if (attributes) {
				return attributes;
			}

			const attr = (
				feature.get("overrideStyleDef") ??
				determineHoveredStyle(mapStore.map!, context.event!, feature, {
					returnStyleDef: true,
					overrideDetermined: !!feature.get("overrideDetermined"),
				})
			)?.attributes;
			if (attr) setAttributes(attr);
			return attr;
		}

		return getStyle(feature, feature?.get("internal"))?.attributes;
	};

	useEffect(() => {
		setTitle(getTitle());
	}, [feature]);

	return (
		<section id="overlay" className="p-4 rounded w-[90vw] sm:w-[480px] border-2 border-npp-grey-500 bg-white flex flex-col z-50 shadow-md relative">
			<OverlayHeader setItem={setItem} item={item} />
			<button
				className="absolute top-4 right-4"
				onClick={() => {
					document.getSelection()?.empty();
					OVERLAY_MANAGER.setPosition(undefined);
				}}
			>
				<span className="material-icons">close</span>
			</button>
			<div className="flex-grow max-w-[80vw] sm:max-w-[30em] p-2">
				<div className="flex flex-col gap-2">
					<div className="relative mr-auto text-lg font-semibold leading-tight text-npp-grey-500">
						<p dangerouslySetInnerHTML={{ __html: title }} className="text-xl font-semibold text-[#303030]"></p>
					</div>
					{!(feature?.getGeometry() instanceof Polygon) && <OverlayLinearRefWrapper feature={feature} />}
				</div>
				<div className="w-full h-[1px] bg-[#d9d9d9] mb-3 mt-4"></div>
				{feature && <OverlayList feature={feature} attributes={attributes ?? getAttributes()}></OverlayList>}
				{system_code === "SIPR" && feature && getButton()}
			</div>
		</section>
	);
};
