import { useContext, useEffect, useRef, useState } from "react";
import { EmbedMapContext } from "../embed-map/embed-map";
import { MapGeoJSONFeature, MapMouseEvent, Popup } from "maplibre-gl";
import EmbedPopup from "../popup/embed-popup";
import EmbedPopupCloseSvg from "../popup/embed-popup-close";

export default function EmbedMapClick() {
	const { map } = useContext(EmbedMapContext);
	const popupEl = useRef<HTMLDivElement | null>(null);
	const popup = useRef<Popup>();
	const [featureState, setFeatureState] = useState<MapGeoJSONFeature[]>([]);
	const [hidden, setHidden] = useState(true);

	useEffect(() => {
		if (!map) return;

		const adjustMapForPopup = (map: any, lngLat: any) => {
			if (!popupEl.current) return;

			const popupBounds = popupEl.current.getBoundingClientRect();
			const mapCanvas = map.getCanvas();
			const mapBounds = mapCanvas.getBoundingClientRect();

			// Check if popup overflows on the right or bottom
			const overflowsRight = popupBounds.right > mapBounds.right;
			const overflowsBottom = popupBounds.bottom > mapBounds.bottom;

			if (overflowsRight || overflowsBottom) {
				map.easeTo({
					center: lngLat,
					padding: { right: overflowsRight ? 430 : 0, bottom: overflowsBottom ? popupBounds.height + 20 : 0 },
					duration: 500,
				});
			}
		};

		const onClick = (e: MapMouseEvent) => {
			popup.current?.remove();
			const clickedFeatures = map.queryRenderedFeatures(e.point).filter((f) => f.layer.source !== "openmaptiles" && f.layer.id !== "event-detour-layer-arrows");

			const uniqueFeatures = clickedFeatures.reduce(
				(acc, feature) => {
					if (feature.id && !acc.seenIds.has(feature.id)) {
						acc.seenIds.add(feature.id);
						acc.features.push(feature);
					}
					return acc;
				},
				{ seenIds: new Set(), features: [] as MapGeoJSONFeature[] }
			).features;

			if (uniqueFeatures.length) {
				setHidden(false);
				console.log("uniqueFeatures:", uniqueFeatures);
				setFeatureState(uniqueFeatures);

				popup.current = new Popup({
					closeOnClick: false,
					closeButton: false,
					anchor: "top-left",
				})
					.setLngLat(e.lngLat)
					.setDOMContent(popupEl.current!)
					.addTo(map);

				// Adjust map only if necessary
				setTimeout(() => adjustMapForPopup(map, e.lngLat), 10); // Small delay to allow popup rendering
			}
		};

		map.on("click", onClick);

		return () => {
			map.off("click", onClick);
		};
	}, [map]);

	return (
		<section
			ref={popupEl}
			className="bg-white rounded-[4px] border-[#4C4E4F] border-2 shadow-md p-5 w-[90vw] sm:w-auto sm:min-w-[420px] font-montserrat relative"
			hidden={hidden}
		>
			<button onClick={() => popup.current?.remove()} className="absolute right-1 top-2">
				<EmbedPopupCloseSvg />
			</button>
			{featureState.length && <EmbedPopup features={featureState} />}
		</section>
	);
}
