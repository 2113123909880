import { useObservable } from "@ngneat/use-observable";
import { LayerSetResponse } from "../../store/Layers.repository";
import { LAYER_SET_SELECTED$ } from "./Layerset-wrapper";

export default function LayersetList(props: { layersets: LayerSetResponse[]; enableLayerset: (layerset: LayerSetResponse) => void; disableLayerset: () => void }) {
	const [selected] = useObservable(LAYER_SET_SELECTED$);

	return (
		<>
			{props.layersets.map((ls) => {
				const enabled = selected?.id === ls.id;

				return (
					<button
						onClick={() => (enabled ? props.disableLayerset() : props.enableLayerset(ls))}
						key={ls.id}
						className={`bg-white py-2 px-4 rounded-md shadow-md pointer-events-auto flex items-center justify-center gap-3 font-medium text-base font-montserrat text-[#4C4E4F] ${
							enabled && "ring-2 ring-npp-blue-500"
						}`}
					>
						{ls.svg_symbol && <img src={ls.svg_symbol} alt="svg_symbol" />}
						<p className="-mb-0.5">{ls.name}</p>
					</button>
				);
			})}
		</>
	);
}
